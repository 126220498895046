<template>
  <transition mode="out-in" name="modal-fade">
    <div ref="modal" @keyup.esc="closeModal" tabindex="-1" class="modal-backdrop">
      <button aria-label="Close modal" class="btn-close" @click="closeModal">
        <i>
          <svg
            fill="none"
            height="56"
            viewBox="0 0 56 56"
            width="56"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="28" cy="28" fill="black" opacity="0.2" r="28" />
            <path
              d="M31.0327 28L35.5814 23.4514C36.1395 22.8932 36.1395 21.9882 35.5814 21.4295L34.5705 20.4186C34.0123 19.8605 33.1073 19.8605 32.5486 20.4186L28 24.9673L23.4514 20.4186C22.8932 19.8605 21.9882 19.8605
                21.4295 20.4186L20.4186 21.4295C19.8605 21.9877 19.8605 22.8927 20.4186 23.4514L24.9673 28L20.4186 32.5486C19.8605 33.1068 19.8605 34.0118 20.4186 34.5705L21.4295 35.5814C21.9877 36.1395 22.8932 36.1395
                23.4514 35.5814L28 31.0327L32.5486 35.5814C33.1068 36.1395 34.0123 36.1395 34.5705 35.5814L35.5814 34.5705C36.1395 34.0123 36.1395 33.1073 35.5814 32.5486L31.0327 28Z"
              fill="white"
            />
          </svg>
        </i>
      </button>
      <div
        aria-describedby="modalDescription"
        aria-labelledby="modalTitle"
        class="modal"
        role="dialog"
      >
        <header class="modal__header">
          <slot name="header"></slot>
          <div class="horizontal-divider" />
        </header>
        <section id="modalDescription" class="modal__body">
          <slot name="main"></slot>
        </section>
        <footer class="modal__footer">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'EditModal',
  emits: ['close'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
  mounted() {
    this.$refs.modal.focus();
  },
};
</script>

<style lang="scss" scoped>
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  opacity: 1;
  overflow-y: auto;
  width: 100%;
  height: auto;

  display: -webkit-box;

  @media only screen and (max-width: 768px) {
    & > span {
      width: 100%;
    }
  }
}

.modal {
  position: relative;
  background: $white;
  box-shadow: 0px 0px 12px $shadow-600;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  max-width: 828px;
  height: fit-content;
  margin: 93px 0 130px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  &__header,
  &__footer {
    display: flex;
    background: $white;
  }

  &__header {
    h2 {
      @extend .text-xl--bold;
      font-size: 20px;
      line-height: 28px;
    }

    .horizontal-divider {
      height: 1px;

      background: $grey-500;
    }
    padding: 54px 64px 0 64px;
    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 64px;
    position: absolute;
    bottom: 48px;
    right: 0;
  }

  &__body {
    position: relative;
    padding: 16px 64px 32px 64px;
    background: $white;
    overflow-y: scroll;

    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 24px 26px;
    }
  }
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
  z-index: 2;
}
</style>
