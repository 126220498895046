const unfocused = {
  beforeMount(el, binding, vnode) {
    el.event = function (event) {
      if (el == event.target || el.contains(event.target)) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('focusout', el.event, true);
  },
  unmounted(el) {
    document.body.removeEventListener('focusout', el.event, true);
  },
};

export default unfocused;
