const expand = {
  mounted(el, binding) {
    if (binding.value !== null) {
      function calcHeight() {
        const currentState = el.getAttribute('aria-expanded');

        el.classList.add('u-no-transition');
        el.removeAttribute('aria-expanded');
        el.style.height = null;
        el.style.height = `${el.clientHeight}px`;
        el.setAttribute('aria-expanded', currentState);

        setTimeout(() => {
          el.classList.remove('u-no-transition');
        });
      }

      el.classList.add('element-expand');
      el.setAttribute('aria-expanded', (binding.value) ? 'true' : 'false');
      calcHeight();
      window.addEventListener('resize', calcHeight);
    }
  },
  updated(el, binding) {
    if (el.style.height && binding.value !== null) {
      el.setAttribute('aria-expanded', (binding.value) ? 'true' : 'false');
    }
  },
};

export default expand;
