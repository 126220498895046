import { setUser } from '@sentry/vue';
import router from '@/router/index';

export default {
  userInfoAnalytics(context) {
    const coreId = context.getters.getUserId;
    return context.dispatch('contactInfo', coreId).then((response) => {
      const data = response.data;
      const username = `${data.first_name} ${data.last_name} <${data.email}>`;
      setUser({ username });
    });
  },

  async redirectAfterLogin({ commit, getters }) {
    if (getters.getRedirectUrl) {
      router.push(getters.getRedirectUrl);
    } else {
      router.push({ name: 'Home' });
    }

    // Set logged in state to true
    commit('setLoggedIn', true);
  },

  logout(context, redirection = true) {
    // TODO check this
    // Remove user infos
    context.commit('removeUserInfos');

    // Set logged in state to false
    context.commit('setLoggedIn', false);

    context.commit('deleteTokens');

    setUser(null);

    if (redirection) {
      router.push({ name: 'Login' });
    }

    return Promise.resolve();
  },
  successAlert(context, message) {
    if (message || message.message) {
      if (typeof message === 'string') context.commit('changeAlertInfos', { type: 'success', show: true, message });
      else {
        console.warn('[WARN!] Success alert was called improperly, message should be a string');
        context.commit('changeAlertInfos', { type: 'success', show: true, message: message.message });
      }
    }
    return true;
  },
  dangerAlert(context, message) {
    if (message || message.message) {
      if (typeof message === 'string') context.commit('changeAlertInfos', { type: 'danger', show: true, message });
      else {
        console.warn('[WARN!] Danger alert was called improperly, message should be a string');
        context.commit('changeAlertInfos', { type: 'danger', show: true, message: message.message });
      }
    }
    return false;
  },
  infoAlert(context, message) {
    if (message || message.message) {
      if (typeof message === 'string') context.commit('changeAlertInfos', { type: 'info', show: true, message });
      else {
        console.warn('[WARN!] Info alert was called improperly, message should be a string');
        context.commit('changeAlertInfos', { type: 'info', show: true, message: message.message });
      }
    }
  },
  // TODO: Method deprecated, should implement the new methods placed above
  triggerAlert(context, alert) {
    console.warn('[WARN!] Trigger alert is deprecated, please use the new methods - infoAlert, successAlert, dangerAlert');
    context.commit('changeAlertInfos', alert);
  },
  focusSection(context, section) {
    context.commit('setFocusedSection', section);
  },
};
