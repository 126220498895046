import { axiosCoreNoToken } from '../plugins/axios.js';
import { store } from '@/store';

export const GOOGLE_USER_ID = import.meta.env.VITE_GOOGLE_USER_ID;

function postGoogleJWT(data) {
  return new Promise((resolve, reject) => {
    axiosCoreNoToken.post('auth/google/', data).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

export function handleCredentialResponse(response) {
  const data = { token: response.credential };
  postGoogleJWT(data).then(
    (response) => {
      store.commit('setAccessToken', response.data.access);
      store.commit('setRefreshToken', response.data.refresh);
      store.dispatch('redirectAfterLogin');
    },
    (error) => {
      store.commit('setLoginFeedback', error.response.data);
    },
  );
}

window.handleCredentialResponse = handleCredentialResponse;
