import { axiosCoreNoToken, axiosCore } from '@/plugins/axios';

const actions = {
  queryCities({}, searchQuery) {
    return axiosCoreNoToken.get(`/cities?query=${searchQuery}`);
  },
  queryCountries({}, searchQuery) {
    return axiosCore.get(`/countries?query=${searchQuery}`);
  },
};

export default {
  actions,
};
