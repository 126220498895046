const state = {
  isKycPopupValid: false,
};

const getters = {
  getIsKycPopupValid: (state) => state.isKycPopupValid,
};

const mutations = {
  setIsKycPopupValid: (state, data) => { state.isKycPopupValid = data; },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
