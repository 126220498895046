<template>
    <div :class="{ 'collapse-trigger':  !triggered, 'collapse-triggered': triggered }" @click="clicked">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'CollapseTrigger',
  emits: ['click'],
  props: {
    triggered: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      transitioning: false,
    };
  },
  watch: {
    triggered(newVal) {
      this.triggered = newVal;
    },
  },
  methods: {
    clicked() {
      this.$emit('click');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    @keyframes loading {
        0% {
            transform: rotate(360deg);
        } 25% {
            transform: rotate(-15deg);
        } 50% {
            transform: rotate(10deg);
        } 75% {
            transform: rotate(-5deg);
        } 100% {
            transform: rotate(0deg);
        }
    }

    .collapse-trigger {
        cursor: pointer;
        display: flex;
        align-items: center;
        &:after {
            align-items: center;
            font-family: FontAwesome;
            font-size: $medium-font-size;
            content: '\f078';
            margin-left: 10px;
        }
        &:hover {
            &:after {
                animation-name: loading;
                animation-duration: 2s;
                animation-iteration-count:infinite;
            }
        }
    }

    .collapse-triggered {
        cursor: pointer;
        display: flex;
        align-items: center;
        &:after {
            align-items: center;
            font-family: FontAwesome;
            font-size: $medium-font-size;
            content: '\f077';
            margin-left: 10px;
        }
        &:hover {
            &:after {
                animation-name: loading;
                animation-duration: 2s;
                animation-iteration-count:infinite;
            }
        }
    }

</style>
