/** @typedef {import("@/ort-lib/types/general/types").Choice} Choice */

import { axiosCore } from '@/plugins/axios';
import { getAction } from '@/ort-lib/utils/jsdoc.js';
import { parseError } from '@/ort-lib/utils/formatters.js';

export default function getBase64(file, callback) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => { callback(reader.result); };
  reader.onerror = () => { callback(-1); };
}

export function errorAlert(defaultMessage, error) {
  const dangerAlert = getAction('dangerAlert');
  return dangerAlert(parseError(defaultMessage, error));
}

export function successAlert(message) {
  const successAlert = getAction('successAlert');
  return successAlert(message);
}

export function infoAlert(message){
  const infoAlert = getAction('infoAlert');
  infoAlert(message);
}

const choiceCache = {};

export async function getChoice(/** @type {string} */ choiceName) {
  if (choiceCache[choiceName]) return choiceCache[choiceName];
  /** @type {{ data: Choice[] }} */
  const response = await axiosCore.get(`choices/?choices=${choiceName}`);
  choiceCache[choiceName] = response.data;
  return response.data;
}

export function choicesToDict(choices /** @type {Choice[]} */) {
  return choices.reduce((acc, curr) => {
    acc[curr.value] = curr.text;
    return acc;
  }, {});
}
