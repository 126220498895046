import { axiosCore } from '@/plugins/axios';

const state = {
  userInfo: {},
};

const mutations = {
  setUserInfo: (state, data) => state.userInfo = data,
};

const getters = {
  getUserEmail: (state) => state.userInfo?.email || '',
};

const actions = {
  contactInfo({ dispatch, commit }, coreId) {
    return new Promise((resolve, reject) => {
      axiosCore.get(`/auth/users/${coreId}/contact-info`).then(
        (response) => {
          commit('setUserInfo', response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};

export default {
  actions, state, mutations, getters,
};
