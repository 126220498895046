<template>
  <div>
    <label class="switch">
      <input
        v-model="isChecked"
        :name="name"
        :disabled="disabled"
        :checked="value"
        @click="onClick"
        type="checkbox"
      />
      <div class="slider round" :class="{ disabled: disabled }"></div>
      <br />
    </label>
    <span class="radio-text" v-if="text">{{ text }}</span>
  </div>
</template>

<script>

export default {
  name: 'FormSwitcher',
  emits: ['input', 'send_switch_value'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'switchName',
    },
    text: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value);
      this.$emit('send_switch_value', !this.value, this.name);
    },
  },
  data() {
    return {
      isChecked: this.value,
    };
  },
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: flex;
  height: 1.25rem;
  margin-right: 8px;
  width: min-content;

  &:hover .slider {
    &::before {
      transition: all 300ms ease-in-out;
      height: 1.125rem;
      width: 1.125rem;
      left: 0.06rem;
      bottom: 0.06rem;
    }
  }
}
.switch input {
  width: 2.375rem;
  opacity: 0;
  height: 0;
}
.slider {
  width: 2.375rem;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0.13rem;
  bottom: 0;
  background-color: $grey-600;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: '';
    height: 1rem;
    width: 1rem;
    left: 0.13rem;
    bottom: 0.13rem;
    background-color: $white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}
input:checked + .slider {
  background-color: $primary;
  &:before {
    transform: translateX(1.125rem);
  }
    &.disabled {
      background-color: $cobalt-400;
    }
}
.slider.round {
  border-radius: 50px;
  &:before {
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 0px 12px $shadow-600;
  }
}
.slider.round.disabled:before {
  background-color: $grey-400;
}
input:checked + .slider.disabled:before {
  background-color: $white;
}
.form-button {
  width: 100px;
  height: 30px;
  border-radius: 6px;
}
</style>
