export const numberWithSpaces = (number) => (number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : null);

export const numberWithCommas = (number) => (number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null);

export const validateFixedAmount = (val) => {
  if (val && val !== '-') return `${Number.parseFloat(val).toFixed(3)}%`;
  return '-';
};

export const validFixedAmountPercent = (val) => {
  if (val && val !== '-') return `${Number.parseFloat(val).toFixed(2) * 100}%`;
  return '-';
};

export const numberToName = (value) => {
  if (!value) return;
  const dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  const tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  const tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  const th = ['', 'thousand', 'million', 'milliard', 'billion'];

  value = value.toString();
  value = value.replace(/[\, ]/g, '');
  if (value != parseFloat(value)) return 'not a number';
  let x = value.indexOf('.');
  if (x == -1) x = value.length;
  if (x > 15) return 'too big';
  const n = value.split('');
  let str = '';
  let sk = 0;
  for (let i = 0; i < x; i++) {
    if ((x - i) % 3 == 2) {
      if (n[i] == '1') {
        str += `${tn[Number(n[i + 1])]} `;
        i++;
        sk = 1;
      } else if (n[i] != 0) {
        str += `${tw[n[i] - 2]} `;
        sk = 1;
      }
    } else if (n[i] != 0) {
      str += `${dg[n[i]]} `;
      if ((x - i) % 3 == 0) str += 'hundred ';
      sk = 1;
    }
    if ((x - i) % 3 == 1) {
      if (sk) str += `${th[(x - i - 1) / 3]} `;
      sk = 0;
    }
  }
  if (x != value.length) {
    const y = value.length;
    str += 'point ';
    for (let i = x + 1; i < y; i++) str += `${dg[n[i]]} `;
  }
  return str.replace(/\value+/g, ' ');
};
