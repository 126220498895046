import perks_data from '@/store/modules/perks/perks_data.json';
import { axiosCore } from '@/plugins/axios';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

const state = {
  perksData: perks_data,
};

const getters = {
  getPerksData: (state) => state.perksData,
  getPromoCodePerks: (state) => state.perksData.filter((perk) => perk.type == 'promo_code' && perk.active),
  getContactPersonPerks: (state) => state.perksData.filter((perk) => perk.type == 'contact_person' && perk.active),
  getExpertPerks: (state) => state.perksData.filter((perk) => perk.type == 'expert' && perk.active),
  getOffersPerks: (state) => state.perksData.filter((perk) => (perk.type == 'promo_code' || perk.type == 'contact_person') && perk.active),
};

const actions = {
  async contactExpert({ dispatch, rootGetters }, expert_name) {
    try {
      const startupId = rootGetters.accesstokenPayload.active_profile.entity_id;
      const response = await axiosCore.post(`startups/${startupId}/contact-expert`, {
        expert_name,
      });
      successAlert(`Your request to learn more about the services of ${expert_name} have been successfully sent!`);
      return response;
    } catch (error) {
      errorAlert('Some error occurred', error);
      throw new Error(error);
    }
  },
};

export default {
  state,
  getters,
  actions,
};
