import { axiosCore } from '@/plugins/axios';

const state = {
  latestStartupFundraising: {},
  isLatestStartupFundraisingLoading: false,
};

const actions = {
  async fetchLatestStartupFundraising({ commit, dispatch, rootGetters }) {
    const startupId = rootGetters.accesstokenPayload.active_profile.entity_id;
    commit('setIsLatestStartupFundraisingLoading', true);

    try {
      const response = await axiosCore.get(`/startups/${startupId}/latest-fundraising`);
      commit('setLatestStartupFundraising', response.data);
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong when getting the current fundraising.' });
      throw new Error(error?.message);
    } finally {
      commit('setIsLatestStartupFundraisingLoading', false);
    }
  },
};

const mutations = {
  setLatestStartupFundraising: (state, data) => state.latestStartupFundraising = data,
  setIsLatestStartupFundraisingLoading: (state, data) => state.isLatestStartupFundraisingLoading = data,
};

export default {
  state,
  actions,
  mutations,
};
