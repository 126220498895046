<template>
    <div>
        <div>
            <div class="burger-menu">
               <!--  <i class="fas fa-bars"></i> -->
               <ORTIcon icon="menu-02"/>
            </div>
        </div>
    </div>
</template>

<script>
import ORTIcon from '@/ort-lib/components/ORTIcon.vue';

export default {
  name: 'HamburgerMenu',
  components: {
    ORTIcon,
  },
};
</script>

<style lang="scss" scoped>
.burger-menu {
    font-size:  1.5rem;
    padding-right: 8px;
}
</style>
