<template>
  <div class="form-group">
    <span v-if="title" class="title">
      {{ title }}
      <span v-if="subtitle" class="subtitle">{{ subtitle }}</span>
    </span>
    <div class="input-container">
      <i
        v-if="iconIsShown"
        :class="'fas fa-' + icon + ' form-control-icon'"
      ></i>
      <VeeField
        :name="name"
        v-slot="{ errorMessage, field }"
        :rules="rules"
        :modelValue="value"
      >
        <input
          v-bind="field"
          :class="['form-control', !iconIsShown ? 'no-icon' : null, fieldIdentifier] "
          :type="type"
          :value="value"
          :name="name"
          :placeholder="placeholder"
          :disabled="disabled"
          @input="onInput($event.target.value)"
        />
        <span v-if="!errorMessage" class="info">{{ info }}</span>
        <span v-else class="text-danger">
          {{ errorMessage }}
        </span>
      </VeeField>
    </div>
  </div>
</template>

<script>

export default {
  compatConfig: { MODE: 3 },
  name: 'FormTextInput',
  emits: ['update:modelValue'],
  props: {
    iconIsShown: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    icon: {
      type: String,
      default: 'user-01',
    },
    info: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'textInput',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: 'required',
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      default: false,
    },
    message: {
      type: String,
    },
    fieldIdentifier: {
      required: false,
    },
  },
  methods: {
    onInput(value) {
      this.$emit('update:modelValue', value, this.name);
    },
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.value = newVal;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-danger {
  font-size: 0.875rem;
  font-family: $fontfamily;
  margin: 4px;

}
.info {
  font-size: 0.875rem;
  font-weight: bold;
  font-family: $fontfamily;
  color: $secondary-label;
}
.title {
  @extend .text-sm--bold;
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
}
.input-container {
  position: relative;
  width: 100%;
  height: 44px;
}
.form-group {
  width: 100%;
  border-radius: 12px;
  margin: 19px 0;
  gap: 4px;
}
.form-control-icon {
  top: 1.1rem;
  left: 0;
  display: block;
  color: $grey-200;
  font-size: 14px;
}

.form-control {
  @extend .text-sm--medium;
  width: 100%;
  height: 44px;
  border-radius: 12px;
  border-radius: 12px;
  border: 1px solid $grey-500;
  z-index: 2;
  padding-left: 2.6rem;
  &:hover {
    border: 1px solid $grey-200;
  }
  &::placeholder {
    color: green;
    font-size: 0.875rem;
    color: rgba(60, 60, 67, 0.3);
  }
  &:focus,
  &:active {
    border: 1px solid $cobalt-200;
    box-shadow: none;
  }
  &.no-icon {
    padding-left: 1rem;
  }
  &.invalid {
    border: 1px solid $danger;
  }
  @media (max-width: 320px) {
    width: 70%;
  }
}
.subtitle {
  @extend .text-sm--bold;
  margin-left: 5px;
  color: $grey-color;
  font-weight: 300;
}
</style>
