const state = {
  sidebarOpen: false,
};
const getters = {
  getSidebarOpen: (state) => state.sidebarOpen,
};
const mutations = {
  setSidebarOpen: (state, open) => {
    state.sidebarOpen = open;
  },
};

export default {
  state,
  getters,
  mutations,
};
