import { axiosCore } from '@/plugins/axios';

const state = {
  goToMarketsCore: [],
  goToMarketsPlatform: [],
};

const getters = {
  goToMarketsList: (state) => state.goToMarketsCore,
};

const actions = {
  getGoToMarkets({ dispatch }) {
    const actionToCall = 'getGoToMarketsCore';
    dispatch(actionToCall);
  },
  getGoToMarketsCore({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.goToMarketsCore.length == 0) {
        axiosCore.get('/gotomarkets-list/').then((response) => {
          commit('storeCoreGoToMarkets', response.data);
          resolve();
        }, (error) => {
          reject();
        });
      } else {
        resolve();
      }
    });
  },
};

const mutations = {
  storeCoreGoToMarkets: (state, data) => state.goToMarketsCore = data,
  storePlatformGoToMarkets: (state, data) => state.goToMarketsPlatform = data,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
