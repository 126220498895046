/**
 * JSDoc is a markup language used to annotate JavaScript source code files.
 * It is used to add information about the code, eg what it does, what parameters it takes, what it returns.
 * JSDoc is a lighter-weight alternative to TypeScript and does not force you to use types.
 * We are adopting JSDoc in our heavily-used stores to make it easier to use in multiple components.
 * This lets us know what each store method/property takes in and returns.
 *
 * For more info on how we use JSDoc see this: https://www.notion.so/oneragtime/a56830e220b6466dac5d9892610bcb7e
 *
 * The official documentation of JSDoc can be found here: https://jsdoc.app/
 */

/** @template T; @typedef {import("src/types/utils.js").TableCache<T>} TableCache<T> */
/** @template T; @typedef {import("src/types/utils.js").PaginatedResults<T>} PaginatedResults<T> */
/** @typedef {import("src/types/fundraisings/fundraising.js").FundraisingData} FundraisingData */
/** @typedef {import("src/types/fundraisings/fundraising.js").CommunityEntry} CommunityEntry */
/** @typedef {FundraisingData["fundraising"] Fundraising} */
/** @typedef {FundraisingData["fundraisingBasicInfo"] FundraisingBasicInfo} */
/** @typedef {FundraisingData["team"] Team } */
/** @typedef {FundraisingData["team"][0] TeamMember } */
/** @typedef {FundraisingData["articles"] Articles} */
/** @typedef {FundraisingData["webinar"] Webinar} */

import Vue from 'vue';
import { getAction } from '@/utils/jsdoc';
import { axiosCore } from '@/plugins/axios';
import utils from '@/components/lib/mixins/utils';
import { checkPageCache, updateTableCache } from '@/store/utils/tables';

const { isEmpty } = utils.methods;
const successAlert = getAction('successAlert');
const dangerAlert = getAction('dangerAlert');

/**
 * @typedef {{
 *  currentFundraisingId: number;
 *  autoSetCurrentFundraisingId: boolean;
 *  fundraisingData: Object.<number, FundraisingData>;
 * }} State
 */

/** @type {State} */
const state = {
  currentFundraisingId: 0,
  autoSetCurrentFundraisingId: true,
  fundraisingData: {
    0: {
      fundraising: {},
      fundraisingBasicInfo: {},
      team: {},
      articles: [],
      webinar: {},
      community: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
        entries: [],
      },
    },
  },
};

const getters = {
  /** @param {State} state */
  getCurrentFundraisingId: (state) => state.currentFundraisingId,
  /** @param {State} state */
  getFundraisingData: (state) => state.fundraisingData[state.currentFundraisingId],
  /** @param {State} state */
  getFundraising: (state) => state.fundraisingData[state.currentFundraisingId].fundraising,
  /** @param {State} state */
  getFundraisingBasicInfo: (state) => state.fundraisingData[state.currentFundraisingId].fundraisingBasicInfo,
  /** @param {State} state */
  getFundraisingTeam: (state) => state.fundraisingData[state.currentFundraisingId].team,
  /** @param {State} state */
  getFundraisingArticles: (state) => state.fundraisingData[state.currentFundraisingId].articles,
  /** @param {State} state */
  getFundraisingWebinar: (state) => state.fundraisingData[state.currentFundraisingId].webinar,
  /** @param {State} state */
  getFundraisingCommunity: (state) => state.fundraisingData[state.currentFundraisingId].community,
};

const actions = {
  /**
   * Get information about the fundraising.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} fundraisingId - The ID of the fundraising.
   */
  async getFundraising({ commit }, fundraisingId) {
    if (isEmpty(state.fundraisingData[fundraisingId]?.fundraising)) {
      /** @type {{data: Fundraising}} */
      const response = await axiosCore.get(`fundraisings/${fundraisingId}/`);
      commit('setFundraisingData', { fundraisingId, fundraisingData: { fundraising: response.data } });
    }
    commit('setCurrentFundraisingId', fundraisingId);
    return state.fundraisingData[fundraisingId]?.fundraising;
  },
  /**
   * Get basic information about the fundraising and its startup.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} fundraisingId - The ID of the fundraising.
   */
  async getFundraisingBasicInfo({ commit }, fundraisingId) {
    if (isEmpty(state.fundraisingData[fundraisingId]?.fundraisingBasicInfo)) {
      /** @type {{data: FundraisingBasicInfo}} */
      const response = await axiosCore.get(`fundraisings/${fundraisingId}/basic-info`);
      commit('setFundraisingData', { fundraisingId, fundraisingData: { fundraisingBasicInfo: response.data } });
    }
    commit('setCurrentFundraisingId', fundraisingId);
    return state.fundraisingData[fundraisingId]?.fundraisingBasicInfo;
  },
  /**
   * Update information about the fundraising.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{ fundraisingId: number; }} payload
   * @returns {Promise<boolean>} - True if the fundraising was successfully updated.
   */
  async updateFundraising({ commit }, { fundraisingId, ...data }) {
    try {
      await axiosCore.patch(`/fundraisings/${fundraisingId}/`, data);
      for (const key in data) {
        if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
          commit('updateFundraisingField', { fundraisingId, field: key, value: data[key] });
        } else commit('updateFundraisingDataField', { fundraisingId, field: 'fundraising', value: { [key]: data[key] } });
      }
      return successAlert('The Fundraising was successfully updated!');
    } catch (error) {
      return dangerAlert('The Fundraising could not be updated!');
    }
  },
  /**
   * Get fundraisings owned by founders.
   * If a startup makes the request, their fundraisings will be returned.
   * If an investor makes the request, the fundraisings they are invited to will be returned.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{ page?: number; page_size?: number; }} params - The query parameters to adjust page of fundraisings returned.
   */
  async getFounderFundraisings({ commit }, { page = 1, page_size = 6 }) {
    const urlParams = new URLSearchParams({ page, page_size }).toString();
    /** @type {{data: PaginatedResults<FundraisingBasicInfo> }} */
    const response = await axiosCore.get(`fundraisings/invited-by-founder?${urlParams}`);
    for (const fundraising of response.data.results) {
      commit('setFundraisingData', { fundraisingId: fundraising.id, fundraisingData: { fundraisingBasicInfo: fundraising } });
    }
    return response.data;
  },
  /**
   * Get team for the fundraising.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} fundraisingId - The ID of the fundraising.
   */
  async getFundraisingTeam({ commit }, fundraisingId) {
    if (isEmpty(state.fundraisingData[fundraisingId]?.team)) {
      /** @type {{data: Team}} */
      const response = await axiosCore.get(`investors/fundraisings/${fundraisingId}/team`);
      const team = {};
      for (const teamMember of response.data) team[teamMember.id] = teamMember;
      commit('setFundraisingData', { fundraisingId, fundraisingData: { team } });
    }
    commit('setCurrentFundraisingId', fundraisingId);
    return state.fundraisingData[fundraisingId].team;
  },
  /**
   * Create Fundraising
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{ startupId: number; }} payload - The ID of the startup creating the fundraising.
   * @returns {Fundraising|boolean} - The created fundraising or false if it failed.
   */
  async createFundraising({ commit }, { startupId, ...data }) {
    try {
      /** @type {{ data: Fundraising }} */
      const response = await axiosCore.post('fundraisings/', { startup: startupId, ...data });
      commit('setFundraisingData', { fundraisingId: response.data.id, fundraisingData: { fundraising: response.data } });
      successAlert('The Fundraising was successfully created!');
      return response.data;
    } catch (error) {
      return dangerAlert('The Fundraising could not be created!');
    }
  },
  /**
   * Invite an existing or new investor to fundraising's community
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  fundraisingId: number;
   *  first_name: string;
   *  last_name: string;
   *  email: string;
   * }} payload
   */
  async inviteInvestorToCommunity({ commit }, { fundraisingId, ...data }) {
    try {
      const response = await axiosCore.post(`fundraisings/${fundraisingId}/invite-community`, data);
      commit('setFundraisingData', { fundraisingId: response.data.id, fundraisingData: {} });
      successAlert('Investor invite sent successfully!');
    } catch (error) {
      dangerAlert('The Investor could not be invited!');
    }
  },
  /**
   * Get investors invited to the fundraising community.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  fundraisingId: number; // The ID of the fundraising.
   *  page?: number; // The page of investors to return.
   *  page_size?: number; // The number of investors to return per page.
   *  useCache?: boolean; // If true, the cache will be used if it exists.
   * }} params - The query parameters to adjust page of investors returned.
  */
  async getFundraisingCommunityMembers({ commit }, {
    fundraisingId, page = 1, page_size = 10, useCache = true,
  }) {
    const fundraisingData = state.fundraisingData[fundraisingId];
    const cacheExists = !isEmpty(fundraisingData.community);
    fundraisingData.community = fundraisingData.community || { entries: [] };
    commit('setTableCachePage', {
      fundraisingId, table: 'community', page, pageSize: page_size,
    });
    if (useCache && cacheExists) {
      const cachedEntries = checkPageCache(fundraisingData.community);
      if (cachedEntries) {
        commit('setCurrentFundraisingId', fundraisingId);
        return cachedEntries;
      }
    }
    const urlParams = new URLSearchParams({ page, page_size }).toString();
    /** @type {{data: PaginatedResults<CommunityEntry> }} */
    const response = await axiosCore.get(`fundraisings/${fundraisingId}/community-members?${urlParams}`);
    const updatedTableCache = updateTableCache(response.data, fundraisingData.community);
    commit('setTableCache', { fundraisingId, table: 'community', tableCache: updatedTableCache });
    commit('setCurrentFundraisingId', fundraisingId);
    return response.data.results;
  },
};

const mutations = {
  /**
   * Toggle if the current fundraising id, used by getters, should be automatically set on each request.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} fundraisingId - ID of the fundraising.
  */
  toggleAutoSetCurrentFundraisingId: (state, autoSetCurrentFundraisingId = !state.autoSetCurrentFundraisingId) => {
    state.autoSetCurrentFundraisingId = autoSetCurrentFundraisingId;
  },
  /**
   * The fundraising id to associate with the getters.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} fundraisingId - ID of the fundraising.
  */
  setCurrentFundraisingId: (state, fundraisingId) => {
    if (state.autoSetCurrentFundraisingId) state.currentFundraisingId = +fundraisingId;
  },
  /**
   * The fundraising id to associate with the getters. This is not affected by autoSetCurrentFundraisingId.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} fundraisingId - ID of the fundraising.
  */
  forceCurrentFundraisingId: (state, fundraisingId) => state.currentFundraisingId = +fundraisingId,
  /**
   * Updates the fundraising data using supplied fields for the given fundraising ID.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} fundraisingId - ID of the fundraising.
   * @param {Fundraising} fundraisingData - Data to set for the fundraising.
  */
  setFundraisingData: (state, { fundraisingId, fundraisingData }) => {
    Vue.set(state.fundraisingData, fundraisingId, { ...state.fundraisingData[fundraisingId], ...fundraisingData });
  },
  /**
   * Updates the current page and page size for the specified table cache.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {{
   *  fundraisingId: number;
   *  table: string;
   *  page: number;
   *  pageSize: number;
   * }} payload
  */
  setTableCachePage: (state, {
    fundraisingId, table, page, pageSize,
  }) => {
    state.fundraisingData[fundraisingId][table].currentPage = page;
    state.fundraisingData[fundraisingId][table].pageSize = pageSize;
  },
  /**
   * Updates the cache for the specified table.
   * @template T
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {{
   *  fundraisingId: number;
   *  table: string;
   *  tableCache: TableCache<T>;
   * }} payload
  */
  setTableCache: (state, { fundraisingId, table, tableCache }) => {
    state.fundraisingData[fundraisingId][table] = tableCache;
  },
  updateFundraisingDataField: (state, { fundraisingId, field, value }) => {
    Vue.set(state.fundraisingData[fundraisingId], field, { ...state.fundraisingData[fundraisingId][field], ...value });
  },
  updateFundraisingField: (state, { fundraisingId, field, value }) => {
    Vue.set(state.fundraisingData[fundraisingId].fundraising, field, { ...state.fundraisingData[fundraisingId].fundraising[field], ...value });
  },
};

/** @typedef {typeof getters} Getters */
/** @typedef {typeof actions} Actions */
/** @typedef {typeof mutations} Mutations */

/**
 * @module fundraising
 * @typedef {Object} FundraisingStore
 * @property {State} state
 * @property {Getters} getters
 * @property {Actions} actions
 * @property {Mutations} mutations
 */

export default {
  state,
  getters,
  actions,
  mutations,
};
