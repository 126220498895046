<template>
  <div class="header">
    <span v-if="showDot" class="dot"></span>
    <Avatar
      v-if="showAvatar"
      :class="[{'no-mobile' : hideIconMobile},{'no-dot-padding': !showDot}]"
      :avatarType="avatarType"
      :avatarSize="size"
      :avatarColor="color"
      :initials="infoInitials"
      :imageSrc="imageSrc"
      :iconName="iconName"
    />
    <div :class="`header-info ${hideIconMobile ? 'no-margin-mobile' : ''}`">
      <div class="title-wrapper">
        <span :title="title" class="text-md--bold pr-3">
          {{ formatString(title) }}
        </span>
        <TagLabel v-if="labelStatus" :class="`label ${labelColor}`">
          {{ labelStatus }}
        </TagLabel>
      </div>

      <div :class="`stage ${statusColor === 'green' ? 'green' : ''} `">
        <slot name="subtitle">
          <img
            v-if="subtitleIcon"
            class="avatar-icon"
            :src="subtitleIcon"
          />
          {{ subtitle }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from './Avatar.vue';
import TagLabel from '../labels/TagLabel.vue';

export default {
  name: 'AvatarInfo',
  components: {
    Avatar,
    TagLabel,
  },
  props: {
    showDot: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: 'users',
    },
    imageSrc: {
      type: String,
      required: false,
    },
    initials: {
      type: String,
    },
    size: {
      type: String,
      default: 'medium',
    },
    color: {
      type: String,
      default: 'blue',
    },
    title: {
      type: String,
    },
    labelStatus: {
      required: false,
    },
    subtitle: {
      type: String,
    },
    subtitleIcon: {
      type: String,
    },
    avatarType: {
      type: String,
      default: 'icon',
    },
    statusColor: {
      validator(value) {
        return ['grey', 'green', 'blue', 'yellow'].indexOf(value) !== -1;
      },
      default: 'grey',
    },
    hideIconMobile: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    infoInitials() {
      if (this.initials) {
        return this.initials;
      }
      const words = this.title ? this.title.split(' ') : '';
      if (words) {
        const firstTwoInitials = words.slice(0, 2).map((word) => word[0]).join('');
        return firstTwoInitials;
      }
    },
    labelColor() {
      switch (this.labelStatus) {
        case 'Pending':
          return 'yellow';
        case 'Validated':
          return 'green';
        case 'Refused':
          return 'red';
        default:
          return 'blue';
      }
    },
  },
};
</script>

<style lang="scss">
.dot {
  padding: 4px;
  background-color: $green-200;
  border-radius: 50%;
}
.no-dot-padding {
  padding-left: 24px;
}
@mixin container-size($img-size) {
  width: $img-size;
  height: $img-size;
}

.base-container {
  @include make-flex(center, center, unset);
  border-radius: 50%;
  &.x24 {
    @include container-size(24px);
  }
  &.x32 {
    @include container-size(32px);
  }
  &.x48 {
    @include container-size(48px);
  }
  &.x56 {
    @include container-size(56px);
  }
  &.x64 {
    @include container-size(64px);
  }
  &.x86 {
    @include container-size(86px);
  }
}
.avatar-icon {
  margin-right: 4px;
}
.no-mobile {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}
.no-margin-mobile {
  @media (max-width: 768px) {
    margin: 0 !important;
  }
}
.header {
  display: flex;
  align-items: center;
  .img-container {
    border: 1px solid $grey-600;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }
  .icon-container {
    &.green {
      background-color: $green-500;
      color: $success;
    }
    &.yellow {
      background-color: $yellow-500;
      color: $warning;
    }
  }
  .header-info {
    @include make-flex(space-between, flex-start, column);
    margin-left: 16px;
    span {
      overflow: hidden;
    }
    .stage {
      @extend .text-sm--bold;
      display: flex;
      align-items: center;
      color: #3c3c4380;
    }
    .green {
      color: $success;
    }
    .title-wrapper {
      @media (max-width: 500px) {
        align-items: center;
      }
    }
  }
}
.label {
  border: none  !important;
}
.green {
    color: $green-200;
    background-color: $green-500;
}
.yellow {
    color: $yellow-200;
    background-color: $yellow-500;
}
.pink {
    color: $cobalt-200;
    background-color: $cobalt-600;
}
.blue {
    color: $blue-200;
    background-color: $blue-500;
}
.red {
    color: $red-200;
    background-color: $red-500;
}
.grey {
    color: $grey-200;
    background-color: $grey-500;
}
</style>
