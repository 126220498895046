<template>
    <b-img
        :src="src"
        :width="width"
        :height="height"
        :rounded="isCircle ? 'circle' : false">
    </b-img>
</template>

<script>
export default {
  name: 'CustomImage',
  props: {
    src: {
      type: String,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    'is-circle': {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
