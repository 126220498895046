import moment from 'moment';

// returns a year (string)
export const investmentYearsDiff = (year) => moment().diff(year, 'years');

export const countYearDiffFromNow = (year) => {
  if (year) {
    const yearsDifference = investmentYearsDiff(year);
    const currentInvestmentYear = `${moment.localeData().ordinal(yearsDifference)} year(s)`;

    return currentInvestmentYear;
  }

  return '-';
};
