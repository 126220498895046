import { axiosCore } from '@/plugins/axios';

const cleanKycBeneficialOwnerForm = {
  id: null,
  kyc_fund: null,
  first_name: null,
  last_name: null,
  country_of_birth: null,
  city_of_birth: null,
  birthday: null,
  residential_address: null,
  postal_code: null,
  proof_of_identity_document: null,
  proof_of_identiy_expiration_date: null,
  proof_of_address_document: null,
  tax_document: null,
  residence_country: null,
  residence_city: null,
  taxpayer_identification_number: null,
  incoming_range: null,
  tin_number: null,
  is_politically_exposed: null,
  political_exposed_position: null,
  economic_interest_percentage: null,
  voting_rights_percentage: null,
};

const accepted_keys = Object.keys(cleanKycBeneficialOwnerForm);

const state = {
  kycBeneficialOwner: [],
  kycBeneficialOwnerForm: {},
  isKycBeneficialOwnerLoading: false,
};

const getters = {
  getKycBeneficialOwner: (state) => state.kycBeneficialOwner,
  getKycBeneficialOwnerForm: (state) => state.kycBeneficialOwnerForm,
  cleanBeneficialOwnerForm: (state) => accepted_keys.reduce((obj, key) => ({ ...obj, [key]: state.kycBeneficialOwnerForm[key] }), {}),
  BenefOwnerExists: (state) => state.kycBeneficialOwnerForm.id,
  getIsKycBeneficialOwnerLoading: (state) => state.isKycBeneficialOwnerLoading,
  beneficialOwnerHasAllDocuments: (state) => {
    const ownerDocsPresent = {
      legal: {},
      natural: {},
    };
    state.kycBeneficialOwner.forEach((beneficialOwner) => {
      ownerDocsPresent.natural[beneficialOwner.id] = !!beneficialOwner.proof_of_identity_document
        && !!beneficialOwner.proof_of_address_document
        && !!beneficialOwner.tax_document;
      ownerDocsPresent.legal[beneficialOwner.id] = !!beneficialOwner.proof_of_identity_document;
    });
    return ownerDocsPresent;
  },
};

const mutations = {
  clearKycBeneficialOwnerForm: (state, _) => {
    state.kycBeneficialOwnerForm = { ...cleanKycBeneficialOwnerForm };
  },
  setKycBeneficialOwner: (state, data) => { state.kycBeneficialOwner = data; },
  setKycBeneficialOwnerForm: (state, data) => { state.kycBeneficialOwnerForm = data; },
  setKycBeneficialOwnerFormProperty: (state, data) => {
    state.kycBeneficialOwnerForm[data.key] = data.value;
  },
  setIsKycBeneficialOwnerLoading: (state, data) => { state.isKycBeneficialOwnerLoading = data; },
};

const actions = {

  async retrieveKycBeneficialOwner({ commit, dispatch, getters }, beneficialOwnerId) {
    commit('setIsKycBeneficialOwnerLoading', true);
    const investorId = getters.portfolioInvestorId;
    const kycFundId = getters.getKycFund.id;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/beneficial-owner/${beneficialOwnerId}`;
    try {
      const response = await axiosCore.get(url);
      commit('setKycBeneficialOwnerForm', response.data);
    } catch {
      commit('setKycBeneficialOwner', {});
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get your Beneficial Owner.',
      });
    } finally {
      commit('setIsKycBeneficialOwnerLoading', false);
    }
  },

  async fetchKycBeneficialOwner({ commit, dispatch, getters }) {
    commit('setIsKycBeneficialOwnerLoading', true);
    const investorId = getters.portfolioInvestorId;
    const kycFundId = getters.getKycFund.id;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/beneficial-owner`;
    try {
      const response = await axiosCore.get(url);
      commit('setKycBeneficialOwner', response.data);
    } catch {
      commit('setKycBeneficialOwner', []);
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get your Beneficial Owner.',
      });
    } finally {
      commit('setIsKycBeneficialOwnerLoading', false);
    }
  },

  async createKycBeneficialOwner({ commit, dispatch, getters }) {
    commit('setIsKycBeneficialOwnerLoading', true);
    const investorId = getters.portfolioInvestorId;
    const kycFundId = getters.getKycFund.id;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/beneficial-owner`;
    const data = getters.cleanBeneficialOwnerForm;
    data.kyc_fund = kycFundId;
    try {
      await axiosCore.post(url, data);
      dispatch('successAlert', { message: 'The Beneficial Owner has been successfully created' });
      dispatch('fetchKycBeneficialOwner');
      commit('setKycBeneficialOwnerForm', {});
    } catch {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to save your Beneficial Owner.',
      });
    } finally {
      commit('setIsKycBeneficialOwnerLoading', false);
    }
  },

  /**
   * Partial object update, patches only updated fields
   */
  async patchKycBeneficialOwner({ commit, dispatch, getters }) {
    commit('setIsKycBeneficialOwnerLoading', true);
    const investorId = getters.portfolioInvestorId;
    const kycFundId = getters.getKycFund.id;
    const data = getters.getKycBeneficialOwnerForm;
    data.kyc_fund = kycFundId;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/beneficial-owner/${data.id}`;
    try {
      await axiosCore.patch(url, data);
      commit('setKycBeneficialOwnerForm', {});
    } catch {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to save your Beneficial Owner.',
      });
    } finally {
      commit('setIsKycBeneficialOwnerLoading', false);
    }
  },

  /**
   * Full object update
   */
  async updateKycBeneficialOwner({ commit, dispatch, getters }) {
    commit('setIsKycBeneficialOwnerLoading', true);
    const investorId = getters.portfolioInvestorId;
    const kycFundId = getters.getKycFund.id;
    const data = getters.cleanBeneficialOwnerForm;
    data.kyc_fund = kycFundId;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/beneficial-owner/${data.id}`;
    try {
      await axiosCore.patch(url, data);
    } catch {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to save your Beneficial Owner.',
      });
    } finally {
      commit('setIsKycBeneficialOwnerLoading', false);
    }
  },

  async deleteKycBeneficialOwner({ commit, dispatch, getters }, beneficialOwnerId) {
    commit('setIsKycBeneficialOwnerLoading', true);
    const investorId = getters.portfolioInvestorId;
    const kycFundId = getters.getKycFund.id;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/beneficial-owner/${beneficialOwnerId}`;
    try {
      await axiosCore.delete(url);
      dispatch('successAlert', { message: 'The Beneficial Owner has been successfully deleted' });
    } catch {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to delete your Beneficial Owner.',
      });
    } finally {
      commit('setIsKycBeneficialOwnerLoading', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
