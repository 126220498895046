<template>
  <span :class="['tag', tagColor, { 'hoverable': hoverable }]">
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'TagLabel',
  props: {
    hoverable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: false,
    },
  },
  computed: {
    tagColor() {
      const colors = ['green', 'yellow', 'pink', 'blue', 'red', 'grey'];
      if (colors.includes(this.color)) return `tag--${this.color}`;
    },
  },
};
</script>

<style lang="scss" scoped src='@/assets/css/components/labels.scss'>
</style>
