const errorDict2String = (dict) => {
  const errors = [];
  for (const [key, value] of Object.entries(dict)) {
    if (Array.isArray(value)) errors.push(`${key}: ${value[0]}`);
    else errors.push(`${key}: ${value}`);
  }
  return errors.join('\n');
};

export const parseError = (defaultErrorString, error) => {
  defaultErrorString = defaultErrorString || "Something went wrong.";
  if (!error) return defaultErrorString;
  const errorData = error.response?.data || error.message;
  if (typeof errorData === 'string') if (!errorData.includes('Request failed with status code')) return errorData;
  if (typeof errorData === 'object' && !Array.isArray(errorData)) return errorDict2String(errorData);
  return defaultErrorString;
};

const currencyEquivalences = {
  USD: '$', EUR: '€', GBP: '£', INR: '₹', JPY: '¥',
};

export function numberWithCommas(x) {
  return x.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

export function currencySymbol(currency) {
  if (Object.prototype.hasOwnProperty.call(currencyEquivalences, currency)) {
    return currencyEquivalences[currency];
  } else {
    return '';
  }
}

// ex: 200000 => €200,000
export function formatAmountWithCurrencyAndCommas(currency, amount) {
  amount = Math.round(amount * 100) / 100;
  return `${currencySymbol(currency)}${numberWithCommas(amount)}`;
}

// ex: 200000 => € 200,000 (space after currency)
export function formatAmountWithCurrencyCommasAndSpace(currency, amount) {
  amount = Math.round(amount * 100) / 100;
  return `${currencySymbol(currency)} ${numberWithCommas(amount)}`;
}

export function setCompactAmount(value, currency, isOnlyThousands) {
  value = value ? Math.round(value) : 0;

  let suffixNum = 0;
  let suffix = '';
  if (isOnlyThousands) {
    suffixNum = 1;
    suffix = ' K';
  } else {
    suffixNum = Math.floor((value.toString().length - 1) / 3);
    suffix = ['', 'K', 'M', 'B', 'T'][suffixNum];
  }
  
  let shortValue = parseFloat(((value / (1000 ** suffixNum))).toPrecision(8));

  if (shortValue % 1 != 0) { 
    // has decimal: correct decimal places
    if (value < 1_000_000 || isOnlyThousands) {
      shortValue = shortValue.toFixed(0);
    } else {
      shortValue = shortValue.toFixed(1);
    }
  }

  return `${formatAmountWithCurrencyCommasAndSpace(currency, shortValue)}${suffix}`;
}

export function humanize (/** @type {string} */ str) {
  let result = '';
  for (const frag of str.split('_')) {
    result += frag.charAt(0).toUpperCase() + frag.slice(1) + ' ';
  }
  return result.trim();
}
