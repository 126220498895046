import { axiosCore } from '@/plugins/axios';

// utils
import { capitalize, snakeToWord, snakeToPascal } from '@/utils/text';
import { getObjectStatus } from '@/store/modules/entities/investor/investor_legal_information';

const state = {
  // Partner related investor
  partnerInvestorLegalInformation: {},
  isPartnerInvestorLegalInfoLoading: false,
  isPartnerInvestorLegalInfoFetchedSuccessfully: false,
  documentShareholderDeclaration: null,

  // Partner
  partnerLegalInformation: {},
  isPartnerLegalInfoLoading: false,
  isPartnerLegalInfoFetchedSuccessfully: false,
  isPartnerLegalInfoUpdatedSuccessfully: false,

  // Partner related documents
  documentIdentityOfProof: null,
  documentArticlesOfAssociation: null,
  documentRegistrationProof: null,
  isHandlingDocumentIdentityOfProof: false,
  isHandlingDocumentArticlesOfAssociation: false,
  isHandlingDocumentRegistrationProof: false,
};

const getters = {
  getIsPartnerInvestorLegalInfoFetchedSuccessfully: (state) => state.isPartnerInvestorLegalInfoFetchedSuccessfully,
  partnerRelatedInvestorAddressInfo: (state) => ({
    ...state.partnerInvestorLegalInformation,
    address_line_1: state.partnerInvestorLegalInformation.representative_address?.address_line_1,
    address_line_2: state.partnerInvestorLegalInformation.representative_address?.address_line_2,
    city: state.partnerInvestorLegalInformation.representative_address?.city,
    region: state.partnerInvestorLegalInformation.representative_address?.region,
    postal_code: state.partnerInvestorLegalInformation.representative_address?.postal_code,
    country: state.partnerInvestorLegalInformation.representative_address?.country,
    country_name: state.partnerInvestorLegalInformation.representative_address?.country_name?.name,
  }),
  addressStatus: (state, getters) => {
    const objToCheck = { ...getters.partnerRelatedInvestorAddressInfo };
    delete objToCheck.address_line_2;
    delete objToCheck.country_name;
    return getObjectStatus(objToCheck);
  },
  partnerAddressInfo: (state) => ({
    address_line_1: state.partnerLegalInformation.representative_address?.address_line_1,
    address_line_2: state.partnerLegalInformation.representative_address?.address_line_2,
    city: state.partnerLegalInformation.representative_address?.city,
    region: state.partnerLegalInformation.representative_address?.region,
    postal_code: state.partnerLegalInformation.representative_address?.postal_code,
    country: state.partnerLegalInformation.representative_address?.country,
    country_name: state.partnerLegalInformation.representative_address?.country_name?.name,
  }),
  partnerAddressStatus: (state, getters) => {
    const objToCheck = { ...getters.partnerAddressInfo };
    delete objToCheck.address_line_2;
    delete objToCheck.country_name;
    return getObjectStatus(objToCheck);
  },
  partnerRelatedInvestorBusinessIdentityInfo: (state) => ({
    company_name: state.partnerInvestorLegalInformation.company_name,
    contact_email: state.partnerInvestorLegalInformation.contact_email,
    company_number: state.partnerInvestorLegalInformation.company_number,
    corporate_form: state.partnerInvestorLegalInformation.corporate_form,
    share_capital: state.partnerInvestorLegalInformation.share_capital,
  }),
  businessIdentityStatus: (state, getters) => getObjectStatus(getters.partnerRelatedInvestorBusinessIdentityInfo),
  partnerBusinessIdentityInfo: (state) => ({
    company_name: state.partnerLegalInformation.company_name,
    contact_email: state.partnerLegalInformation.contact_email,
    company_number: state.partnerLegalInformation.company_number,
    corporate_form: state.partnerLegalInformation.corporate_form,
    share_capital: state.partnerLegalInformation.share_capital,
  }),
  partnerRelatedInvestorHeadquarterInfo: (state) => ({
    address_line_1: state.partnerInvestorLegalInformation.business_address?.address_line_1,
    address_line_2: state.partnerInvestorLegalInformation.business_address?.address_line_2,
    city: state.partnerInvestorLegalInformation.business_address?.city,
    region: state.partnerInvestorLegalInformation.business_address?.region,
    postal_code: state.partnerInvestorLegalInformation.business_address?.postal_code,
    country: state.partnerInvestorLegalInformation.business_address?.country,
    country_name: state.partnerInvestorLegalInformation.business_address?.country_name?.name,
  }),
  headquartersStatus: (state, getters) => {
    const objToCheck = { ...getters.partnerRelatedInvestorHeadquarterInfo };
    delete objToCheck.address_line_2;
    delete objToCheck.country_name;
    return getObjectStatus(objToCheck);
  },
  partnerHeadquarterInfo: (state) => ({
    address_line_1: state.partnerLegalInformation.business_address?.address_line_1,
    address_line_2: state.partnerLegalInformation.business_address?.address_line_2,
    city: state.partnerLegalInformation.business_address?.city,
    region: state.partnerLegalInformation.business_address?.region,
    postal_code: state.partnerLegalInformation.business_address?.postal_code,
    country: state.partnerLegalInformation.business_address?.country,
    country_name: state.partnerLegalInformation.business_address?.country_name?.name,
  }),
  partnerRelatedInvestorRepresentativeIdentityInfo: (state) => {
    const fields = {
      ...state.partnerInvestorLegalInformation,
      first_name: state.partnerInvestorLegalInformation.first_name,
      last_name: state.partnerInvestorLegalInformation.last_name,
      email: state.partnerInvestorLegalInformation.email,
      country_of_residence: state.partnerInvestorLegalInformation.country_of_residence,
      country_of_residence_name: state.partnerInvestorLegalInformation.country_of_residence_name,
      birthday: state.partnerInvestorLegalInformation.birthday,
      representant_nationality: state.partnerInvestorLegalInformation.representant_nationality,
      representant_nationality_name: state.partnerInvestorLegalInformation.representant_nationality_name,
      proof_of_identity_expiration_date: state.partnerInvestorLegalInformation.proof_of_identity_expiration_date,
    };
    if (state.partnerInvestorLegalInformation.type === 'natural') {
      fields.position = state.partnerInvestorLegalInformation.position;
      fields.incoming_range = state.partnerInvestorLegalInformation.incoming_range;
    }
    return fields;
  },
  partnerRepresentativeIdentityInfo: (state) => {
    const fields = {
      first_name: state.partnerLegalInformation.first_name,
      last_name: state.partnerLegalInformation.last_name,
      email: state.partnerLegalInformation.email,
      country_of_residence: state.partnerLegalInformation.country_of_residence,
      country_of_residence_name: state.partnerLegalInformation.country_of_residence_name,
      birthday: state.partnerLegalInformation.birthday,
      representant_nationality: state.partnerLegalInformation.representant_nationality,
      representant_nationality_name: state.partnerLegalInformation.representant_nationality_name,
      proof_of_identity_expiration_date: state.partnerLegalInformation.proof_of_identity_expiration_date,
    };
    if (state.partnerLegalInformation.type === 'natural') {
      fields.position = state.partnerLegalInformation.position;
      fields.incoming_range = state.partnerLegalInformation.incoming_range;
    }
    return fields;
  },
  getPartnerInvestorLegalInfo: (state) => state.partnerInvestorLegalInformation,
  partnerRepresentativeIdentityStatus: (state, getters) => {
    const objToCheck = { ...getters.partnerRepresentativeIdentityInfo };
    delete objToCheck.country_of_residence_name;
    delete objToCheck.representant_nationality_name;
    if (state.investorLegalInformation?.type !== 'natural') {
      delete objToCheck.position;
      delete objToCheck.incoming_range;
    }
    return getObjectStatus(objToCheck);
  },
  getInvestorLegalInfo: (state) => state.partnerInvestorLegalInformation,
  getInvestorKycStatus: (state) => state.partnerInvestorLegalInformation.status,
  getInvestorKycStatusCapitalized:
    (state) => capitalize(snakeToWord(state.partnerInvestorLegalInformation.status)),
  getInvestorKycType: (state) => state.partnerInvestorLegalInformation.type,
  getInvestorKycTypeCapitalized:
    (state) => capitalize(snakeToWord(state.partnerInvestorLegalInformation.type)),
  getPartnerLegalInformation: (state) => state.partnerLegalInformation,
};

const actions = {
  // Partner related Investor
  async fetchPartnerInvestorLegalInfo({ commit, rootGetters }, investorId) {
    commit('setIsPartnerInvestorLegalInfoLoading', true);
    commit('setIsPartnerInvestorLegalInfoFetchedSuccessfully', false);

    try {
      const partnerId = rootGetters.entityId;
      const response = await axiosCore.get(`partners/${partnerId}/investors/${investorId}/edit-profile`);
      commit('setPartnerInvestorLegalInformation', response.data);
      commit('setIsPartnerInvestorLegalInfoFetchedSuccessfully', true);
    } catch (error) {
      commit('setIsPartnerInvestorLegalInfoFetchedSuccessfully', false);
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerInvestorLegalInfoLoading', false);
    }
  },

  // Partner related Investor
  async patchPartnerInvestorLegalInfo({ commit, dispatch, rootGetters }, { investorId, data }) {
    commit('setIsPartnerInvestorLegalInfoLoading', true);
    commit('setIsPartnerInvestorLegalInfoFetchedSuccessfully', false);
    try {
      const partnerId = rootGetters.entityId;
      const response = await axiosCore.patch(`partners/${partnerId}/investors/${investorId}/edit-profile`, data);
      commit('setPartnerInvestorLegalInformation', response.data);
      commit('setIsPartnerInvestorLegalInfoFetchedSuccessfully', true);
      dispatch('successAlert', { message: 'Legal information successfully updated!' }, { root: true });
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong when updating legal information.' }, { root: true });
      commit('setIsPartnerInvestorLegalInfoFetchedSuccessfully', false);
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerInvestorLegalInfoLoading', false);
    }
  },
  async fetchPartnerLegalInfo({ commit, rootGetters }) {
    commit('setIsPartnerLegalInfoLoading', true);
    commit('setIsPartnerLegalInfoFetchedSuccessfully', false);

    try {
      const partnerId = rootGetters.entityId;
      const response = await axiosCore.get(`partners/${partnerId}/legal-information`);
      commit('setPartnerLegalInformation', response.data);
      commit('setIsPartnerLegalInfoFetchedSuccessfully', true);
    } catch (error) {
      commit('setIsPartnerLegalInfoFetchedSuccessfully', false);
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerLegalInfoLoading', false);
    }
  },
  async patchPartnerLegalInfo({ commit, rootGetters, dispatch }, data) {
    commit('setIsPartnerLegalInfoLoading', true);
    commit('setIsPartnerLegalInfoUpdatedSuccessfully', false);
    try {
      const partnerId = rootGetters.entityId;
      const response = await axiosCore.patch(`partners/${partnerId}/legal-information`, data);
      commit('setPartnerLegalInformation', response.data);
      commit('setIsPartnerLegalInfoUpdatedSuccessfully', true);
      dispatch('successAlert', { message: 'Legal information successfully updated!' }, { root: true });
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong when updating legal information.' }, { root: true });
      commit('setIsPartnerLegalInfoUpdatedSuccessfully', false);
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerLegalInfoLoading', false);
    }
  },
};

const mutations = {
  setIsPartnerInvestorLegalInfoLoading:
  (state, data) => {
    state.isPartnerInvestorLegalInfoLoading = data;
  },
  setPartnerInvestorLegalInformation: (state, data) => {
    state.partnerInvestorLegalInformation = data;
  },
  setIsPartnerInvestorLegalInfoFetchedSuccessfully: (state, data) => {
    state.isPartnerInvestorLegalInfoFetchedSuccessfully = data;
  },
  setIsPartnerLegalInfoLoading: (state, data) => state.isPartnerLegalInfoLoading = data,
  setPartnerLegalInformation: (state, data) => state.partnerLegalInformation = data,
  setIsPartnerLegalInfoFetchedSuccessfully: (state, data) => {
    state.isPartnerLegalInfoFetchedSuccessfully = data;
  },
  setIsPartnerLegalInfoUpdatedSuccessfully: (state, data) => {
    state.isPartnerLegalInfoUpdatedSuccessfully = data;
  },
  setProofOfIdentity: (state, data) => state.isProofOfIdentitySubmitted = data,

  setDocumentIdentityOfProof: (state, data) => state.documentIdentityOfProof = data,
  setDocumentArticlesOfAssociation: (state, data) => state.documentArticlesOfAssociation = data,
  setDocumentRegistrationProof: (state, data) => state.documentRegistrationProof = data,
  setIsHandlingDocumentIdentityOfProof: (state, data) => {
    state.isHandlingDocumentIdentityOfProof = data;
  },
  setIsHandlingDocumentArticlesOfAssociation: (state, data) => {
    state.isHandlingDocumentArticlesOfAssociation = data;
  },
  setIsHandlingDocumentRegistrationProof: (state, data) => {
    state.isHandlingDocumentRegistrationProof = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
