import { axiosCoreNoToken } from '@/plugins/axios';

const actions = {
  apiPatchRecoverPassword({}, recover_body) {
    return new Promise((resolve, reject) => {
      axiosCoreNoToken
        .post('/auth/users/recover-password', recover_body)
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(error);
        });
    });
  },
};

export default {
  actions,
};
