import { axiosCore } from '@/plugins/axios';

const state = {
  shareholders: [],
  areShareholdersLoading: false,
  areShareholdersFetchedSuccessfully: false,
  formData: {
    id: null,
    first_name: null,
    last_name: null,
    birthplace: null,
    birthplace_name: null,
    birthday: null,
    nationality: null,
    nationality_name: null,
    address: null,
    address_complement: null,
    city: null,
    region: null,
    postal_code: null,
    country: null,
    country_name: null,
    is_active: true,
  },
};

const getters = {
};

const actions = {
  async fetchShareholders({ commit }, investorId) {
    commit('setAreShareholdersLoading', true);
    commit('setAreShareholdersFetchedSuccessfully', false);

    try {
      const response = await axiosCore.get(`investors/${investorId}/ubos`);
      commit('setShareholders', response.data);
      commit('setAreShareholdersFetchedSuccessfully', true);
    } catch (error) {
      commit('setAreShareholdersFetchedSuccessfully', false);
      throw new Error(error.message);
    } finally {
      commit('setAreShareholdersLoading', false);
    }
  },
  createOrPatchShareholder({ dispatch }, data) {
    if (data.id) {
      dispatch('patchExistingShareholder', data);
    } else {
      dispatch('createNewShareholder', data);
    }
  },
  async patchExistingShareholder({
    state, commit, getters, dispatch,
  }, data) {
    commit('setIsCreateNewShareholderLoading', true);
    const body = {
      ...data,
    };
    if (!['string', 'number'].includes(typeof data.country)) {
      body.country = data.country.id;
    }
    if (!['string', 'number'].includes(typeof data.nationality)) {
      body.nationality = data.nationality.id;
    }
    try {
      const investorId = getters.portfolioInvestorId;
      const uboId = body.id;
      const response = await axiosCore.patch(`investors/${investorId}/ubos/${uboId}`, body);
      const shareholderIndex = state.shareholders.findIndex((item) => item.id === uboId);
      const updatedUbos = [...state.shareholders];
      updatedUbos[shareholderIndex] = response.data;
      commit('setShareholders', [...updatedUbos]);
      dispatch('successAlert', { message: 'Shareholder successfully updated!' });
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong when updating the shareholder.' });
      throw new Error(error.message);
    } finally {
      commit('setIsCreateNewShareholderLoading', false);
      commit('clearShareHolderFormData');
    }
  },
  async createNewShareholder({
    state, commit, getters, dispatch,
  }, data) {
    commit('setIsCreateNewShareholderLoading', true);
    const body = {
      ...data,
      country: data.country.id,
      nationality: data.nationality.id,
    };
    try {
      const investorId = getters.portfolioInvestorId;
      const response = await axiosCore.post(`investors/${investorId}/create-ubo`, body);
      commit('setShareholders', [...state.shareholders, response.data]);
      dispatch('successAlert', { message: 'New shareholder successfully created!' });
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong when creating new shareholder.' });
      throw new Error(error.message);
    } finally {
      commit('setIsCreateNewShareholderLoading', false);
      commit('clearShareHolderFormData');
    }
  },
  async removeShareholder({
    state, commit, getters, dispatch,
  }, data) {
    commit('setAreShareholdersLoading', true);
    try {
      const investorId = getters.portfolioInvestorId;
      const uboId = data.id;
      await axiosCore.delete(`investors/${investorId}/ubos/${uboId}`);
      const shareholderIndex = state.shareholders.findIndex((item) => item.id === uboId);
      const updatedUbos = [...state.shareholders];
      updatedUbos.splice(shareholderIndex, 1);
      commit('setShareholders', [...updatedUbos]);
      dispatch('successAlert', { message: 'New shareholder successfully deleted!' });
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong when deleting the shareholder.' });
      throw new Error(error.message);
    } finally {
      commit('setAreShareholdersLoading', false);
    }
  },
};

const mutations = {
  updateShareHolderFormData: (state, data) => state.formData = { ...state.formData, ...data },
  clearShareHolderFormData: (state) => {
    state.formData = {
      id: null,
      first_name: null,
      last_name: null,
      birthplace: null,
      birthplace_name: null,
      birthday: null,
      nationality: null,
      nationality_name: null,
      address: null,
      address_complement: null,
      city: null,
      region: null,
      postal_code: null,
      country: null,
      country_name: null,
      is_active: true,
    };
  },
  setShareholders: (state, data) => state.shareholders = data,
  setAreShareholdersLoading: (state, data) => state.areShareholdersLoading = data,
  setAreShareholdersFetchedSuccessfully: (state, data) => state.areShareholdersFetchedSuccessfully = data,
  setIsCreateNewShareholderLoading: (state, data) => state.isCreateNewShareholderLoading = data,
};
export default {
  state,
  getters,
  actions,
  mutations,
};
