import { errorAlert } from '@/ort-lib/utils/utils';
import { axiosCore } from '@/plugins/axios';

const state = {
  fundraisingCommits: [],
  fundraisingTeam: [],
  fundraisingDocumentation: [],
  fundraisingIsLoading: false,
  fundraisingTeamIsLoading: false,
  error: null,
  fundraisingDetails: {},
  requestDeepAnalysisIsLoading: false,
  requestDeepAnalysisResponse: false,
};

const getters = {

};

const mutations = {
  setFundraisingCommits: (state, data) => {
    state.fundraisingCommits = data;
  },
  setFundraisingIsLoading: (state, data) => {
    state.fundraisingIsLoading = data;
  },
  setFundraisingTeamIsLoading: (state, data) => {
    state.fundraisingTeamIsLoading = data;
  },
  setFundraisingTeam: (state, data) => {
    state.fundraisingTeam = data;
  },
  setFundraisingDocument: (state, data) => {
    state.fundraisingDocumentation = data;
  },
  setError: (state, data) => {
    state.error = data;
  },
  setFundraisingDetails: (state, data) => {
    state.fundraisingDetails = data;
  },
  setRequestDeepAnalysisIsLoading: (state, data) => {
    state.requestDeepAnalysisIsLoading = data;
  },
  setRequestDeepAnalysisResponse: (state, data) => {
    state.requestDeepAnalysisResponse = data;
  },
};

const actions = {
  async fetchFundraisingTeam({ commit, getters, dispatch }, pk) {
    commit('setFundraisingTeamIsLoading', true);
    try {
      const response = await axiosCore
        .get(
          `investors/fundraisings/${pk}/team`,
        );
      commit('setFundraisingTeam', response.data);
    } catch (error) {
      commit('setError', error?.message || 'Something went wrong');
      errorAlert('Failed to fetch team.', error);
    } finally {
      commit('setFundraisingTeamIsLoading', false);
    }
  },
  async fetchFundraisingDocuments({ commit, getters, dispatch }, pk) {
    commit('setFundraisingIsLoading', true);
    try {
      const response = await axiosCore
        .get(
          `investors/fundraisings/${pk}/documentation`,
        );
      commit('setFundraisingDocument', response.data);
    } catch (error) {
      commit('setError', error?.message || 'Something went wrong');
    }
  },
  async fetchFundraisingDetails({ commit, dispatch }, pk) {
    commit('setFundraisingIsLoading', true);
    try {
      const response = await axiosCore.get(`/fundraisings/${pk}/basic-info`);
      commit('setFundraisingDetails', response.data);
    } catch (error) {
      commit('setError', 'Something went wrong.');
      errorAlert('Failed to fetch fundraising details.', error);
    } finally {
      commit('setFundraisingIsLoading', false);
    }
  },
  makeInvestment({}, body) {
    return new Promise((resolve, reject) => {
      axiosCore.post('/investment/', body).then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  },
  sendEmail({}, investmentId) {
    return new Promise((resolve, reject) => {
      axiosCore.post(`/investment/${investmentId}/send_email`).then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  },
  async requestDoc({ commit, dispatch }, { pk, doc }) {
    commit('setRequestDeepAnalysisIsLoading', true);
    commit('setRequestDeepAnalysisResponse', false);
    try {
      await axiosCore.post(`fundraisings/${pk}/request-doc?doc=${doc}`);
      commit('setRequestDeepAnalysisResponse', true);
    } catch (error) {
      commit('setRequestDeepAnalysisResponse', false);
      dispatch('dangerAlert', {
        message: error?.message || 'Something went wrong',
      });
      throw new Error(error?.message || 'Request Deep Analysis failed');
    } finally {
      commit('setRequestDeepAnalysisIsLoading', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
