import { store } from '@/store/index';

/**
 * @function
 * @template DispatchPayload
 * @template DispatchResult
 * @param {function(any, DispatchPayload): DispatchResult} action - Name of action to get.
 * @returns {function(DispatchPayload): DispatchResult}
 */
export function getAction(action) {
  return (...params) => store.dispatch(action, ...params);
}

/**
 * @function
 * @template CommitPayload
 * @param {function(any, CommitPayload): void} mutation - Name of mutation to get.
 * @returns {function(CommitPayload): void}
 */
export function getMutation(mutation) {
  return (...params) => store.commit(mutation, ...params);
}

/**
 * @function
 * @template GetterResult
 * @param {function(State): GetterResult} getter - Name of getter to get.
 * @returns {GetterResult}
 */
export function getGetter(getter) {
  return store.getters[getter];
}

/**
 * @function
 * @template State
 * @param {State} state - Name of state to get.
 * @returns {State}
 */
export function getState(state) {
  return store.state[state];
}
