<template>
  <SelectorRadio
    class="mb-3"
    :id="relationshipId"
    :value="ownerId"
    :title="entityName"
    :subtitle="entityType"
    :selected="selected"
    :imageSrc="entityProfilePicture"
    :initials="entityInitials"
    :avatarType="entityAvatarType"
    :iconName="iconName"
    @emitSelectedEntity="emitSelectedEntityWithPhoto"
  />
</template>

<script>
import { mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import SelectorRadio from '@/components/molecules/toggles/radio/SelectorRadio.vue';

export default {
  name: 'EntityWithPhotoSelector',
  components: {
    SelectorRadio,
  },
  emits: ['entityData'],
  props: {
    relationshipId: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: 'user',
    },
  },
  computed: {
    getEntityInitials() {
      const name = this.entityName.split(' ');
      return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`;
    },
  },
  methods: {
    ...mapActions(['getEntityProfilePicture']),
    emitSelectedEntityWithPhoto(relationshipId, entityType) {
      this.$emit('entityData', relationshipId, entityType);
    },
    setEntityAvatar() {
      const data = {
        user_id: this.ownerId,
        type: this.entityType,
        relation_id: this.relationshipId,
      };
      this.getEntityProfilePicture(data)
        .then((response) => {
          if (response.avatar_images) {
            const image_to_show = response.avatar_images.filter(
              (image) => image.pixel_size_height === 56,
            );
            this.entityProfilePicture = image_to_show[0] ? image_to_show[0].image_file : '';
          }
          this.setAvatarType();
        })
        .catch((err) => {
          this.setAvatarType();
        });
    },
    setAvatarType() {
      if (isEmpty(this.entityProfilePicture)) {
        this.entityAvatarType = 'initials';
        this.entityInitials = this.getEntityInitials;
        if (isEmpty(this.entityInitials)) {
          this.entityAvatarType = 'icon';
        }
      } else {
        this.entityAvatarType = 'image';
      }
    },
  },
  mounted() {
    this.setEntityAvatar();
  },
  data() {
    return {
      entityProfilePicture: '',
      entityInitials: '',
      entityAvatarType: 'icon',
    };
  },
};
</script>

<style lang="scss">
.entities-header {
  display: flex;
  justify-content: space-between;
  & h3 {
    @extend .text-md--bold;
    color: $secondary-label;
  }
}
</style>
