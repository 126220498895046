import { axiosCore } from '@/plugins/axios';

const state = {
  articles: [],
  articlesLoading: false,
  webinar: {},
  thumbnail: [],
  webinarLoading: false,
};

const getters = {
  getArticles: (state) => state.articles,
  isArticlesLoading: (state) => state.articlesLoading,
  isArticlesFull: (state) => state.articles.length >= 5,
  getWebinar: (state) => state.webinar,
  getThumbnail: (state) => state.thumbnail,
  isWebinarLoading: (state) => state.webinarLoading,
};

const actions = {
  async fetchFundraisingArticles({ commit }, fundraisingId) {
    commit('setArticleLoading', true);
    try {
      const response = await axiosCore.get(`fundraisings/${fundraisingId}/articles`);
      commit('setArticles', response.data);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setArticleLoading', false);
    }
  },
  async fetchFundraisingWebinar({ commit, dispatch }, fundraisingId) {
    commit('setWebinarLoading', true);
    try {
      const response = await axiosCore.get(`fundraisings/${fundraisingId}/webinars`);
      commit('setWebinar', response.data);
      dispatch('fetchThumbnail', response.data.id);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setWebinarLoading', false);
    }
  },
  async fetchThumbnail({ commit }, webinarId) {
    commit('setWebinarLoading', true);
    try {
      const response = await axiosCore.get(`files/image/webinars/${webinarId}/thumbnail/`);
      commit('setThumbnail', response.data);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setWebinarLoading', false);
    }
  },
};

const mutations = {
  setArticleLoading: (state, loading) => (state.articlesLoading = loading),
  setArticles: (state, data) => (state.articles = data),
  setWebinar: (state, data) => (state.webinar = data),
  setWebinarLoading: (state, loading) => (state.webinarLoading = loading),
  setThumbnail: (state, data) => (state.thumbnail = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
