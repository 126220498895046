import { errorAlert } from '@/ort-lib/utils/utils';
import { axiosCore } from '@/plugins/axios';

const state = {
  activePartnerClient: {},
  partnerInvestorByUserRelationships: [],
  partnerInvestorByUserRelationshipsIsLoading: false,
};
const getters = {
  isActivePartnerClientEmpty: (state) => Object.keys(state.activePartnerClient).length == 0,
  getActivePartnerClient: (state) => state.activePartnerClient,
  getPartnerInvestorByUserRelationships: (state) => state.partnerInvestorByUserRelationships,
  getPartnerInvestorByUserRelationshipsIsLoading: (state) => state.partnerInvestorByUserRelationshipsIsLoading,
};
const mutations = {
  setPartnerInvestorByUserRelationships: (state, data) => {
    state.partnerInvestorByUserRelationships = data;
  },
  setPartnerInvestorByUserRelationshipsIsLoading: (state, data) => {
    state.partnerInvestorByUserRelationshipsIsLoading = data;
  },
  setActivePartnerClient: (state, data) => {
    state.activePartnerClient = data;
  },
};

const actions = {
  getPartnerRelationshipsCore(context) {
    const coreId = context.getters.getUserId;
    const url = `/partners/user/${coreId}/partner-relationships`;
    return new Promise((resolve, reject) => {
      axiosCore.get(url).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  async fetchPartnerInvestorByUserRelationships({ commit, dispatch, getters }) {
    const userId = getters.getUserId;
    const partnerId = getters.entityId;
    const url = `/partners/${partnerId}/user/${userId}/investor-relationships`;
    commit('setPartnerInvestorByUserRelationshipsIsLoading', true);
    try {
      const response = await axiosCore.get(url);
      commit('setPartnerInvestorByUserRelationships', response.data);
      if (getters.isActivePartnerClientEmpty && response.data.length) {
        commit('setActivePartnerClient', response.data[0]);
      }
    } catch (error) {
      commit('setPartnerInvestorByUserRelationships', []);
      errorAlert('Something went wrong trying to get investor relationships.', error);
    } finally {
      commit('setPartnerInvestorByUserRelationshipsIsLoading', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
