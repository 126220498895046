<template>
    <div class="dropdown-item" v-if="to === undefined"><slot></slot></div>
    <router-link class="dropdown-item" :to="to" v-else><slot></slot></router-link>
</template>

<script>
export default {
  name: 'DropdownItem',
  props: {
    to: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      drop: false,
    };
  },
  methods: {
    clicked() {
      this.drop = !this.drop;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .dropdown-item {
        color: $black-grey-color;
        transition: color .3s ease;
        font-size: $medium-font-size;
        &:hover, &:focus, &:active {
            background-color: $white-grey-color;
        }
    }

</style>
