import { axiosCore } from '@/plugins/axios';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

const state = {
  documents: [],
  extraDocuments: [],
};

const getters = {
  getDocuments: (state) => state.documents,
  getDocument: (state, id) => state.documents.find((o) => o.id === id),
  getExtraDocuments: (state) => state.extraDocuments,
};

const mutations = {
  setDocument: (state, data) => {
    state.documents.splice(state.documents.findIndex((item) => item.id === data.id), 1);
    state.documents.push(data.value);
  },
  removeDocument: (state, id) => {
    state.documents.splice(state.documents.findIndex((item) => item.id === id), 1);
  },
  setExtraDocuments: (state, data) => (state.extraDocuments = data),

};

const actions = {
  getDocument({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosCore.get(data.value).then(
        (response) => {
          successAlert('Document successfully fetched');
          resolve(response);
          commit('setDocument', { value: response.data, id: data.id });
        },
        (error) => {
          errorAlert('There was an error when fetching the document', error);
          reject(error);
        },
      );
    });
  },
  fetchExtraDocuments({ commit }, fundraising_id) {
    return new Promise((resolve, reject) => {
      const url = `/files/document/startup/fundraisings/${fundraising_id}/extra_documents/all/?is_visible=true`;
      axiosCore.get(url).then(
        (response) => {
          resolve(response);
          commit('setExtraDocuments', response.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getDocumentSilently({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosCore.get(data.url).then(
        (response) => {
          resolve(response);
          commit('setDocument', { value: response.data, id: data.id });
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  deleteDocument({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosCore.delete(data.url).then(
        (response) => {
          successAlert('The document was correctly deleted.');
          resolve(response);
          commit('removeDocument', data.id);
        },
        (error) => {
          errorAlert('Error! The document could not be deleted.', error);
          reject(error);
        },
      );
    });
  },
  uploadFile({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosCore.post(data.url, data.post_data, {headers:{'Content-Type': 'multipart/form-data'}}).then(
        (response) => {
          successAlert('Document succesfully uploaded');
          resolve(response);
          const id = data.id ? data.id : response.data.id;
          commit('setDocument', { value: response.data, id });
        },
        (error) => {
          errorAlert('There was an error when uploading the document', error);
          reject(error);
        },
      );
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
