import { axiosCore } from '@/plugins/axios';
import { snakeToPascal } from '@/utils/text';

const state = {
  generatedSADocument: null,
  isGeneratedSALoading: false,
};

const getters = {
  getGeneratedSADocument: (state) => state.generatedSADocument,
  getIsGeneratedSALoading: (state) => state.isGeneratedSALoading,
};

const actions = {
  async getKYCFundDocument({ commit, getters }, fileType) {
    const kycFundDocument = snakeToPascal(fileType);
    const kycFundId = getters.getKycFund.id;
    const url = `/files/document/partner/kyc_fund/${kycFundId}/${fileType}/`;
    commit(`setIs${kycFundDocument}Loading`, true);
    try {
      const response = await axiosCore.get(url);
      commit(`set${kycFundDocument}`, response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit(`setIs${kycFundDocument}Loading`, false);
    }
  },
  async postKYCFundDocument({ commit, getters, dispatch }, data) {
    const kycFundDocument = snakeToPascal(data.fileType);
    const kycFundId = getters.getKycFund.id;
    const url = `/files/document/partner/kyc_fund/${kycFundId}/${data.fileType}/`;
    commit(`setIs${kycFundDocument}Loading`, true);
    try {
      const response = await axiosCore.post(url, data);
      commit(`set${kycFundDocument}`, response.data);
      dispatch('successAlert', { message: 'Document successfully uploaded!' });
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong when uploading document.' });
      throw new Error(error.message);
    } finally {
      commit(`setIs${kycFundDocument}Loading`, false);
    }
  },
  async deleteKYCFundDocument({ commit, getters, dispatch }, fileType) {
    const kycFundDocument = snakeToPascal(fileType);
    const kycFundId = getters.getKycFund.id;
    const url = `/files/document/partner/kyc_fund/${kycFundId}/${fileType}/`;
    commit(`setIs${kycFundDocument}Loading`, true);
    try {
      const response = await axiosCore.delete(url);
      commit(`set${kycFundDocument}`, response.data);
      dispatch('successAlert', { message: 'Document successfully deleted!' });
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong when deleting document.' });
      throw new Error(error.message);
    } finally {
      commit(`setIs${kycFundDocument}Loading`, false);
    }
  },
  async generateKYCFundSADocument({ commit, getters, dispatch }) {
    const investorId = getters.portfolioInvestorId;
    const url = `/investors/${investorId}/kyc-fund/download-pdf`;
    commit('setGeneratedSAIsLoading', true);
    try {
      const response = await axiosCore.post(url);
      commit('setGeneratedSADocument', response.data);
      // dispatch('successAlert', { message: 'Document successfully generated!' });
      return;
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong when opening the document.' });
      throw new Error(error.message);
    } finally {
      commit('setGeneratedSAIsLoading', false);
      axiosCore.post(`investors/${investorId}/kyc-fund/auto-fill`);
      return;
    }
  },
};

const mutations = {
  setGeneratedSADocument: (state, data) => state.generatedSADocument = data,
  setGeneratedSAIsLoading: (state, data) => state.isGeneratedSALoading = data,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
