/**
 * JSDoc is a markup language used to annotate JavaScript source code files.
 * It is used to add information about the code, eg what it does, what parameters it takes, what it returns.
 * JSDoc is a lighter-weight alternative to TypeScript and does not force you to use types.
 * We are adopting JSDoc in our heavily-used stores to make it easier to use in multiple components.
 * This lets us know what each store method/property takes in and returns.
 *
 * For more info on how we use JSDoc see this: https://www.notion.so/oneragtime/a56830e220b6466dac5d9892610bcb7e
 *
 * The official documentation of JSDoc can be found here: https://jsdoc.app/
 */

/** @template T; @typedef {import("src/types/utils.js").PaginatedResults<T>} PaginatedResults<T> */
/** @typedef {import("src/types/secondary/secondary.js").BuyOffer} BuyOffer */
/** @typedef {import("src/types/secondary/secondary.js").SellOffer} SellOffer */
/** @typedef {import("src/types/secondary/secondary.js").UpdatedOffer} UpdatedOffer */
/** @typedef {import("src/types/secondary/secondary.js").FundraisingLookup} FundraisingLookup */

import { getState, getAction } from '@/utils/jsdoc';
import { axiosCore } from '@/plugins/axios';
import utils from '@/components/lib/mixins/utils';

const { isEmpty } = utils.methods;
const dangerAlert = getAction('dangerAlert');

/**
 * @typedef {{
 *  currentBuyOfferId: number;
 *  currentSellOfferId: number;
 *  buyOffer: Object.<number, BuyOffer>;
 *  sellOffer: Object.<number, SellOffer>;
 * }} State
 */

/** @type {State} */
const state = {
  currentBuyOfferId: 0,
  currentSellOfferId: 0,
  buyOffers: {
    0: {
      docusign_request: {},
      startup_valuation: {},
    },
  },
  sellOffers: {
    0: {
      docusign_request: {},
      startup_valuation: {},
    },
  },
};

const getters = {
  /** @param {State} state */
  getCurrentBuyOffer: (state) => state.buyOffer[state.currentBuyOfferId],
  /** @param {State} state */
  getCurrentSellOffer: (state) => state.sellOffer[state.currentSellOfferId],
};

const actions = {
  /**
   * Get information about a buy offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} buyOfferId - The ID of the fundraising.
   */
  async getBuyOffer({ commit }, buyOfferId) {
    if (isEmpty(state.buyOffer[buyOfferId])) {
      /** @type {{data: BuyOffer}} */
      const response = await axiosCore.get(`secondary/buy-offers/${buyOfferId}`);
      commit('setBuyOffer', { buyOfferId, buyOffer: response.data });
    }
    commit('setCurrentBuyOfferId', buyOfferId);
    return state.buyOffer[buyOfferId];
  },
  /**
   * Get marketplace entries of buy offers.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  search?: string; // The search query to filter buy offers.
   *  page?: number; // The page of buy offers to return.
   *  page_size?: number; // The number of buy offers to return per page.
   * }} params - The query parameters to adjust page of buy offers returned in Sell Marketplace.
   */
  async getMarketplaceBuyOffers({ commit }, {
    search = '', page = 1, page_size = 10,
  }) {
    const params = new URLSearchParams({ search, page, page_size }).toString();
    /** @type {{data: PaginatedResults<BuyOffer>}} */
    const response = await axiosCore.get(`secondary/buy-offers/?${params}&parent_offer=&status=listed`);
    return response.data;
  },
  /**
   * Get My Trades entries of buy offers.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  page?: number; // The page of buy offers to return.
   *  page_size?: number; // The number of buy offers to return per page.
   * }} params - The query parameters to adjust page of buy offers returned in My Trades.
   */
  async getMyTradesBuyOffers({ commit }, { page = 1, page_size = 10 }) {
    const viewerInvestorId = getState('jwt').accesstoken?.payload?.active_profile?.entity_id;
    const params = new URLSearchParams({ page, page_size }).toString();
    /** @type {{data: PaginatedResults<BuyOffer>}} */
    const response = await axiosCore.get(`secondary/buy-offers/?${params}&buyer=${viewerInvestorId}&status=listed&status=pending&status=buyer_signature_pending&status=seller_signature_pending&status=oneragtime_signature_pending`);
    return response.data;
  },
  /**
   * Get TransactionHistory entries of buy offers.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  page?: number; // The page of buy offers to return.
   *  page_size?: number; // The number of buy offers to return per page.
   * }} params - The query parameters to adjust page of buy offers returned in Transaction History.
   */
  async getTransactionHistoryBuyOffers({ commit }, { page = 1, page_size = 10 }) {
    const viewerInvestorId = getState('jwt').accesstoken?.payload?.active_profile?.entity_id;
    const params = new URLSearchParams({ page, page_size }).toString();
    /** @type {{data: PaginatedResults<BuyOffer>}} */
    const response = await axiosCore.get(`secondary/buy-offers/?${params}&buyer=${viewerInvestorId}&status=completed&status=withdrawn&status=declined`);
    return response.data;
  },
  /**
   * Lookup Startup by prefix.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  search?: string; // The search query to filter startups.
   *  owned?: boolean; // Limit the search to startups the user has shares in.
   *  id?: number; // The id of the startup.
   *  page?: number; // The page to return.
   *  page_size?: number; // The number of items to return per page.
   * }} params - The query parameters to adjust page.
   */
  async lookupStartup({ commit }, {
    search = '', owned = false, id = '', page = 1, page_size = 10,
  }) {
    const params = new URLSearchParams({
      search, owned, id, page, page_size,
    }).toString();
    /** @type {{data: PaginatedResults<StartupLookup>}} */
    const response = await axiosCore.get(`secondary/startup-lookup/?${params}`);
    return response.data;
  },
  /**
   * Get information about a sell offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} sellOfferId - The ID of the sell offer.
   */
  async getSellOffer({ commit }, sellOfferId) {
    if (isEmpty(state.sellOffer[sellOfferId])) {
      /** @type {{data: SellOffer}} */
      const response = await axiosCore.get(`secondary/sell-offers/${sellOfferId}`);
      commit('setSellOffer', { sellOfferId, sellOffer: response.data });
    }
    commit('setCurrentSellOfferId', sellOfferId);
    return state.sellOffer[sellOfferId];
  },
  /**
   * Get marketplace entries of sell offers.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  search?: string; // The search query to filter sell offers.
   *  page?: number; // The page of sell offers to return.
   *  page_size?: number; // The number of sell offers to return per page.
   * }} params - The query parameters to adjust page of sell offers returned in Buy Marketplace.
   */
  async getMarketplaceSellOffers({ commit }, {
    search = '', page = 1, page_size = 10,
  }) {
    const params = new URLSearchParams({ search, page, page_size }).toString();
    /** @type {{data: PaginatedResults<SellOffer>}} */
    const response = await axiosCore.get(`secondary/sell-offers/?${params}&parent_offer=&status=listed`);
    return response.data;
  },
  /**
   * Get My Trades entries of sell offers.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  page?: number; // The page of sell offers to return.
   *  page_size?: number; // The number of sell offers to return per page.
   * }} params - The query parameters to adjust page of sell offers returned in My Trades.
   */
  async getMyTradesSellOffers({ commit }, { page = 1, page_size = 10 }) {
    const viewerInvestorId = getState('jwt').accesstoken?.payload?.active_profile?.entity_id;
    const params = new URLSearchParams({ page, page_size }).toString();
    /** @type {{data: PaginatedResults<SellOffer>}} */
    const response = await axiosCore.get(`secondary/sell-offers/?${params}&seller=${viewerInvestorId}&status=listed&status=pending&status=buyer_signature_pending&status=seller_signature_pending&status=oneragtime_signature_pending`);
    return response.data;
  },
  /**
   * Get TransactionHistory entries of sell offers.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  page?: number; // The page of sell offers to return.
   *  page_size?: number; // The number of sell offers to return per page.
   * }} params - The query parameters to adjust page of sell offers returned in Transaction History.
   */
  async getTransactionHistorySellOffers({ commit }, { page = 1, page_size = 10 }) {
    const viewerInvestorId = getState('jwt').accesstoken?.payload?.active_profile?.entity_id;
    const params = new URLSearchParams({ page, page_size }).toString();
    /** @type {{data: PaginatedResults<SellOffer>}} */
    const response = await axiosCore.get(`secondary/sell-offers/?${params}&seller=${viewerInvestorId}&status=completed&status=withdrawn&status=declined`);
    return response.data;
  },
  /**
   * Create a new buy offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  startup: number; // The startup the investor wants to buy shares in.
   *  price: string; // The price to offer for the startup.
   *  shares: number; // The number of shares to buy.
   *  buyer: number; // The investor making the buy offer.
   * }} payload - Details of the buy offer to create.
   * @returns {Promise<BuyOffer>} - The created buy offer.
  */
  async createInitialBuyOffer({ commit }, payload) {
    try {
      /** @type {{data: BuyOffer}} */
      const response = await axiosCore.post('secondary/buy-offers/', { ...payload, status: 'listed' });
      return response.data;
    } catch (error) {
      return dangerAlert('The Buy Offer could not be created!');
    }
  },
  /**
   * Create a response buy offer to a parent sell offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  shares: number; // The number of shares to buy.
   *  buyer: number; // The investor making the buy offer.
   *  parentOffer: SellOffer; // The parent sell offer this buy offer is in response to.
   * }} payload - Details of the buy offer to create.
   * @returns {Promise<BuyOffer>} - The created buy offer.
  */
  async createResponseBuyOffer({ commit }, payload) {
    const buyOffer = {
      status: 'pending',
      buyer: payload.buyer,
      shares: payload.shares,
      parent_offer: payload.parentOffer.id,
      startup: payload.parentOffer.startup,
      price: payload.parentOffer.price,
    };
    try {
      /** @type {{data: BuyOffer}} */
      const response = await axiosCore.post('secondary/buy-offers/', buyOffer);
      return response.data;
    } catch (error) {
      return dangerAlert('The Buy Offer could not be created!');
    }
  },
  /**
   * Create a new sell offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  startup: number; // The startup associated with the shares being sold.
   *  fundraising: number; // The fundraising associated with the shares being sold.
   *  price: string; // The price to offer for a single share.
   *  shares: number; // The number of shares to sell.
   *  seller: number; // The investor making the sell offer.
   *  fees: string; // The fees associated with the sell offer.
   * }} payload - Details of the sell offer to create.
   * @returns {Promise<SellOffer>} - The created sell offer.
   */
  async createInitialSellOffer({ commit }, payload) {
    try {
      /** @type {{data: SellOffer}} */
      const response = await axiosCore.post('secondary/sell-offers/', { ...payload, status: 'listed' });
      return response.data;
    } catch (error) {
      return dangerAlert('The Sell Offer could not be created!');
    }
  },
  /**
   * Create a response sell offer to a parent buy offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  fundraising: number; // The fundraising associated with the shares being sold.
   *  shares: number; // The number of shares to sell.
   *  fees: string; // The fees associated with the sell offer.
   *  seller: number; // The investor making the sell offer.
   *  parentOffer: BuyOffer; // The parent buy offer this sell offer is in response to.
   * }} payload - Details of the sell offer to create.
   * @returns {Promise<SellOffer>} - The created sell offer.
  */
  async createResponseSellOffer({ commit }, payload) {
    const sellOffer = {
      status: 'pending',
      fees: payload.fees,
      seller: payload.seller,
      shares: payload.shares,
      fundraising: payload.fundraising,
      parent_offer: payload.parentOffer.id,
      startup: payload.parentOffer.startup,
      price: payload.parentOffer.price,
    };
    try {
      /** @type {{data: SellOffer}} */
      const response = await axiosCore.post('secondary/sell-offers/', sellOffer);
      return response.data;
    } catch (error) {
      return dangerAlert('The Sell Offer could not be created!');
    }
  },
  /**
   * Withdraw my buy offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {BuyOffer} buyOffer - The buy offer to withdraw.
   * @returns {Promise<BuyOffer>} - The withdrawn buy offer.
  */
  async withdrawBuyOffer({ commit }, buyOffer) {
    try {
      /** @type {{data: BuyOffer}} */
      const response = await axiosCore.patch(`secondary/buy-offers/${buyOffer.id}`, {
        status: 'withdrawn',
        completed_at: new Date().toISOString(),
      });
      return response.data;
    } catch (error) {
      return dangerAlert('The Buy Offer could not be withdrawn!');
    }
  },
  /**
   * Withdraw my sell offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {SellOffer} sellOffer - The sell offer to withdraw.
   * @returns {Promise<SellOffer>} - The withdrawn sell offer.
  */
  async withdrawSellOffer({ commit }, sellOffer) {
    try {
      /** @type {{data: SellOffer}} */
      const response = await axiosCore.patch(`secondary/sell-offers/${sellOffer.id}`, {
        status: 'withdrawn',
        completed_at: new Date().toISOString(),
      });
      return response.data;
    } catch (error) {
      return dangerAlert('The Sell Offer could not be withdrawn!');
    }
  },
  /**
   * Accept a response buy offer to my parent sell offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} buyOfferId - The ID of the buy offer to accept.
   * @returns {Promise<BuyOffer>} - The accepted buy offer.
  */
  async acceptBuyOffer({ commit }, buyOfferId) {
    try {
      /** @type {{data: BuyOffer}} */
      const response = await axiosCore.patch(`secondary/buy-offers/${buyOfferId}`, { status: 'seller_signature_pending' });
      return response.data;
    } catch (error) {
      return dangerAlert('The Buy Offer could not be accepted!');
    }
  },
  /**
   * Accept a response sell offer to my parent buy offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} sellOfferId - The ID of the sell offer to accept.
   * @returns {Promise<SellOffer>} - The accepted sell offer.
  */
  async acceptSellOffer({ commit }, sellOfferId) {
    try {
      /** @type {{data: SellOffer}} */
      const response = await axiosCore.patch(`secondary/sell-offers/${sellOfferId}`, { status: 'buyer_signature_pending' });
      return response.data;
    } catch (error) {
      return dangerAlert('The Sell Offer could not be accepted!');
    }
  },
  /**
   * Decline a response buy offer to my parent sell offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} buyOfferId - The ID of the buy offer to decline.
   * @returns {Promise<BuyOffer>} - The declined buy offer.
  */
  async declineBuyOffer({ commit }, buyOfferId) {
    try {
      /** @type {{data: BuyOffer}} */
      const response = await axiosCore.patch(`secondary/buy-offers/${buyOfferId}`, { status: 'declined' });
      return response.data;
    } catch (error) {
      return dangerAlert('The Buy Offer could not be declined!');
    }
  },
  /**
   * Decline a response sell offer to my parent buy offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} sellOfferId - The ID of the sell offer to decline.
   * @returns {Promise<SellOffer>} - The declined sell offer.
  */
  async declineSellOffer({ commit }, sellOfferId) {
    try {
      /** @type {{data: SellOffer}} */
      const response = await axiosCore.patch(`secondary/sell-offers/${sellOfferId}`, { status: 'declined' });
      return response.data;
    } catch (error) {
      return dangerAlert('The Sell Offer could not be declined!');
    }
  },
  /**
   * Update the status of a buy offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  buyOfferId: number; // The ID of the buy offer to decline.
   *  status: string; // The status to update the buy offer to.
   * }} payload
   * @returns {Promise<UpdatedOffer>}
  */
  async updateBuyOfferStatus({ commit }, { buyOfferId, status }) {
    try {
      /** @type {{data: BuyOffer}} */
      const response = await axiosCore.patch(`secondary/buy-offers/${buyOfferId}`, { status });
      return response.data;
    } catch (error) {
      return dangerAlert('The Buy Offer could not be updated!');
    }
  },
  /**
   * Update the status of a sell offer.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  sellOfferId: number; // The ID of the sell offer to decline.
   *  status: string; // The status to update the sell offer to.
   * }} payload
   * @returns {Promise<UpdatedOffer>}
  */
  async updateSellOfferStatus({ commit }, { sellOfferId, status }) {
    try {
      /** @type {{data: SellOffer}} */
      const response = await axiosCore.patch(`secondary/sell-offers/${sellOfferId}`, { status });
      return response.data;
    } catch (error) {
      return dangerAlert('The Sell Offer could not be updated!');
    }
  },
};

const mutations = {
  /**
   * The buy offer id to associate with the getters.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} buyOfferId - ID of the buyOffer.
  */
  setCurrentBuyOfferId: (state, buyOfferId) => {
    state.currentBuyOfferId = +buyOfferId;
  },
  /**
   * The sell offer id to associate with the getters.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} sellOfferId - ID of the sellOffer.
  */
  setCurrentSellOfferId: (state, sellOfferId) => {
    state.currentSellOfferId = +sellOfferId;
  },
};

/** @typedef {typeof getters} Getters */
/** @typedef {typeof actions} Actions */
/** @typedef {typeof mutations} Mutations */

/**
 * @module secondary
 * @typedef {Object} SecondaryStore
 * @property {State} state
 * @property {Getters} getters
 * @property {Actions} actions
 * @property {Mutations} mutations
 */

export default {
  state,
  getters,
  actions,
  mutations,
};
