<template>
  <label class="radio" :disabled="disabled">
    <div
      class="small-card"
      :class="{ checked: selected }"
      :disabled="disabled"
      @mousedown="emitSelectedEntity"
    >
      <AvatarInfo
        :title="title"
        :subtitle="subtitle"
        :avatarType="avatarType"
        :imageSrc="imageSrc"
        :initials="initials"
        :size="avatarSize"
        :color="avatarColor"
        :showAvatar="false"
      />
      <div v-if="radioIsShown" class="radio-contact-card">
        <input :disabled="disabled" :checked="selected" type="radio" />
      </div>
    </div>
  </label>
</template>

<script>
import AvatarInfo from '@/components/atoms/avatars/AvatarInfo.vue';

export default {
  name: 'SelectorRadio',
  components: { AvatarInfo },
  emits: ['emitSelectedEntity'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    radioIsShown: {
      type: Boolean,
      default: true,
    },
    initials: {
      type: String,
      default: 'AB',
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: false,
    },
    imageSrc: {
      type: String,
      required: false,
    },
    avatarType: {
      type: String,
      required: false,
    },
    avatarSize: {
      type: String,
      required: false,
    },
    avatarColor: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    emitSelectedEntity() {
      this.$emit('emitSelectedEntity', this.id, this.subtitle);
    },
  },
};
</script>

<style lang="scss" scoped>
.small-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: initial;
  width: 100%;
  max-width: 342px;
  min-height: 80px;
  padding: 16px;
  border-radius: 12px;
  background-color: $grey-700;
  transition: border-color 0.3s ease;
  &.checked {
    border: 2px solid $cobalt-300;
  }
  .card-icon {
    size: 12px;
    font-weight: 900;
    color: $grey-200;
  }
  .radio-contact-card {
    flex-direction: row-reverse;
    margin-left: auto;
  }
}
.radio {
  display: block;
  cursor: pointer;
  user-select: none;
  text-align: left;
  position: relative;
  margin: 2px;
  input {
    display: none;
    & + span {
      &:before {
        content: "";
        display: block;
        position: relative;
        top: -17px;
        left: 6px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 1px solid $grey-300;
        background: white;
      }
      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: $cobalt-200;
        position: relative;
        border-radius: 50%;
        top: -32px;
        left: 11px;
        opacity: 0;
        transform: scale(0, 0);
        border-color: $cobalt-400;
      }
    }
    &:checked + span:after {
      opacity: 1;
      transform: scale(1, 1);
    }
    &:checked + span:before {
      border: 2px solid $primary;
    }
    &:disabled + span:before {
      border: 2px solid $cobalt-400;
      color: $cobalt-400;
    }
    &:disabled + span:after {
      border: 2px solid $cobalt-400;
      background: $cobalt-400;
    }
  }
}
</style>
