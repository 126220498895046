import jwt_decode from 'jwt-decode';
import moment from 'moment';

const ten_seconds = 10;
const EXPIRE_MARGIN = ten_seconds;

class JwtToken {
  constructor(token) {
    this.token = token;
    this.payload = '';
  }

  decode() {
    const decoded_payload = jwt_decode(this.token);
    this.payload = decoded_payload;
    return decoded_payload;
  }

  is_expired() {
    this.decode();
    const { exp } = this.payload;
    const now_epoche = moment().unix();
    return exp <= now_epoche + EXPIRE_MARGIN;
  }

  active_entity() {
    this.decode();
    const active_entity = this.payload?.active_profile.entity_type;
    return active_entity;
  }
}

export default JwtToken;
