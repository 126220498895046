export function createList(data) {
  let value;
  if (data === '') {
    value = [];
  } else if (typeof data === 'string') {
    value = data.split(',');
  } else {
    value = data;
  }
  return value;
}

export function createListInt(data) {
  const listStr = createList(data);
  return listStr.map((el) => parseInt(el));
}
