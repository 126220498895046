import {
  getLocalStorageArrayInts,
  getLocalStorage,
  getLocalStorageInt,
  getLocalStorageArray,
  getLocalStorageArrayObjs,
  getLocalStorageBool,
} from '@/utils/local_storage';
import router from '@/router/index';

const state = {
  startupName: getLocalStorage('onboarding_entrepreneur_startup_name'),
  startupLocation: getLocalStorage(
    'onboarding_entrepreneur_startup_location',
  ),
  startupWebsite: getLocalStorage('onboarding_entrepreur_startup_website'),
  startupLinkedin: getLocalStorage('onboarding_entrepreur_startup_linkedin'),
  businessSectorOfInterest: getLocalStorageArrayInts(
    'onboarding_entrepreneur_business_sectors_of_interest',
  ),
  businessModels: getLocalStorageArray(
    'onboarding_entrepreneur_more_about_business_models',
    [],
  ),
  goToMarkets: getLocalStorageArray(
    'onboarding_entrepreneur_more_about_business_markets',
    [],
  ),
  startupTagline: getLocalStorage('onboarding_entrepreneur_startup_tagline'),
  startupFemale: getLocalStorage('onboarding_entrepreneur_startup_female'),
  startupImpact: getLocalStorage('onboarding_entrepreneur_startup_impact'),
  startupDescription: getLocalStorage(
    'onboarding_entrepreneur_startup_description',
  ),
  startupStage: getLocalStorage('onboarding_entrepreneur_startup_stage'),
  startupFundraisingSize: getLocalStorage(
    'onboarding_entrepreneur_startup_fundraising_size',
  ),
  startupCompetitors: getLocalStorage(
    'onboarding_entrepreneur_startup_competitors',
  ),
  startupRevenue: getLocalStorage(
    'onboarding_entrepreneur_startup_revenue',
  ),
  uploadId: getLocalStorage('onboarding_entrepreneur_upload_id'),
  isUpload: getLocalStorageBool('onboarding_entrepreneur_is_upload'),
  entrepreneurProgressBarActiveSteps: getLocalStorageInt('onboarding_entrepreneur_progressbar_active_steps', 0.01),
  entrepreneurSteps: [
    'PitchYourStartup',
    'SectorOfInterest',
    'MoreAboutYourBusinessForm',
    'DescribeYourBusinessForm',
    'DescribeYourBusinessStageForm',
    'UploadDeckForm',
    'ThankYou',
  ],
  activeStep: getLocalStorageInt('onboarding_entrepreneur_step', 0),
};

const getters = {
  startupName: (state) => state.startupName,
  startupLocation: (state) => state.startupLocation,
  startupWebsite: (state) => state.startupWebsite,
  startupLinkedin: (state) => state.startupLinkedin,
  businessSectorOfInterest: (state) => state.businessSectorOfInterest,
  businessModels: (state) => state.businessModels,
  goToMarkets: (state) => state.goToMarkets,
  startupTagline: (state) => state.startupTagline,
  startupFemale: (state) => state.startupFemale,
  startupImpact: (state) => state.startupImpact,
  startupDescription: (state) => state.startupDescription,
  startupStage: (state) => state.startupStage,
  startupFundraisingSize: (state) => state.startupFundraisingSize,
  startupCompetitors: (state) => state.startupCompetitors,
  startupRevenue: (state) => state.startupRevenue,
  uploadId: (state) => state.uploadId,
  isUpload: (state) => state.isUpload,
  onboardingEntrepreneurActiveStep: (state) => state.entrepreneurSteps[state.activeStep],
  entrepreneurProgressBarActiveSteps: (state) => state.entrepreneurProgressBarActiveSteps,
};

const actions = {
  saveStartupName: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_startup_name', data);
    commit('setStartupName', data);
  },
  saveStartupLocation: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_startup_location', data);
    commit('setStartupLocation', data);
  },
  saveStartupWebsite: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreur_startup_website', data);
    commit('setStartupWebsite', data);
  },
  saveStartupLinkedin: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreur_startup_linkedin', data);
    commit('setStartupLinkedin', data);
  },
  saveBusinessSectorsOfInterest: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_business_sectors_of_interest', data);
    commit('setBusinessSectorOfInterest', data);
  },
  saveBusinessModels: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_more_about_business_models', data);
    commit('setBusinessModels', data);
  },
  saveGoToMarkets: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_more_about_business_markets', data);
    commit('setGoToMarkets', data);
  },
  saveStartupTagline: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_startup_tagline', data);
    commit('setStartupTagline', data);
  },
  saveStartupFemale: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_startup_female', data);
    commit('setStartupFemale', data);
  },
  saveStartupImpact: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_startup_impact', data);
    commit('setStartupImpact', data);
  },
  saveStartupDescription: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_startup_description', data);
    commit('setStartupDescription', data);
  },
  saveStartupStage: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_startup_stage', data);
    commit('setStartupStage', data);
  },
  saveStartupFundraisingSize: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_startup_fundraising_size', data);
    commit('setStartupFundraisingSize', data);
  },
  saveStartupCompetitors: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_startup_competitors', data);
    commit('setStartupCompetitors', data);
  },
  saveStartupRevenue: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_startup_revenue', data);
    commit('setStartupRevenue', data);
  },
  saveUploadId: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_upload_id', data);
    commit('setUploadId', data);
  },
  saveIsUpload: ({ commit }, data) => {
    localStorage.setItem('onboarding_entrepreneur_is_upload', data);
    commit('setIsUpload', data);
  },
  onboardingEntrepreneurNextStep: ({ commit, state }) => {
    commit('setOnboardingEntrepreneurStep', state.activeStep + 1);
    commit('setEntrepreneurProgressBarActiveStep', state.entrepreneurProgressBarActiveSteps + 0.5);
    localStorage.setItem('onboarding_entrepreneur_step', state.activeStep);
    localStorage.setItem('onboarding_entrepreneur_progressbar_active_steps', state.entrepreneurProgressBarActiveSteps);
  },
  onboardingEntrepreneurPreviousStep: ({ commit, state, getters }) => {
    if (state.activeStep < 1) {
      router.push({ name: 'Onboarding' });
    } else {
      commit('setOnboardingEntrepreneurStep', state.activeStep - 1);
      commit('setEntrepreneurProgressBarActiveStep', state.entrepreneurProgressBarActiveSteps - 0.5);
      localStorage.setItem('onboarding_entrepreneur_step', state.activeStep);
      localStorage.setItem('onboarding_entrepreneur_progressbar_active_steps', state.entrepreneurProgressBarActiveSteps);
    }
  },
};

const mutations = {
  setStartupName: (state, startupName) => (state.startupName = startupName),
  setStartupLocation: (state, startupLocation) => (state.startupLocation = startupLocation),
  setStartupWebsite: (state, startupWebsite) => (state.startupWebsite = startupWebsite),
  setStartupLinkedin: (state, startupLinkedin) => (state.startupLinkedin = startupLinkedin),
  setBusinessSectorOfInterest: (state, option) => (state.businessSectorOfInterest = option),
  setBusinessModels: (state, option) => (state.businessModels = option),
  setGoToMarkets: (state, option) => (state.goToMarkets = option),
  setStartupTagline: (state, startupTagline) => (state.startupTagline = startupTagline),
  setStartupFemale: (state, startupFemale) => (state.startupFemale = startupFemale),
  setStartupImpact: (state, startupImpact) => (state.startupImpact = startupImpact),
  setStartupDescription: (state, startupDescription) => (state.startupDescription = startupDescription),
  setStartupStage: (state, startupStage) => (state.startupStage = startupStage),
  setStartupFundraisingSize: (state, startupFundraisingSize) => (state.startupFundraisingSize = startupFundraisingSize),
  setStartupCompetitors: (state, startupCompetitors) => (state.startupCompetitors = startupCompetitors),
  setStartupRevenue: (state, startupRevenue) => (state.startupRevenue = startupRevenue),
  setUploadId: (state, uploadId) => (state.uploadId = uploadId),
  setIsUpload: (state, isUpload) => (state.isUpload = isUpload),
  setOnboardingEntrepreneurStep: (state, value) => (state.activeStep = value),
  setEntrepreneurProgressBarActiveStep: (state, value) => (state.entrepreneurProgressBarActiveSteps = value),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
