export const truncate = (str, n) => ((str && str.length > n) ? `${str.substr(0, n - 1)}...` : str);

export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const capitalizeAll = (s) => s?.split(' ').map((e) => capitalize(e)).join(' ');

export const snakeToWord = (str) => {
  if (str) {
    const word = str.toString().replace(/_/g, ' ');
    return capitalize(word);
  }

  return '';
};

export const snakeToPascal = (string) => {
  let pascalString = '';
  if (string) {
    string.split('_').forEach((word) => {
      pascalString += `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
    });
    return pascalString;
  }
  return string;
};
