import { axiosCore } from '@/plugins/axios';

// TODO dep whole file

const state = {
  investorBio: '',
  investorJob: '',
  investorLocation: '',
  investorPlatformSubscriptionInvitation: {},
  isInvestorPlatformSubscriptionInvitationLoading: false,
  isInvestorPlatformSubscriptionInvitationSentSuccessfully: false,
};

const getters = {
  investorBio: (state) => state.investorBio,
  investorJob: (state) => state.investorJob,
  investorLocation: (state) => state.investorLocation,
};

const actions = {
};

const mutations = {
  setInvestorBio: (state, data) => (state.investorBio = data),
  setInvestorJob: (state, data) => (state.investorJob = data),
  setInvestorLocation: (state, data) => (state.investorLocation = data),
  setInvestorPlatformSubscriptionInvitation: (localState, newData) => {
    localState.investorPlatformSubscriptionInvitation = newData;
  },
  setIsInvestorPlatformSubscriptionInvitationLoading: (localState, newData) => {
    localState.isInvestorPlatformSubscriptionInvitationLoading = newData;
  },
  setIsInvestorPlatformSubscriptionInvitationSentSuccessfully: (localState, newData) => {
    localState.isInvestorPlatformSubscriptionInvitationSentSuccessfully = newData;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
