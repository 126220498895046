const focused = {
  beforeMount(el, binding, vnode) {
    el.event = function (event) {
      // here I check that click was outside the el and his childrens
      if (el == event.target || el.contains(event.target)) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('focus', el.event, true);
  },
  unmounted(el) {
    document.body.removeEventListener('focus', el.event, true);
  },
};

export default focused;
