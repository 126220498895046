<template>
    <div>
        <div
            v-if="avatarType == 'initials'"
            :class="`initials avatar ${avatarSize} ${avatarColor}`"
        >
            <p v-if="initials">{{ initials.toUpperCase().substring(0, 3) }}</p>
        </div>
        <div
            v-if="avatarType === 'icon'"
            :class="`avatar ${avatarSize} ${avatarColor}`"
        >
            <i v-if="iconName" :class="`fas fa-${iconName}`"></i>
        </div>
        <div v-if="avatarType == 'image'" :class="`avatar ${avatarSize}`">
            <img v-if="imageSrc" :src="imageSrc" />
        </div>
    </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    imageSrc: {
      type: String,
      required: false,
    },
    iconName: {
      type: String,
      default: 'users',
    },
    initials: {
      type: String,
      default: 'ab',
    },
    avatarSize: {
      type: String,
      default: 'medium',
      validator(value) {
        return (
          [
            'micro',
            'extra-small',
            'small',
            'medium',
            'large',
            'extra-large',
            'huge',
          ].indexOf(value) !== -1
        );
      },
    },
    avatarType: {
      type: String,
      default: 'icon',
      validator(value) {
        return ['image', 'icon', 'initials'].indexOf(value) !== -1;
      },
    },
    avatarColor: {
      type: String,
      default: 'blue',
      validator(value) {
        return (
          ['pink', 'yellow', 'grey', 'red', 'green', 'blue'].indexOf(
            value,
          ) !== -1
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin container-size($avatarSize) {
    width: $avatarSize;
    height: $avatarSize;
}
.avatar {
    border-radius: 50%;
    border: 1px solid $grey-600;
    display: flex;
    justify-content: center;
    align-items: center;

    & i {
      fill: red;

    }
    &.micro {
        @include container-size(24px);
        i,
        p {
            font-size: 12px;
        }
    }
    &.extra-small {
        @include container-size(32px);
        i,
        p {
            font-size: 18px;
        }
    }
    &.small {
        @include container-size(48px);
        i,
        p {
            font-size: 18px;
        }
    }
    &.medium {
        @include container-size(56px);
        i,
        p {
            font-size: 22px;
        }
    }
    &.large {
        @include container-size(64px);
        i,
        p {
            font-size: 22px;
        }
    }
    &.extra-large {
        @include container-size(86px);
        i,
        p {
            font-size: 38px;
        }
    }
    &.huge {
        @include container-size(144px);
        i,
        p {
            font-size: 38px;
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
    }
    p {
        margin: auto;
    }
}
.header {
    display: flex;
    align-items: center;
    .header-info {
        @include make-flex(space-between, flex-start, column);
        margin-left: 8px;
        .name {
            @extend .text-sm--bold;
            margin-bottom: 4px;
        }
        .stage {
            @extend .text-sm--bold;
            color: rgba(60, 60, 67, 0.6);
        }
    }
    &.vertical {
        flex-direction: column;
        .header-info {
            align-items: center !important;
            margin-left: 0;
            margin-top: 4px;
        }
    }
}
.initials {
    border: 1px solid $grey-600;
}
.green {
    color: $green-200;
    background-color: $green-500;
}
.yellow {
    color: $yellow-200;
    background-color: $yellow-500;
}
.pink {
    color: $cobalt-200;
    background-color: $cobalt-600;
}
.blue {
    color: $cobalt-300;
    background-color: $cobalt-600;
}
.red {
    color: $red-200;
    background-color: $red-500;
}
.grey {
    color: $grey-200;
    background-color: $grey-500;
}
</style>
