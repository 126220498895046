import {
  axiosCoreNoToken,
} from '@/plugins/axios';

const url = '/auth/users';

const actions = {
  forgottenPassword({ dispatch, commit }, data) {
    return new Promise((resolve, reject) => {
      axiosCoreNoToken.post(`${url}/forgot-password`, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};

export default {
  actions,
};
