<template>
  <div class="popup-wrapper">
    <div class="settings" @click="toSettings" v-if="!isActiveStartup">
      <ORTIcon icon="settings-01"/>
      <p>Settings</p>
    </div>
    <div class="logout" @click="logout">
      <ORTIcon icon="log-out-01"/>
      <p>Log out</p>
    </div>
  </div>
</template>
<script>
import ORTIcon from '@/ort-lib/components/ORTIcon.vue';

export default {
  name: 'ProfileControls',
  emits: ['logout'],
  components: {
    ORTIcon,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    isActiveStartup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    logout() {
      this.$emit('logout');
    },
    toSettings() {
      this.$router.push('/profile');
      this.$emit('closeSidebar');
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-wrapper {
  position: absolute;
  bottom: 10%;
  left: 17%;
  width: 240px;
  //height: 100px;
  border-radius: 6px;
  border: 1px solid #EAECF0;
  background-color: #fff;
  z-index: 10000;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  .settings, .logout {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 50%;
    border-bottom: 1px solid $grey-600;
    cursor: pointer;
    p {
      margin: 0 0 0 8px;
    }
  }
}
</style>
