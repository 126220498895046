const state = {
  KYCTypeChoices: [],
  KYCFundMaritalStatusChoices: [],
  QualifiedInvestorChoices: [],
  InvestorCategorizationChoices: [],
  InvestorCategorizationTypeOfEntityChoices: [],
  InvestorCategorizationCriteriaFinancialYearChoices: [],
  InvestorCategorizationSemiProfessionalChoices: [],
  NonProfessionalTestInvestmentObjectiveChoices: [],
  ProfessionalTestInvestmentObjectiveChoices: [],
  RiskProfileTestChoices: [],
  AMLGeneralPointsChoices: [],
  AMLMoneySourceIndividualChoices: [],
  FatcaStatusChoices: [],
  TaxEntityTypeChoices: [],
  TaxEntityTypeChoicesUS: [],
  TaxLLCChoicesUS: [],
  FatcaStatusOfDisregardedChoices: [],
  TaxTreatyBenefitsDetailsChoices: [],
  TaxTreatyBenefitsLimitationsChoices: [],
  FinancialInstrumentPortfolioValueTestChoices: [],
  InvestmentTransactionsTestChoices: [],
  ExemptRetirementPlansChoices: [],
  PassiveNFFECertificatesChoices: [],
  SponsoredFFIChoices: [],
  InternationalOrganizationChoices: [],
  OwnerDocumentsChoices: [],
  RestrictedDistributorChoices: [],
  PoliticallyExposedPositionChoices: [],
  AMLMoneySourceLegalChoices: [],
  AMLInvestorTypeChoices: [],
  StatusReportingEntityChoices: [],
  StatusReportingEntityOtherChoices: [],
  NonReportingIGAFromChoices: [],
};

const getters = {
  getYesNoChoices: () => [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ],
  getKYCTypeChoices: (state) => state.KYCTypeChoices,
  getKYCFundMaritalStatusChoices: (state) => state.KYCFundMaritalStatusChoices,
  getQualifiedInvestorChoices: (state) => state.QualifiedInvestorChoices,
  getInvestorCategorizationChoices: (state, getters) => state.InvestorCategorizationChoices.filter((choice) => {
    if (choice.value === 'professional_by_nature' && getters.getKycFund.investor_type === 'natural') return;
    return choice;
  }),
  getInvestorCategorizationTypeOfEntityChoices: (state) => state.InvestorCategorizationTypeOfEntityChoices,
  getInvestorCategorizationCriteriaFinancialYearChoices: (state) => state.InvestorCategorizationCriteriaFinancialYearChoices,
  getInvestorCategorizationSemiProfessionalChoices: (state) => state.InvestorCategorizationSemiProfessionalChoices,
  getNonProfessionalTestInvestmentObjectiveChoices: (state) => state.NonProfessionalTestInvestmentObjectiveChoices,
  getProfessionalTestInvestmentObjectiveChoices: (state) => state.ProfessionalTestInvestmentObjectiveChoices,
  getRiskProfileTestChoices: (state) => state.RiskProfileTestChoices.map((choice) => {
    if (choice.value !== 'risky') choice.dangerText = 'This risk profile is not adapted to an investment in the Fund.';
    return choice;
  }),
  getAMLGeneralPointsChoices: (state) => state.AMLGeneralPointsChoices,
  getAMLMoneySourceIndividualChoices: (state) => state.AMLMoneySourceIndividualChoices,
  getFatcaStatusChoices: (state) => state.FatcaStatusChoices,
  getTaxEntityTypeChoices: (state) => state.TaxEntityTypeChoices,
  getTaxEntityTypeChoicesUS: (state) => state.TaxEntityTypeChoicesUS,
  getTaxLLCChoicesUS: (state) => state.TaxLLCChoicesUS,
  getFatcaStatusOfDisregardedChoices: (state) => state.FatcaStatusOfDisregardedChoices,
  getTaxTreatyBenefitsDetailsChoices: (state) => state.TaxTreatyBenefitsDetailsChoices,
  getTaxTreatyBenefitsLimitationsChoices: (state) => state.TaxTreatyBenefitsLimitationsChoices,
  getFinancialInstrumentPortfolioValueTestChoices: (state) => state.FinancialInstrumentPortfolioValueTestChoices,
  getInvestmentTransactionsTestChoices: (state) => state.InvestmentTransactionsTestChoices,
  getExemptRetirementPlansChoices: (state) => state.ExemptRetirementPlansChoices,
  getPassiveNFFECertificatesChoices: (state) => state.PassiveNFFECertificatesChoices,
  getSponsoredFFIChoices: (state) => state.SponsoredFFIChoices,
  getInternationalOrganizationChoices: (state) => state.InternationalOrganizationChoices,
  getOwnerDocumentsChoices: (state) => state.OwnerDocumentedChoices,
  getRestrictedDistributorChoices: (state) => state.RestrictedDistributorChoices,
  getPoliticallyExposedPositionChoices: (state) => state.PoliticallyExposedPositionChoices,
  getAMLMoneySourceLegalChoices: (state) => state.AMLMoneySourceLegalChoices,
  getAMLInvestorTypeChoices: (state) => state.AMLInvestorTypeChoices,
  getStatusReportingEntityChoices: (state) => state.StatusReportingEntityChoices,
  getStatusReportingEntityOtherChoices: (state) => state.StatusReportingEntityOtherChoices,
  getNonReportingIGAFromChoices: (state) => state.NonReportingIGAFromChoices,
};

const mutations = {
  setKYCTypeChoices: (state, data) => { state.KYCTypeChoices = data; },
  setKYCFundMaritalStatusChoices: (state, data) => { state.KYCFundMaritalStatusChoices = data; },
  setQualifiedInvestorChoices: (state, data) => { state.QualifiedInvestorChoices = data; },
  setInvestorCategorizationChoices: (state, data) => { state.InvestorCategorizationChoices = data; },
  setInvestorCategorizationTypeOfEntityChoices: (state, data) => { state.InvestorCategorizationTypeOfEntityChoices = data; },
  setInvestorCategorizationCriteriaFinancialYearChoices: (state, data) => { state.InvestorCategorizationCriteriaFinancialYearChoices = data; },
  setInvestorCategorizationSemiProfessionalChoices: (state, data) => { state.InvestorCategorizationSemiProfessionalChoices = data; },
  setNonProfessionalTestInvestmentObjectiveChoices: (state, data) => { state.NonProfessionalTestInvestmentObjectiveChoices = data; },
  setProfessionalTestInvestmentObjectiveChoices: (state, data) => { state.ProfessionalTestInvestmentObjectiveChoices = data; },
  setRiskProfileTestChoices: (state, data) => { state.RiskProfileTestChoices = data; },
  setAMLGeneralPointsChoices: (state, data) => { state.AMLGeneralPointsChoices = data; },
  setAMLMoneySourceIndividualChoices: (state, data) => { state.AMLMoneySourceIndividualChoices = data; },
  setFatcaStatusChoices: (state, data) => { state.FatcaStatusChoices = data; },
  setTaxEntityTypeChoices: (state, data) => { state.TaxEntityTypeChoices = data; },
  setTaxEntityTypeChoicesUS: (state, data) => { state.TaxEntityTypeChoicesUS = data; },
  setTaxLLCChoicesUS: (state, data) => { state.TaxLLCChoicesUS = data; },
  setFatcaStatusOfDisregardedChoices: (state, data) => { state.FatcaStatusOfDisregardedChoices = data; },
  setTaxTreatyBenefitsDetailsChoices: (state, data) => { state.TaxTreatyBenefitsDetailsChoices = data; },
  setTaxTreatyBenefitsLimitationsChoices: (state, data) => { state.TaxTreatyBenefitsLimitationsChoices = data; },
  setFinancialInstrumentPortfolioValueTestChoices: (state, data) => { state.FinancialInstrumentPortfolioValueTestChoices = data; },
  setInvestmentTransactionsTestChoices: (state, data) => { state.InvestmentTransactionsTestChoices = data; },
  setExemptRetirementPlansChoices: (state, data) => { state.ExemptRetirementPlansChoices = data; },
  setPassiveNFFECertificatesChoices: (state, data) => { state.PassiveNFFECertificatesChoices = data; },
  setSponsoredFFIChoices: (state, data) => { state.SponsoredFFIChoices = data; },
  setInternationalOrganizationChoices: (state, data) => { state.InternationalOrganizationChoices = data; },
  setOwnerDocumentsChoices: (state, data) => { state.OwnerDocumentedChoices = data; },
  setRestrictedDistributorChoices: (state, data) => { state.RestrictedDistributorChoices = data; },
  setPoliticallyExposedPositionChoices: (state, data) => { state.PoliticallyExposedPositionChoices = data; },
  setAMLMoneySourceLegalChoices: (state, data) => { state.AMLMoneySourceLegalChoices = data; },
  setAMLInvestorTypeChoices: (state, data) => { state.AMLInvestorTypeChoices = data; },
  setStatusReportingEntityChoices: (state, data) => { state.StatusReportingEntityChoices = data; },
  setStatusReportingEntityOtherChoices: (state, data) => { state.StatusReportingEntityOtherChoices = data; },
  setNonReportingIGAFromChoices: (state, data) => { state.NonReportingIGAFromChoices = data; },
};

export default {
  state,
  getters,
  mutations,
};
