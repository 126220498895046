<template>
  <hr v-if="user_profiles.length > 0" class="w-100"/>
  <div v-if="user_profiles.length > 0" class="wrapper">
    <div class="wrapper__header">
      <p v-if="user_profiles.length > 1" class="title text-md--bold">Choose entity</p>
      <p v-else class="title text-md--bold">Your entity</p>
      <!-- <div v-if="isActivePartner">
        <i @click="openInvestorModal" class="fas fa-user-plus wrapper__header--button"
          aria-hidden="true"></i>
      </div> -->
    </div>
    <div class="entity-container">
      <loading class="entity-loader" :active="loadEntities" />
      <div>
        <div v-if="!hasEntities" class="frame-empty">
          <p>No entities available.</p>
        </div>
        <div class="frame-with-entitites">
          <div v-if="!loadEntities" class="m-0">
            <div v-for="(profile, index) in user_profiles" :key="index">
              <EntityWithPhotoSelector
                :relationshipId="profile.id"
                :ownerId="profile.owner"
                :entityName="profile.entity_name"
                :entityType="profile.entity_type"
                :selected="profile.selected"
                @entityData="setActivateEntity"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import Button from '@/components/atoms/buttons/Button.vue';
import EntityWithPhotoSelector from '@/components/molecules/containers/EntityWithPhotoSelector.vue';

export default {
  components: {
    EntityWithPhotoSelector,
    Button,
  },
  data() {
    return {
      user_profiles: [],
      user_profile_active: [],
      loadEntities: false,
      investorRelationships: [],
    };
  },
  computed: {
    ...mapGetters([
      'activeEntityType',
      'activeRelationshipId',
    ]),
    hasEntities() {
      return this.user_profiles.length > 0;
    },
    ...mapState({
      returnActiveEntity:
        (state) => state.activate_entity.activeEntity,
    }),
    returnData() {
      return this.returnActiveEntity;
    },
  },
  watch: {
    returnData: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.user_profile_active = newValue;
          this.user_profiles = this.user_profiles.map((item) => {
            const profile = { ...item };
            if (item.entity_name === newValue.entity_name) {
              profile.selected = true;
            } else {
              profile.selected = false;
            }
            return profile;
          });
        }
      },
    },
  },
  methods: {
    ...mapMutations(['setActiveEntity']),
    ...mapActions([
      'getInvestorRelationshipsCore',
      'getCorporateRelationshipsCore',
      'getPartnerRelationshipsCore',
      'getStartupRelationshipsCore',
      'hardRefreshTokens',
      'activateEntity',
    ]),
    async getActiveProfile() {
      await this.getInvestorRelationshipsCore().then((response) => {
        this.investorRelationships = [
          ...this.investorRelationships,
          ...response.data.map((item) => (
            { relationshipId: item.entity_id, entityName: item.entity_name }
          )),
        ];
        this.user_profile_active = this.user_profiles.filter((item) => (
          item.id === this.activeRelationshipId));
      });
    },
    setInitialEntity() {
      const user_entity = this.activeEntityType;
      this.switchEntity(this.activeRelationshipId, user_entity);
    },
    async getProfiles() {
      await this.getPartnerRelationshipsCore().then((response) => {
        this.user_profiles = [...this.user_profiles, ...response.data];
      });
      await this.getInvestorRelationshipsCore().then((response) => {
        this.user_profiles = [...this.user_profiles, ...response.data];
      });
      await this.getCorporateRelationshipsCore().then((response) => {
        this.user_profiles = [...this.user_profiles, ...response.data];
      });
      await this.getStartupRelationshipsCore().then((response) => {
        this.user_profiles = [...this.user_profiles, ...response.data];
      });
    },
    switchEntity(relationshipId, entityType) {
      const profiles = this.user_profiles;
      this.user_profiles = profiles.map(
        (option) => (
          option.id === relationshipId && option.entity_type === entityType
            ? { ...option, selected: true }
            : { ...option, selected: false }
        ),
      );
    },
    setActivateEntity(relationshipId, entityType) {
      this.switchEntity(relationshipId, entityType);
      const selectedEntity = this.user_profiles.filter(
        (profile) => profile.selected === true,
      )[0];
      if (!selectedEntity) return;
      this.setActiveEntity(selectedEntity);
      const body = {
        entity_type: selectedEntity.entity_type,
        relationship_id: selectedEntity.id,
        owner_id: selectedEntity.owner,
      };
      this.activateEntity(body).then(
        () => this.hardRefreshTokens().then(
          () => this.setInitialEntity(),
        ),
      ).then(() => {
        this.$emit('close-sidebar');
      });
    },
  },
  mounted() {
    this.loadEntities = true;
    this.getProfiles()
      .then(() => {
        this.setInitialEntity();
        this.loadEntities = false;
        this.getActiveProfile();
      })
      .catch((e) => {
        this.loadEntities = false;
        throw e;
      });
    this.getInvestorRelationshipsCore().then((response) => {
      this.investorRelationships = response.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: $secondary-label;
  margin: 0 0 8px ;
}
.wrapper {
  height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &--button{
      color: $primary;
      &:hover{
        cursor: pointer;
        color: $primary;
      }
    }
  }
}

.entity-container {
  width: 100%;
  overflow: scroll !important;
  height:100%;
  &::-webkit-scrollbar {
    display: none;
  }
}
.button-container {
  width: 100%;
  z-index: 3;
  padding-top: 12px;
}
.switch-entity-btn {
  @extend .text-sm--bold;
  width: 100%;
  color: $white-color;
}
@media (max-height: 670px) {
  .switch-entity-btn {
    bottom: 50px;
  }
}
.entity-loader {
  position: relative;
  left: 40%;
  top: 40%;
}
:deep(.name){
  max-width: 142px;
  max-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
