<template>
  <div
    v-if="!isAuthenticated"
    id="login"
    class="app-unauthorized"
  >
    <alert
      v-if="$store.state.alert.show"
      :message="$store.state.alert.message"
      :type="$store.state.alert.type">
    </alert>
    <RouterView v-slot="{ Component }">
      <Component :is="Component"></Component>
    </RouterView>
  </div>

  <div class="app" v-else>
    <alert
      v-if="$store.state.alert.show"
      :message="$store.state.alert.message"
      :type="$store.state.alert.type">
    </alert>

    <NavbarResponsive
      v-if="!routeInBlancPages"
      class="d-lg-none d-flex d-md-none"
    />

    <RouterView  v-slot="{ Component }" class="content mt-md-5">
      <Transition mode="out-in" name="fade">
        <div>
          <Component :is="Component"></Component>
        </div>
      </Transition>
    </RouterView>

    <!-- <FooterResponsive
      v-if="!routeInBlancPages"
      class="d-flex d-md-none platform-footer"
    /> -->
    <FooterSection
      v-if="!routeInBlancPages"
      class="d-none d-md-flex platform-footer"
    />

    <!-- Modals -->
    <NewInvestorModal
      v-if="isNewInvestorOpen"
      @close-investor-modal="closeNewInvestorModal"
    />

    <!-- Sidebar -->
    <SideBar v-if="!routeInBlancPages" @logout="logout" />

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FooterResponsive from '@/components/sections/FooterResponsive.vue';
import NavbarResponsive from './components/sections/NavbarResponsive.vue';
import NewInvestorModal from '@/components/molecules/modals/NewInvestorModal.vue';
import FooterSection from '@/components/sections/FooterSection.vue';
import SideBar from './components/sections/SideBar.vue';

export default {
  name: 'App',
  components: {
    FooterResponsive,
    NavbarResponsive,
    NewInvestorModal,
    FooterSection,
    SideBar,
  },
  data() {
    return {
      blancPages: [
        'OnboardingEntrepreneur',
        'Onboarding',
        'KYCFund',
        'KYCFundCompleted',
      ],
      isNewInvestorOpen: false,
    };
  },
  methods: {
    ...mapActions(['userInfoAnalytics']),
    logout() {
      this.$store.dispatch('logout');
    },
    openNewInvestorModal() {
      this.isNewInvestorOpen = true;
    },
    closeNewInvestorModal() {
      this.isNewInvestorOpen = false;
    },
  },
  computed: {
    ...mapGetters([
      'isActiveInvestor',
      'getUserId',
      'isAuthenticated',
    ]),
    routeInBlancPages() {
      return this.$route.matched.some(({ name }) => this.blancPages.includes(name));
    },
  },
  created() {
    this.userInfoAnalytics();
  },
};
</script>

<style lang="scss">
.app {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  background: white;

  &-unauthorized {
    @media (min-width: 1025px) {
      background: $bg-gradient--unauthorized;
    }
  }
}

body.modal-open {
  padding-right: 0 !important;
}

a {
  color: $primary;
  text-decoration: none;
  &:hover {
    color: $cobalt-300;
  }
}

a:hover {
  text-decoration: none;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0 !important;
  outline: 0 !important;
  div[role="radiogroup"],
  div[role="group"] {
    border: 0 !important;
    outline: 0 !important;
  }
}

.pagination {
  .page-link {
    color: $cobalt-200;
    text-decoration: none;
  }
  .page-item.active .page-link {
    background-color: $grey-color;
    border-color: $grey-color;
  }
}

.row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.container.padding-container {
  @media (min-width: 576px) {
    max-width: 100vw !important;
  }
  @media (min-width: 768px) {
    max-width: 100vw !important;
  }
  @media (min-width: 992px) {
    max-width: 100vw !important;
  }
  @media (min-width: 1200px) {
    max-width: 100vw !important;
  }
  @media (min-width: 1600px) {
    max-width: 100vw !important;
  }
}

.root-container {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.first-container {
  margin-top: 0px;
}

.last-container {
  margin-bottom: 0px;
}

.icon-link {
  cursor: pointer;
  text-align: center;
  color: $black-grey-color;
  -webkit-transition: color 0.3s; /* Safari */
  transition: color 0.3s;
  &:hover,
  &:focus,
  &:active,
  &-focused {
    color: $black-color;
  }
  &-heart:hover,
  &-heart:focus,
  &-heart:active,
  &-heart-focused {
    color: $red-color;
  }
  &-follow:hover,
  &-follow:focus,
  &-follow:active,
  &-follow-focused {
    color: $cobalt-200;
  }
  &-facebook:hover,
  &-facebook:focus,
  &-facebook:active,
  &-facebook-focused {
    color: $facebook-color;
  }
  &-twitter:hover,
  &-twitter:focus,
  &-twitter:active,
  &-twitter-focused {
    color: $twitter-color;
  }
  &-linkedin:hover,
  &-linkedin:focus,
  &-linkedin:active,
  &-twitter-focused {
    color: $linkedin-color;
  }
  &-instagram:hover,
  &-instagram:focus,
  &-instagram:active,
  &-instagram-focused {
    color: $instagram-color;
  }
  &-website:hover,
  &-website:focus,
  &-website:active,
  &-website-focused {
    color: $website-color;
  }
  &-danger:hover,
  &-danger:focus,
  &-danger:active,
  &-danger-focused {
    color: $red-color;
  }
  &-success:hover,
  &-success:focus,
  &-success:active,
  &-success-focused {
    color: $green-color;
  }
}

.icon {
  text-align: center;
  color: $black-grey-color;
  -webkit-transition: color 0.3s; /* Safari */
  transition: color 0.3s;
  &:hover,
  &:focus,
  &:active,
  &-focused {
    color: $black-color;
  }
  &-heart,
  &-heart:hover,
  &-heart:focus,
  &-heart:active,
  &-heart-focused {
    color: $red-color;
  }
  &-follow,
  &-follow:hover,
  &-follow:focus,
  &-follow:active,
  &-follow-focused {
    color: $cobalt-200;
  }
  &-facebook,
  &-facebook:hover,
  &-facebook:focus,
  &-facebook:active,
  &-facebook-focused {
    color: $facebook-color;
  }
  &-twitter,
  &-twitter:hover,
  &-twitter:focus,
  &-twitter:active,
  &-twitter-focused {
    color: $twitter-color;
  }
  &-linkedin,
  &-linkedin:hover,
  &-linkedin:focus,
  &-linkedin:active,
  &-twitter-focused {
    color: $linkedin-color;
  }
  &-instagram,
  &-instagram:hover,
  &-instagram:focus,
  &-instagram:active,
  &-instagram-focused {
    color: $instagram-color;
  }
  &-website,
  &-website:hover,
  &-website:focus,
  &-website:active,
  &-website-focused {
    color: $website-color;
  }
  &-danger,
  &-danger:hover,
  &-danger:focus,
  &-danger:active,
  &-danger-focused {
    color: $red-color;
  }
  &-success,
  &-success:hover,
  &-success:focus,
  &-success:active,
  &-success-focused {
    color: $green-color;
  }
}

.page-root {
  text-align: center;
  padding: 70px;
  margin-left: 235px;
  overflow-x: hidden;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 20px;
    margin-left: 0px;
  }
}

.break {
  color: $white-color;
  margin-top: 10px;
  margin-bottom: 10px;
}

.icon-more {
  width: max-content;
  margin: 0 auto;
  font: $main-font-xbold;
  color: #5971F2;
  text-align: center;
  font-size: 14px;
  margin-top: 15px;
  transition: color 0.3s ease;
  cursor: pointer;
  &:hover {
    color: $purple-darker-color;
  }
}

// For Navbar
.dropdown-menu {
  a.dropdown-item {
    color: $black-grey-color;
    background: none;
    &:hover,
    &:focus,
    &:active {
      color: $black-grey-color;
      outline: none;
    }
  }
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  a.dropdown-item {
    text-align: left;
  }
}

.progress-bar {
  background-color: $red-color;
}

.root-page-card {
  min-height: calc(100vh - 450px);
  padding-top: 30px;
  padding-bottom: 30px;
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 135px);
  }
}

.invisible {
  opacity: 0;
}

.status-label-center {
  margin: 0 auto;
}

.row.row-break-margin-bottom {
  margin-bottom: 0px;
}
.row.row-break-margin-top {
  margin-top: 0px;
}

.loading-container-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-like {
  font-weight: 400 !important;
  cursor: pointer;
  font: $main-font-regular;
  align-items: flex-start;
  vertical-align: top;
  transition: color 0.3s ease;
  border: none;
  color: $blue-color;
  &:hover,
  &:focus,
  &:active {
    color: $blue-darker-color;
  }
}

//modal
.modal.fade.show {
  padding-right: 0 !important;
}
.modal-content {
  min-height: 27vh;
  max-height: 90vh;
  overflow-x: auto;
  overflow-y: auto;
  .modal-header {
    padding: 20px;
    border-bottom: none !important;
    h5 {
      color: $black-color;
    }
  }
  .modal-body {
    padding: 0;
  }
}
.modal-text {
  font: $main-font-semibold;
  font-size: $slarge-font-size;
  color: $black-color;
}

// transition classes are from https://v2.vuejs.org/v2/guide/transitions.html#Transitioning-Single-Elements-Components
.fade-enter-active, .fade-leave-active {
  transition: opacity .45s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

</style>
