/* eslint-disable no-shadow */

import { errorAlert } from '@/ort-lib/utils/utils';
import { axiosCore } from '@/plugins/axios';
import { get, set } from 'lodash';

function getParamsStringFromObject(paramsObj) {
  if (Object.keys(paramsObj).length === 0) {
    return '';
  }
  const params = [];
  for (const key in paramsObj) {
    if (Object.hasOwn(paramsObj, key)) {
      params.push(`${key}=${paramsObj[key]}`);
    }
  }
  const string = params.join('&');
  return string;
}

const state = {
  fundraisingGrowthStages: [],
  fundraisingSectors: [],
  fundraisingBusinessModels: [],
  currentFundDeals: {
    results: [],
    next: null,
    count: 0,
  },
  closedClubdealDeals: {
    results: [],
    next: null,
    count: 0,
  },
  currentClubdealDeal: {},
  currentFundDeal: {},
  currentFundDealsLoading: false,
  currentClubdealDealsLoading: false,
  opportunitiesFilters: {},
  currentOpenClubdealDeals: {},
};

const getters = {
  getFundraisingGrowthStages: (state) => {
    const fundraisingGrowthStagesData = state.fundraisingGrowthStages.map(
      (fundraisingGrowthStages) => {
        const obj = {
          id: fundraisingGrowthStages.pk,
          name: fundraisingGrowthStages.name,
        };
        return obj;
      },
    );
    return fundraisingGrowthStagesData;
  },
  getFundraisingSectors: (state) => {
    const fundraisingSectorsData = state.fundraisingSectors.map(
      (fundraisingSectors) => {
        const obj = {
          id: fundraisingSectors.id,
          name: fundraisingSectors.name,
          type: fundraisingSectors.type,
        };
        return obj;
      },
    );
    return fundraisingSectorsData;
  },
  getFundraisingBusinessModels: (state) => {
    const fundraisingBusinessModelsData = state.fundraisingBusinessModels.map(
      (fundraisingBusinessModels) => {
        const obj = {
          id: fundraisingBusinessModels.id,
          name: fundraisingBusinessModels.name,
        };
        return obj;
      },
    );
    return fundraisingBusinessModelsData;
  },
  getCurrentFundDeals: (state) => (state.currentFundDeals.results),
  getFundDealsNextPage: (state) => state.currentFundDeals.next,
  getCurrentFundDealsLoading: (state) => (state.currentFundDealsLoading),
  getCurrentClubdealDeals: (state) => (state.currentClubdealDeals.results),
  getClosedClubdealDeals: (state) => (state.closedClubdealDeals.results),
  getClubdealDealsNextPage: (state) => state.currentClubdealDeals.next,
  getClosedClubdealDealsNextPage: (state) => state.closedClubdealDeals.next,
  getCurrentClubdealDealsLoading: (state) => (state.currentClubdealDealsLoading),
  getCurrenOpenClubDeal: (state) => (getCurrenClubDealId) => state.currentOpenClubdealDeals.filter(
    (currentClubDeal) => currentClubDeal?.id === getCurrenClubDealId,
  )[0],
  getClosedClubDeal: (state) => (getCurrenClubDealId) => state.closedClubdealDeals.results?.filter(
    (currentClubDeal) => currentClubDeal?.id === getCurrenClubDealId,
  )[0],
  getCurrentFundDeal: (state) => (fundDealId) => state.currentFundDeals.results.filter(
    (currentFundDeal) => currentFundDeal?.id === fundDealId,
  )[0],
  getCurrentQueryClubDeal: (state) => (state.currentClubdealDeal),
  getCurrentQueryFundDeal: (state) => (state.currentFundDeal),
  getOpportunitiesFilters: (state) => (state.opportunitiesFilters),
  getCurrentOpenClubDeals: (state) => (state.currentOpenClubdealDeals),
};

const actions = {
  async fetchFundraisingGrowthStages({ commit, dispatch, getters }) {
    try {
      let response = getters.getFundraisingGrowthStages;
      if (response === null || response.length === 0) {
        response = await axiosCore.get('/growths-list/');
        commit('setFundraisingGrowthStages', response.data);
      }
    } catch (error) {
      commit('setError', error?.message || 'Something went wrong');
      errorAlert('Something went wrong', error);
    }
  },
  async fetchFundraisingSectors({ commit, dispatch, getters }) {
    try {
      let response = getters.getFundraisingSectors;
      if (response === null || response.length === 0) {
        response = await axiosCore.get('/sectors-list/');
        commit('setFundraisingSectors', response.data);
      }
    } catch (error) {
      commit('setError', error?.message || 'Something went wrong');
      errorAlert('Something went wrong', error);
    }
  },
  async fetchFundraisingBusinessModels({ commit, dispatch, getters }) {
    try {
      let response = getters.getFundraisingBusinessModels;
      if (response === null || response.length === 0) {
        response = await axiosCore.get('/businessmodels-list/');
        commit('setFundraisingBusinessModels', response.data);
      }
    } catch (error) {
      commit('setError', error?.message || 'Something went wrong');
      errorAlert('Something went wrong', error);
    }
  },
  fetchCurrentFundDeals({ dispatch, commit }, params = {}) {
    commit('setCurrentFundDealsLoading', true);
    const fundParams = {
      ...params,
      is_fund: 'True',
      page: params.page || 1,
      page_size: 3,
    };
    dispatch('fetchDeals', fundParams).then((response) => {
      commit('setCurrentFundDeals', response.data);
    }).finally(() => {
      commit('setCurrentFundDealsLoading', false);
    });
  },
  fetchCurrentClubdealDeals({ dispatch, commit }, params = {}) {
    commit('setCurrentClubdealDealsLoading', true);
    const clubdealParams = {
      ...params,
      is_fund: 'False',
    };
    dispatch('fetchDeals', clubdealParams).then((response) => {
      commit('setCurrentClubdealDeals', response.data);
    }).finally(() => {
      commit('setCurrentClubdealDealsLoading', false);
    });
  },
  fetchCurrentOpenClubDeals({ dispatch, commit }, params = {}){
    commit('setCurrentClubdealDealsLoading', true);
    const clubdealParams = {
      ...params,
      is_fund: 'False',
      status: 'open',
    };
    dispatch('fetchDeals', clubdealParams).then((response) => {
      commit('setCurrentOpenClubdealDeals', response.data.results);
    }).finally(() => {
      commit('setCurrentClubdealDealsLoading', false);
    });
  },
  fetchAggregateClosedClubDeals({ dispatch, commit }) {
    commit('setCurrentClubdealDealsLoading', true);
    const clubdealParams = {
      is_fund: 'False',
      status: 'successfully_closed',
    };
    state.closedClubdealDeals.next += 1;
    dispatch('fetchDeals', clubdealParams).then((response) => {
      const data = response.data.results;
      commit('setClosedClubdealDealsAgreggate', data);
    }).finally(() => {
      commit('setCurrentClubdealDealsLoading', false);
    });
  },
  fetchDeals({ commit, dispatch }, extraParams = {}) {
    return new Promise((resolve, reject) => {
      const filters = getParamsStringFromObject(extraParams);
      axiosCore.get(`fundraisings/list-filtered?${filters}`).then((response) => {
        resolve(response);
      }, (error) => {
        commit('setError', 'Something went wrong.');
        errorAlert('Something went wrong', error);
        reject(error);
      });
    });
  },
  async fetchCurrentDeal({ commit, dispatch }, clubDealID) {
    try {
      commit('setCurrentClubdealDealsLoading', true);
      const response = await axiosCore.get(`fundraisings/${clubDealID}/basic-info`);
      commit('setCurrentClubdealDeal', response.data);
    } catch (error) {
      errorAlert('Something went wrong looking for this deal', error);
      throw new Error('The data fetching failed in the fetchPortfolioCompany action');
    } finally {
      commit('setCurrentClubdealDealsLoading', false);
    }
  },
};

const mutations = {
  setFundraisingGrowthStages: (state, data) => {
    state.fundraisingGrowthStages = data;
  },
  setFundraisingSectors: (state, data) => {
    state.fundraisingSectors = data;
  },
  setFundraisingBusinessModels: (state, data) => {
    state.fundraisingBusinessModels = data;
  },
  setCurrentFundDealsLoading: (state, data) => {
    state.currentFundDealsLoading = data;
  },
  setCurrentClubdealDealsLoading: (state, data) => {
    state.currentClubdealDealsLoading = data;
  },
  setCurrentFundDeals: (state, data) => {
    state.currentFundDeals = data;
  },
  setCurrentClubdealDeals: (state, data) => {
    state.currentClubdealDeals = data;
  },
  setCurrentOpenClubdealDeals: (state, data) => {
    state.currentOpenClubdealDeals = data;
  },
  setCurrentClubdealDeal: (state, data) => {
    state.currentClubdealDeal = data;
  },
  setClosedClubdealDealsAgreggate: (state, data) => {
    state.closedClubdealDeals.results = data;
  },
  setClosedClubdealDealsNext: (state, data) => {
    state.closedClubdealDeals.next = data;
  },
  setOpportunitiesFilters: (state, data) => {
    state.opportunitiesFilters = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
