import { axiosCore } from '@/plugins/axios';

const state = {
  kycFundSteps: [],
  isKycFundStepsLoading: false,
};

const getters = {
  getKycFundSteps: (state) => state.kycFundSteps,
  getIsKycFundStepsLoading: (state) => state.isKycFundStepsLoading,
  getActiveStep: (state) => state.kycFundSteps.find((v) => v.status === 'active'),
  getNextStep: (state, getters) => state.kycFundSteps[state.kycFundSteps.indexOf(getters.getActiveStep) + 1],
  getPreviousStep: (state, getters) => state.kycFundSteps[state.kycFundSteps.indexOf(getters.getActiveStep) - 1],
  getNextValidToFillStep: (state) => {
    const firstToFillStep = state.kycFundSteps.find((v) => v.status === 'tofill');
    const prevToFillStep = state.kycFundSteps[state.kycFundSteps.indexOf(firstToFillStep) - 1];
    if (['active', 'completed'].includes(prevToFillStep?.status)) {
      return firstToFillStep;
    }
    return false;
  },
};

const mutations = {
  setKycFundSteps: (state, data) => { state.kycFundSteps = data; },
  setIsKycFundStepsLoading: (state, data) => { state.isKycFundStepsLoading = data; },
};

const actions = {
  async fetchKycFundSteps({ commit, dispatch, getters }) {
    commit('setIsKycFundStepsLoading', true);
    const investorId = getters.portfolioInvestorId;
    const url = `investors/${investorId}/kyc-fund/active-steps`;
    try {
      const response = await axiosCore.get(url);
      commit('setKycFundSteps', response.data);
    } catch {
      commit('setKycFundSteps', []);
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get kyc fund steps.',
      });
    } finally {
      commit('setIsKycFundStepsLoading', false);
    }
  },

  async patchKycFundSteps({ commit, dispatch, getters }, data) {
    commit('setIsKycFundStepsLoading', true);
    const investorId = getters.portfolioInvestorId;
    const url = `investors/${investorId}/kyc-fund/active-step`;
    try {
      const response = await axiosCore.put(url, data);
      const sortedSteps = response.data.sort((a, b) => a.id - b.id);
      commit('setKycFundSteps', sortedSteps);
    } catch {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to save your kyc information.',
      });
    } finally {
      commit('setIsKycFundStepsLoading', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
