import { createList } from './data_transform';

export function setLocalStorageArray(name, value) {
  const string_value = JSON.stringify(value).slice(1, -1);
  localStorage.setItem(name, string_value);
}

export function getLocalStorage(name, default_ = '') {
  if (name) {
    const value = localStorage.getItem(name);
    return value || default_;
  }
}

export function getLocalStorageInt(name, default_ = '') {
  const storageInt = getLocalStorage(name, default_);
  return parseFloat(storageInt);
}

export function getLocalStorageBool(name, default_ = '') {
  const val = getLocalStorage(name, default_);
  const trueArray = ['true', 'True'];
  return trueArray.includes(val);
}

export function getLocalStorageArray(name, default_ = '') {
  const data = getLocalStorage(name, default_);
  return createList(data);
}

export function getLocalStorageArrayInts(name, default_ = '') {
  const list = getLocalStorageArray(name, default_);
  return list.map((el) => parseInt(el));
}

export function getLocalStorageArrayObjs(name, default_ = []) {
  const list = localStorage.getItem(name);
  if (list) {
    return JSON.parse(list);
  } else {
    return default_;
  }
}

export function eraseLocalStorage(name) {
  localStorage.removeItem(name);
}

export function eraseMultipleLocalStorage(names = []) {
  if (names.length > 0) {
    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      eraseLocalStorage(name);
    }
  }
}
