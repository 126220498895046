<template>
    <div id="navbar-responsive" class="navbar-expand" variant="faded">
        <router-link :to="{ name: 'Home' }">
            <img
                id="brand"
                :fluid="true"
                src="@/assets/images/OneRagtime-Logo-2017-WEB-v2.svg"
                width="100"
            />
        </router-link>
        <div @click="openSidebar">
            <HamburguerMenu/>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';
import BetaIndicator from '@/components/atoms/other/BetaIndicator.vue';
import HamburguerMenu from '@/components/atoms/buttons/HamburguerMenu.vue';

export default {
  name: 'NavbarResponsive',
  components: {
    BetaIndicator,
    HamburguerMenu,
  },
  computed: {
    ...mapGetters(['getSidebarOpen']),
  },
  methods: {
    ...mapMutations(['setSidebarOpen']),
    openSidebar() {
      this.setSidebarOpen(true);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#navbar-responsive {
    position: fixed;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 8px;
    z-index: 4;
    border-bottom: 1px solid $white-grey-color;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    .dropdown-menu-item {
        span {
            font: $main-font-regular;
            i {
                min-width: 23px;
                margin-right: 0;
            }
        }
    }
}

#brand {
    padding-left: 10px;
}
</style>
