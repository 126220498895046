import { axiosCore } from '@/plugins/axios';

const state = {
  investorRelationships: [],
  investorRelationshipsIsLoading: false,
};
const getters = {
  getInvestorRelationshipsResponse: (state) => state.investorRelationships,
  getInvestorRelationshipsIsLoading: (state) => state.investorRelationshipsIsLoading,
};
const mutations = {
  setInvestorRelationships: (state, data) => {
    state.investorRelationships = data;
  },
  setInvestorRelationshipsIsLoading: (state, data) => {
    state.investorRelationshipsIsLoading = data;
  },
};

const actions = {
  getInvestorRelationshipsCore(context) {
    const coreId = context.getters.getUserId;
    const url = `/investors/user/${coreId}/investor-relationships`;
    return new Promise((resolve, reject) => {
      axiosCore.get(url).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
