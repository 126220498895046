<template>
  <b-container>
    <b-row>
      <b-navbar class="footer-responsive navbar-expand" variant="faded">
        <b-col class="button-box align-items-center text-center">
          <b-nav-item
            class="footer-link club-deal"
            :class="{ focused: sectionFocused === 'ClubdealSection'}"
            :to="{ name: 'ClubdealSection' }"
            @click="focusSection('ClubdealSection')"
          >
            <span class="icon"><i class="fas fa-rocket"></i></span>
            <p class="text">Club deals</p>
          </b-nav-item>
        </b-col>
        <b-col class="button-box align-items-center text-center">
          <b-nav-item
            class="footer-link funds"
            :class="{ focused: sectionFocused === 'FundSection'}"
            :to="{ name: 'FundSection' }"
            @click="focusSection('FundSection')"
          >
            <span class="icon"><i class="fas fa-coins"></i></span>
            <p class="text">Funds</p>
          </b-nav-item>
        </b-col>
        <b-col
          class=" button-box align-items-center text-center"
          v-if="isActiveInvestor || isActiveCorporate || isActivePartner"
        >
          <b-nav-item
            class="footer-link portfolio"
            :class="{ focused: sectionFocused === 'Portfolio' }"
            :to="{ name: 'Portfolio' }"
            @click="focusSection('Portfolio')"
          >
            <span class="icon"><i class="fas fa-folder-open"></i></span>
            <p class="text">My portfolio</p>
          </b-nav-item>
        </b-col>
        <b-col
          class="button-box align-items-center text-center"
          v-if="isActivePartner"
        >
          <b-nav-item
            class="footer-link home"
            :class="{ focused: sectionFocused === 'ManageClients' }"
            :to="{ name: 'ManageClients' }"
            @click="focusSection('ManageClients')"
          >
            <span class="icon"><i class="fas fa-user"></i></span>
            <p class="text">Clients</p>
          </b-nav-item>
        </b-col>
      </b-navbar>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FooterResponsive',
  computed: {
    ...mapGetters([
      'isActiveInvestor',
      'isActiveCorporate',
      'isActiveStartup',
      'isActivePartner',
    ]),
    sectionFocused() {
      return this.$store.state.section_focused;
    },
    isHomeFocused() {
      return (
        this.sectionFocused === 'Home'
        || this.sectionFocused === 'HomeInvestor'
        || this.sectionFocused === 'HomeStartup'
        || this.sectionFocused === 'HomeCorporate'
        || this.sectionFocused === 'HomePartner'
      );
    },
  },
  data() {
    return {};
  },
  methods: {
    focusSection(section) {
      this.$store.dispatch('focusSection', section);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>

// Not all navbar's styling is here. Go to App.Vue end of style section to see dropdown menu styling
@mixin link-transform($color) {
    &:hover,
    &:focus,
    &.focused {
        border-top: 3px solid $color;
        .icon,
        .text {
            color: $color !important;
        }
    }
}
.footer-responsive {
    position: fixed;
    background-color: white;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 0;
    z-index: 1000;
    border-top: 1px solid $white-grey-color;
}
.footer-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: 3px solid transparent;
    .icon {
        font-size: 20px;
    }
    .text {
        text-wrap: nowrap;
        font: $main-font-semibold;
        color: #626262;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
    }
    &.home {
        @include link-transform($cobalt-200);
    }
    &.club-deal {
        @include link-transform($red-color);
    }
    &.deal-flow {
        @include link-transform($red-color);
    }
    &.portfolio {
        @include link-transform($green-color );
    }
    &.raise {
        @include link-transform($red-color);
    }
    &.funds {
        @include link-transform($yellow-color);
    }
}
.nav-link {
    margin: auto;
    padding: 10px 0 0 0;
}
.button-box {
  padding: 0;
}
</style>
