<template>
  <div class="form-group">
    <span v-if="title" class="title">{{ title }}</span>
    <div @mousedown="onTouch" class="input-container">
      <div class="icon-wrapper">
        <i
        v-if="iconIsShown"
        :class="`${iconClass()} ${iconBackground()} form-control-icon`"
        ></i>
      </div>
      <v-select
        v-model="value"
        :options="options"
        :class="[!iconIsShown ? 'no-icon' : 'has-icon']"
        :placeholder="placeholder"
        :name="name"
        @input="handleChange"
        @search="searchChanged"
        :filterable="filterable"
        :clearable="clearable"
        :searchable="searchable"
        :disabled="disabled"
        :style="cssProps"
      >
        <template #no-options>
          <span>{{ noOptionsMsg }}</span>
        </template>
        <template #selected-option="ele">
          <div>
            <img
              v-if="ele.imageSrc"
              class="option__image"
              :src="ele.imageSrc"
              alt="image"
            />
            <span v-if="ele.label || ele.name" class="option__label">
              {{ this.truncateOption(ele.label) || this.truncateOption(ele.name) }}
            </span>
            <span v-else class="option__label">
              {{ this.truncateOption(option) }}
            </span>
          </div>
        </template>
        <template #option="option">
          <div class="option">
            <img
              v-if="!!option.imageSrc"
              class="option__image"
              :src="option.imageSrc"
              alt="image"
            />
            <span v-if="!!option.label" class="option__label">
              {{ option.label }}
            </span>
            <span v-else class="option__label">
              {{ option }}
            </span>
          </div>
        </template>
      </v-select>
      <span v-if="errorMessage" class="text-danger">
        {{ errorMessage }}
      </span>
      <span v-else-if="info" class="info text-sm--semibold">{{ info }}</span>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  compatConfig: { MODE: 3 },
  name: 'FormDropdown',
  components: {
    vSelect,
  },
  emits: ['update:modelValue', 'searchChanged'],
  props: {
    iconIsShown: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'user',
    },
    iconPack: {
      type: String,
      default: 'fa',
      validator(value) {
        return ['fa', 'flag'].indexOf(value) !== -1;
      },
    },
    info: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'dropdown',
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: [String, Number, Object],
      default: '',
    },
    iconHasBackground: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
    minWidth: {
      type: String,
      default: '180px',
    },
    maxSymbols: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      value: this.modelValue,
      isTouched: false,
      searchTerm: '',
    };
  },
  methods: {
    handleChange(value) {
      this.$emit('update:modelValue', value, this.name);
    },
    searchChanged(search, loading) {
      this.$emit('searchChanged', search, loading);
      this.searchTerm = search;
    },
    iconClass() {
      const iconPrefix = {
        fa: 'fa fa-',
        flag: 'flag-icon flag-icon-',
      };
      return iconPrefix[this.iconPack] + this.icon;
    },
    iconBackground() {
      if (this.iconHasBackground) {
        return 'icon-background';
      }
      return '';
    },
    onTouch() {
      this.isTouched = true;
    },
    truncateOption(option) {
      if (this.maxSymbols && option?.length > this.maxSymbols) {
        return `${option?.substring(0, this.maxSymbols)}...`;
      }
      return option;
    },
  },
  computed: {
    cssProps() {
      return {
        '--min-width': this.minWidth,
      };
    },
    errorMessage() {
      return this.required && !this.value && this.isTouched ? 'This field is required' : '';
    },
    noOptionsMsg() {
      if (!this.searchTerm) return 'Start typing to search...';
      if (['Country', 'City'].includes(this.placeholder)) if (this.searchTerm.length < 3) return 'Keep typing to search...';
      return 'No matching options';
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (this.value !== newVal) {
          this.value = newVal;
        }
      },
    },
    icon() {
      this.iconClass();
    },
    options() {
      this.value = this.modelValue;
    },
  },
};
</script>

<style lang="scss">
.v-select {
  min-width: var(--min-width);
  border-radius: 12px;
  border: 1px solid $grey-500;
  cursor: pointer;
  &:hover {
    border: 1px solid $grey-200;
  }
  &:active,
  &:focus {
    border: 1px solid $cobalt-200;
  }
  &.invalid {
    border: 1px solid $danger;
  }
  &.no-icon {
    .vs__selected-options {
      margin-left: 0.3rem;
    }
  }
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.form-group {
  gap: 4px;
}

.option--selected {
  display: flex;
  align-items: center;
}
.option {
  &__image {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;
  }
  &__label {
    @extend .text-sm--medium;
    line-height: 2.1rem;
  }
}
.invalid {
  .vs__open-indicator {
    display: none;
  }
}
.vs__dropdown-option--highlight {
  background: $cobalt-600;
}
.vs__dropdown-option {
  color: $cobalt-200;
}
.vs__dropdown-toggle {
  width: 100%;
  border: none;
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vs__selected-options {
  margin-left: 1.6rem;
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
}
.vs__search:focus {
  @extend .text-sm--bold;
  color: $grey-400 !important;
}
.vs__search {
  @extend .text-sm--bold;
  display: flex;
  align-items: center;
  justify-self: start;
  color: $grey-200;
  cursor: pointer;
}
.vs--open, .vs--searching {
  .vs__search {
    color: $grey-200;
    line-height: 2.1rem;
  }
}
.vs--open {
  .vs__selected {
    position: relative !important;
  }
}
.vs__dropdown-menu {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  min-width: var(--min-width) !important;
  transition: border-color 0.25s ease;
}
.vs__dropdown-toggle {
  min-width: var(--min-width) !important;
  min-height: 41px;
  border: none;
}
.text-danger {
  font-size: 0.875rem;
  font-family: $fontfamily;
}
.info {
  font-family: $fontfamily;
  color: $secondary-label;
}
.title {
  @extend .text-sm--bold;
  display: block;
  margin-right: 10px;
  padding-bottom: 3px;
  margin-bottom: 3px;
}
.input-container {
  position: relative;
  width: 100%;
  @media (max-width: 320px) {
    width: 70%;
  }
}
.form-control-icon {
  position: absolute;
  display: block;
  top: 50%;
  transform: translate(12px, -50%);
  color: $grey-200;
  font-size: 14px;
  z-index: 3;
  width: 10px;
  height: 10px;
}
.icon-background {
  flex-wrap: wrap;
  background: $grey-700;
  border-radius: 60%;
  width: 18px;
  display: flex;
  height: 18px;
  align-content: space-around;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.error-icon {
  font-size: 0.875rem;
  color: $danger;
}
.flag-icon {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin: 8px;
  padding: 0;
}
.vs__actions {
  margin-right: 10px;
}
.vs__clear {
  margin-right: 15px;
  margin-bottom: 4px;
  z-index: 2;
}
.vs__dropdown-toggle {
  border-radius: 12px;
}
.icon-wrapper{
  height: 41px;
  position: absolute;
}
</style>
