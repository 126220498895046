import { axiosCore } from '@/plugins/axios';

const state = {
  clubDealDistributed: false,
  fundsDistributed: [],
};

const getters = {
  getClubDealDistributed: (state) => state.clubDealDistributed,
  getFundsDistributed: (state) => state.fundsDistributed,
};

const mutations = {
  setClubDealDistributed: (state, data) => { state.clubDealDistributed = data; },
  setFundsDistributed: (state, data) => { state.fundsDistributed = data; },
};

const actions = {
  async fetchPartnerContractInfo({ commit, dispatch, getters }) {
    if (!getters.isActivePartner) return;
    const partnerId = getters.entityId;
    const url = `partners/${partnerId}/contract-info`;
    try {
      const response = await axiosCore.get(url);
      commit('setClubDealDistributed', response.data.club_deal_distributed);
      commit('setFundsDistributed', response.data.funds_distributed);
    } catch {
      commit('setClubDealDistributed', false);
      commit('setFundsDistributed', []);
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get partner contract info.',
      });
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
