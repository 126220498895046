/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import moment from 'moment';
import { axiosCore } from '@/plugins/axios';
import { numberWithSpaces } from '@/utils/number';
import { errorAlert } from '@/ort-lib/utils/utils';
import { countYearDiffFromNow, investmentYearsDiff } from '@/utils/date.js';
import { validFixedAmountPercent } from '@/utils/number.js';

const state = {
  portfolioCompanies: [],
  portfolioCompany: {},
  portfolioFundInvestment: {},
  portfolioFund: {},
  isPortfolioFundLoading: false,
  isPortfolioFundInvestmentLoading: false,
  isPortfolioCompaniesLoading: false,
  isPortfolioCompanyLoading: false,
  investmentFilterDate: {
    date_before: null,
    date_after: null,
  },
  investmentStartupDocuments: [],
  isInvestmentStartupDocumentsLoading: false,
  investmentBills: [],
  isInvestmentBillsLoading: false,
  investmentDocuments: {},
  isInvestmentDocumentsLoading: false,
  fundInvestments: [],
  isFundInvestmentsLoading: false,
  fundPortfolioCompanies: [],
  isFundPortfolioCompaniesLoading: false,
};

const getters = {
  getPortfolioCompanies: (state) => state.portfolioCompanies,
  getPortfolioCompany: (state) => state.portfolioCompany,
  getPortfolioFundInvestment: (state) => state.portfolioFundInvestment,
  getPortfolioFund: (state) => state.portfolioFund,
  getPortfolioFundOverviews: (_, getters, rootState) => {
    let nextFeeDate = null;
    const creationDate = getters.getPortfolioFund?.creation_datetime;
    const yearDifference = investmentYearsDiff(creationDate);
    const currency = rootState.portfolioManagement.currentCurrency === 'EUR' ? '€' : '$';

    if (creationDate && yearDifference) {
      const creationDateYearDiffSum = moment(creationDate).add(yearDifference, 'Y');
      nextFeeDate = creationDateYearDiffSum.format('MMMM Do, YYYY');
    } else {
      nextFeeDate = '-';
    }

    let investmentStatus = '';
    const status = getters.getPortfolioFund.investment_status;
    if (getters.getPortfolioFund?.investment_status) {
      if (getters.getPortfolioFund?.creation_datetime) {
        const dateObject = new Date(getters.getPortfolioFund?.creation_datetime);
        investmentStatus = `${status} - ${dateObject.toDateString()}`;
      } else {
        investmentStatus = status;
      }
    }

    return [
      {
        icon: 'fa-check',
        title: 'Investment status',
        valueType: '',
        value: investmentStatus || '-',
      },
      {
        icon: 'fa-user-tie',
        title: 'My Investment',
        valueType: getters.getPortfolioFund.investment_amount ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioFund.investment_amount)
          ? `${currency}${numberWithSpaces(getters.getPortfolioFund.investment_amount)}`
          : '-',
      },
      {
        icon: 'fa-file-invoice-dollar',
        title: 'Fees status',
        valueType: getters.getPortfolioFund.fees_amount ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioFund.fees_amount)
          ? `${currency}${numberWithSpaces(getters.getPortfolioFund.fees_amount)}`
          : '-',
      },
      {
        icon: 'fa-calendar',
        title: 'Current investment year',
        valueType: '',
        value: countYearDiffFromNow(getters.getPortfolioFund?.creation_datetime),
      },
      {
        icon: 'fa-hourglass',
        title: 'Next fee date',
        valueType: '',
        value: nextFeeDate || '-',
      },
    ];
  },
  getPortfolioFundInvestmentOverviews: (_, getters, rootState) => {
    const currency = rootState.portfolioManagement.currentCurrency === 'EUR' ? '€' : '$';

    return [
      {
        icon: 'fa-building-columns',
        title: 'OneRagtime investment',
        valueType: getters.getPortfolioFundInvestment.investment_amount ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioFundInvestment.investment_amount)
          ? `${currency}${numberWithSpaces(getters.getPortfolioFundInvestment.investment_amount)}`
          : '-',
      },
      {
        icon: 'fa-database',
        title: 'Total round',
        valueType: getters.getPortfolioFundInvestment.fundraising_total_round ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioFundInvestment.fundraising_total_round)
          ? `${currency}${numberWithSpaces(getters.getPortfolioFundInvestment.fundraising_total_round)}`
          : '-',
      },
      {
        icon: 'fa-money-bill',
        title: 'Pre Money Valuation',
        valueType: getters.getPortfolioFundInvestment.fundraising_pre_money_valuation ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioFundInvestment.fundraising_pre_money_valuation)
          ? `${currency}${numberWithSpaces(getters.getPortfolioFundInvestment.fundraising_pre_money_valuation)}`
          : '-',
      },
      {
        icon: 'fa-money-bill-simple-wave',
        title: 'Post Money Valuation',
        valueType: getters.getPortfolioFundInvestment.fundraising_post_money_valuation ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioFundInvestment.fundraising_post_money_valuation)
          ? `${currency}${numberWithSpaces(getters.getPortfolioFundInvestment.fundraising_post_money_valuation)}`
          : '-',
      },
      {
        icon: 'fa-tags',
        title: 'Sectors',
        valueType: getters.getPortfolioFundInvestment.startup_sectors ? 'tag' : '',
        value: getters.getPortfolioFundInvestment.startup_sectors?.map((sector) => sector.name),
      },
    ];
  },
  getCompanyOverviews: (_, getters, rootState) => {
    const currency = rootState.portfolioManagement.currentCurrency === 'EUR' ? '€' : '$';

    return [
      {
        icon: 'fa-check',
        title: 'Investment status',
        valueType: getters.getPortfolioCompany.status ? 'status' : '',
        value: getters.getPortfolioCompany.status,
      },
      {
        icon: 'fa-user-tie',
        title: 'My Investment',
        valueType: getters.getPortfolioCompany.committed_amount ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioCompany.committed_amount)
          ? `${currency}${numberWithSpaces(getters.getPortfolioCompany.committed_amount)}`
          : '-',
      },
      {
        icon: 'fa-file-invoice-dollar',
        title: 'Fees amount',
        valueType: getters.getPortfolioCompany.fees_amount ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioCompany.fees_amount)
          ? `${currency}${numberWithSpaces(getters.getPortfolioCompany.fees_amount)}`
          : '-',
      },
      {
        icon: 'fa-sack-dollar',
        title: 'Exit Status',
        valueType: '',
        value: getters.getExitStatusChoicesMap[getters.getPortfolioCompany.exit_status],
      },
      {
        icon: 'fa-wallet',
        title: 'My stake in the round',
        valueType: getters.getPortfolioCompany.investment_stake ? 'percent' : '',
        value: validFixedAmountPercent(getters.getPortfolioCompany.investment_stake),
      },
      {
        icon: 'fa-building-columns',
        title: 'OneRagtime investment',
        valueType: getters.getPortfolioCompany.oneragtime_investment ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioCompany.oneragtime_investment)
          ? `${currency}${numberWithSpaces(getters.getPortfolioCompany.oneragtime_investment)}`
          : '-',
      },
      {
        icon: 'fa-database',
        title: 'Total round',
        valueType: getters.getPortfolioCompany.total_round ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioCompany.total_round)
          ? `${currency}${numberWithSpaces(getters.getPortfolioCompany.total_round)}`
          : '-',
      },
      {
        icon: 'fa-money-bill',
        title: 'Pre Money Valuation',
        valueType: getters.getPortfolioCompany.fundrasing_pre_money_valuation ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioCompany.fundrasing_pre_money_valuation)
          ? `${currency}${numberWithSpaces(getters.getPortfolioCompany.fundrasing_pre_money_valuation)}`
          : '-',
      },
      {
        icon: 'fa-money-bill-simple-wave',
        title: 'Post Money Valuation',
        valueType: getters.getPortfolioCompany.fundraising_post_money_valuation ? 'money' : '',
        value: numberWithSpaces(getters.getPortfolioCompany.fundraising_post_money_valuation)
          ? `${currency}${numberWithSpaces(getters.getPortfolioCompany.fundraising_post_money_valuation)}`
          : '-',
      },
      {
        icon: 'fa-tags',
        title: 'Sectors',
        valueType: getters.getPortfolioCompany.startup_sectors ? 'tag' : '',
        value: getters.getPortfolioCompany.startup_sectors,
      },
    ];
  },
  getIsPortfolioCompaniesLoading: (state) => state.isPortfolioCompaniesLoading,
  getIsPortfolioCompanyLoading: (state) => state.isPortfolioCompanyLoading,
};

const actions = {
  async fetchFundInvestments({ commit, dispatch, getters }) {
    const fundId = getters.getCurrentFundId;
    const investorId = getters.portfolioInvestorId;
    try {
      commit('setIsFundInvestmentsLoading', true);
      const response = await axiosCore.get(`/investors/${investorId}/investments-fund/${fundId}`);
      commit('setFundInvestments', response.data);
    } catch (error) {
      errorAlert('Failed to fetch fund investments', error);
    } finally {
      commit('setIsFundInvestmentsLoading', false);
    }
  },
  async fetchPortfolioCompanies({ commit, dispatch, getters }, currency = null) {
    const investorId = getters.portfolioInvestorId;
    let url = `/investors/${investorId}/portfolio-companies?`;
    try {
      commit('setIsPortfolioCompaniesLoading', true);
      const date_before = state.investmentFilterDate?.date_before || '';
      const date_after = state.investmentFilterDate?.date_after || '';

      if (date_before && date_after) {
        url += `date_before=${date_before}&date_after=${date_after}&`;
      }

      if (currency) {
        url += `currency=${currency}`;
      }

      const response = await axiosCore.get(url);
      commit('setPortfolioCompanies', response.data);
    } catch (error) {
      errorAlert('Failed to fetch portfolio companies', error);
    } finally {
      commit('setIsPortfolioCompaniesLoading', false);
    }
  },
  async fetchFundPortfolioCompanies({ commit, dispatch, getters }) {
    const investorId = getters.portfolioInvestorId;
    const fundId = getters.getCurrentFundId;

    let url = `/investors/${investorId}/portfolio-companies-fund/${fundId}`;
    try {
      commit('setIsFundPortfolioCompaniesLoading', true);
      const date_before = state.investmentFilterDate?.date_before || '';
      const date_after = state.investmentFilterDate?.date_after || '';

      if (date_before && date_after) {
        url = `/investors/${investorId}/portfolio-companies-fund/${fundId}?date_before=${date_before}&date_after=${date_after}`;
      }
      const response = await axiosCore.get(url);
      commit('setFundPortfolioCompanies', response.data);
    } catch (error) {
      errorAlert('Failed to fetch fund portfolio companies', error);
    } finally {
      commit('setIsFundPortfolioCompaniesLoading', false);
    }
  },
  async fetchPortfolioCompany({ commit, dispatch }, investmentId) {
    try {
      commit('setIsPortfolioCompanyLoading', true);
      const response = await axiosCore.get(`/investment/${investmentId}/portfolio-company`);
      commit('setPortfolioCompany', response.data);
    } catch (error) {
      errorAlert('Failed to fetch portfolio company', error);
    } finally {
      commit('setIsPortfolioCompanyLoading', false);
    }
  },
  async fetchPortfolioFundInvestment({ commit, dispatch, getters }, investmentId) {
    const investorId = getters.portfolioInvestorId;
    try {
      commit('setIsPortfolioFundInvestmentLoading', true);
      const response = await axiosCore.get(`/investment/${investmentId}/investors/${investorId}/portfolio-fund-investment`);
      commit('setPortfolioFundInvestment', response.data);
    } catch (error) {
      errorAlert('Failed to fetch portfolio fund investment', error);
    } finally {
      commit('setIsPortfolioFundInvestmentLoading', false);
    }
  },
  async fetchPortfolioFund({ commit, dispatch }, investmentId) {
    try {
      commit('setIsPortfolioFundLoading', true);
      const response = await axiosCore.get(`/investment/${investmentId}/portfolio-fund`);
      commit('setPortfolioFund', response.data);
    } catch (error) {
      errorAlert('Failed to fetch portfolio fund', error);
    } finally {
      commit('setIsPortfolioFundLoading', false);
    }
  },
  async fetchInvestmentStartupDocuments({ commit, dispatch }, startupId) {
    try {
      commit('setIsInvestmentStartupDocumentsLoading', true);
      const response = await axiosCore.get(`/startups/${startupId}/reporting-documents`);
      commit('setInvestmentStartupDocuments', response.data);
    } catch (error) {
      if (error.response.status === 403) {
        // investment status is not yet transferred so we don't show reports
        commit('setInvestmentStartupDocuments', []);
      } else {
        errorAlert('Failed to fetch investment startup documents', error);
      }
    } finally {
      commit('setIsInvestmentStartupDocumentsLoading', false);
    }
  },
  async fetchInvestmentBills({ commit, dispatch, getters }, investmentId) {
    try {
      const investorId = getters.portfolioInvestorId;
      commit('setIsInvestmentBillsLoading', true);
      const response = await axiosCore.get(`/investors/${investorId}/portfolio-companies/${investmentId}/bills/`);
      commit('setInvestmentBills', response.data);
    } catch (error) {
      errorAlert('Failed to fetch investment bills', error);
    } finally {
      commit('setIsInvestmentBillsLoading', false);
    }
  },
  async fetchInvestmentDocuments({ commit, dispatch }, investmentId) {
    try {
      commit('setIsInvestmentDocumentsLoading', true);
      const response = await axiosCore.get(`/investment/${investmentId}/documents`);
      commit('setInvestmentDocuments', response.data);
    } catch (error) {
      errorAlert('Failed to fetch investment documents', error);
    } finally {
      commit('setIsInvestmentDocumentsLoading', false);
    }
  },
};

const mutations = {
  setPortfolioCompanies: (state, data) => state.portfolioCompanies = data,
  setPortfolioCompany: (state, data) => state.portfolioCompany = data,
  setIsPortfolioCompaniesLoading: (state, data) => state.isPortfolioCompaniesLoading = data,
  setIsPortfolioCompanyLoading: (state, data) => state.isPortfolioCompanyLoading = data,
  setInvestmentFilterDate: (state, data) => state.investmentFilterDate = data,
  setInvestmentStartupDocuments: (state, data) => state.investmentStartupDocuments = data,
  setIsInvestmentStartupDocumentsLoading: (state, data) => state.isInvestmentStartupDocumentsLoading = data,
  setInvestmentBills: (state, data) => state.investmentBills = data,
  setIsInvestmentBillsLoading: (state, data) => state.isInvestmentBillsLoading = data,
  setInvestmentDocuments: (state, data) => state.investmentDocuments = data,
  setIsInvestmentDocumentsLoading: (state, data) => state.isInvestmentDocumentsLoading = data,
  setFundInvestments: (state, data) => state.fundInvestments = data,
  setIsFundInvestmentsLoading: (state, data) => state.isFundInvestmentsLoading = data,
  setFundPortfolioCompanies: (state, data) => state.fundPortfolioCompanies = data,
  setIsFundPortfolioCompaniesLoading: (state, data) => state.isFundPortfolioCompaniesLoading = data,
  setPortfolioFundInvestment: (state, data) => state.portfolioFundInvestment = data,
  setIsPortfolioFundInvestmentLoading: (state, data) => state.isPortfolioFundInvestmentLoading = data,
  setPortfolioFund: (state, data) => state.portfolioFund = data,
  setIsPortfolioFundLoading: (state, data) => state.isPortfolioFundLoading = data,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
