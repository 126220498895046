import { createRouter, createWebHistory } from 'vue-router';
import VueCookies from 'vue-cookies';

import JwtToken from '@/plugins/jwt';
import { store } from '@/store/index.js';

// eslint-disable-next-line import/extensions
import routes from '@/router/routes.js';

export const accessAllPages = [
  'Login',
  'Join',
  'CreateAccountPassword',
  'VerifyEmail',
  'ForgotPassword',
  'ResetPassword',
  'TCs',
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_PATH || undefined),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  store.commit('setRoute', to);

  const raw_refresh_token = VueCookies.get('platform_ort_jwt_refresh_token');

  if (raw_refresh_token) {
    // User has token
    const jwt_refresh_token = new JwtToken(raw_refresh_token);
    if (jwt_refresh_token.is_expired()) {
      // Logout
      VueCookies.remove('platform_ort_jwt_access_token');
      VueCookies.remove('platform_ort_jwt_refresh_token');
      store.dispatch('logout', false);
      // Go to logiin page
      if (to.path !== '/login') {
        next('/login');
      } else {
        next();
      }
    } else if (to.path === '/onboarding' || to.path === '/onboarding-entrepreneur') {
      if (store.getters.isOnboarded) {
        // Already onboarded users redirect to base
        next('/');
      } else {
        next();
      }
    } else if (!to.path.startsWith('/onboarding') && !store.getters.isOnboarded) {
      // Not onboarded users redirect to onboarding
      router.push('/onboarding');
    } else if (to.path === '/joining') {
      if (store.getters.isActive) {
        // User validated
        next('/');
      } else {
        // Blocked from the platform
        next();
      }
    } else {
      // Fallback to normal flow
      if (!store.getters.isActive) {
        // Blocked from the platform
        await router.push('/joining');
      } else if (to.path === '/login' || to.path === '/join') {
        // Valid users should not see login page if already logged in
        next('/');
      } else {
        // Normal flow
        next();
      }
    }
  } else {
    // User has no token
    if (accessAllPages.includes(to.name)) {
      // Can access pages that don't need token
      next();
    } else {
      // Redirect to Login (save redirect path)
      router.push({ name: 'Login', query: { redirect: to.fullPath } });
    }
  }

  // Bug fix?
  if (to.path.startsWith('/platform')) {
    router.push(to.path.replace('/platform', ''));
  } else if (to.matched.length == 0) {
    router.push('/404');
  } else {
    next();
  }
});

export default router;
