import { axiosCore } from '@/plugins/axios';

// utils
import { capitalize, snakeToWord } from '@/utils/text';

function isAnyFieldMissing(object) {
  let isMissing = false;
  Object.entries(object).forEach((item) => {
    if (!item[1]) {
      isMissing = true;
    }
  });
  return isMissing;
}

function isObjectEmpty(object) {
  const isEmpty = Object.values(object).every(
    (value) => !value,
  );
  return isEmpty;
}

export function getObjectStatus(object) {
  const isEmpty = isObjectEmpty(object);
  if (isEmpty) {
    return 'missing';
  }
  const isMissing = isAnyFieldMissing(object);
  if (isMissing) {
    return 'unfulfilled';
  }
  return 'fulfilled';
}

function getDocumentStatus(document) {
  const isEmpty = !document;
  if (isEmpty) {
    return 'missing';
  }
  return 'fulfilled';
}

const state = {
  investorLegalInformation: {},
  isInvestorLegalInfoLoading: false,
  isInvestorLegalInfoFetchedSuccessfully: false,
  isCreateNewShareholderLoading: false,
  isHandlingDocumentIdentityOfProof: false,
  isHandlingDocumentArticlesOfAssociation: false,
  isHandlingDocumentRegistrationProof: false,
  isHandlingDocumentShareholderDeclaration: false,
  documentIdentityOfProof: null,
  documentArticlesOfAssociation: null,
  documentRegistrationProof: null,
  documentShareholderDeclaration: null,
  isKycSubmitting: false,
  isProofOfIdentitySubmitted: null,
};

const getters = {
  getIsKycSubmitting: (state) => state.isKycSubmitting,
  getIsInvestorLegalInfoFetchedSuccessfully: (state) => state.isInvestorLegalInfoFetchedSuccessfully,
  getIsInvestorLegalInfoLoading: (state) => state.isInvestorLegalInfoLoading,
  refuseReasons: (state) => ([
    state.investorLegalInformation.refused_reason_articles_of_association,
    state.investorLegalInformation.refused_reason_identity_of_proof,
    state.investorLegalInformation.refused_reason_registration_proof,
    state.investorLegalInformation.refused_reason_shareholder_declaration,
  ]
  ),
  addressInfo: (state) => ({
    address_line_1: state.investorLegalInformation.representative_address?.address_line_1,
    address_line_2: state.investorLegalInformation.representative_address?.address_line_2,
    city: state.investorLegalInformation.representative_address?.city,
    region: state.investorLegalInformation.representative_address?.region,
    postal_code: state.investorLegalInformation.representative_address?.postal_code,
    country: state.investorLegalInformation.representative_address?.country,
    country_name: state.investorLegalInformation.representative_address?.country_name?.name,
  }),
  addressStatus: (_, getters) => {
    const objToCheck = { ...getters.addressInfo };
    delete objToCheck.address_line_2;
    delete objToCheck.country_name;
    return getObjectStatus(objToCheck);
  },
  businessIdentityInfo: (state) => ({
    company_name: state.investorLegalInformation.company_name,
    contact_email: state.investorLegalInformation.contact_email,
    company_number: state.investorLegalInformation.company_number,
    corporate_form: state.investorLegalInformation.corporate_form,
    share_capital: state.investorLegalInformation.share_capital,
  }),
  businessIdentityStatus: (_, getters) => getObjectStatus(getters.businessIdentityInfo),
  headquartersInfo: (state) => ({
    address_line_1: state.investorLegalInformation.business_address?.address_line_1,
    address_line_2: state.investorLegalInformation.business_address?.address_line_2,
    city: state.investorLegalInformation.business_address?.city,
    region: state.investorLegalInformation.business_address?.region,
    postal_code: state.investorLegalInformation.business_address?.postal_code,
    country: state.investorLegalInformation.business_address?.country,
    country_name: state.investorLegalInformation.business_address?.country_name?.name,
  }),
  headquartersStatus: (_, getters) => {
    const objToCheck = { ...getters.headquartersInfo };
    delete objToCheck.address_line_2;
    delete objToCheck.country_name;
    return getObjectStatus(objToCheck);
  },
  representativeIdentityInfo: (state) => {
    const fields = {
      first_name: state.investorLegalInformation.first_name,
      last_name: state.investorLegalInformation.last_name,
      email: state.investorLegalInformation.email,
      country_of_residence: state.investorLegalInformation.country_of_residence,
      country_of_residence_name: state.investorLegalInformation.country_of_residence_name,
      birthday: state.investorLegalInformation.birthday,
      representant_nationality: state.investorLegalInformation.representant_nationality,
      representant_nationality_name: state.investorLegalInformation.representant_nationality_name,
      proof_of_identity_expiration_date: state.investorLegalInformation.proof_of_identity_expiration_date,
    };
    if (state.investorLegalInformation.type === 'natural') {
      fields.position = state.investorLegalInformation.position;
      fields.incoming_range = state.investorLegalInformation.incoming_range;
    }
    return fields;
  },
  representativeIdentityStatus: (state, getters) => {
    const objToCheck = { ...getters.representativeIdentityInfo };
    delete objToCheck.country_of_residence_name;
    delete objToCheck.representant_nationality_name;
    if (state.investorLegalInformation.type !== 'natural') {
      delete objToCheck.position;
      delete objToCheck.incoming_range;
    }
    return getObjectStatus(objToCheck);
  },
  proofOfIdentityStatus: (state) => getDocumentStatus(state.documentIdentityOfProof),
  articlesOfAssociationStatus: (state) => getDocumentStatus(state.documentArticlesOfAssociation),
  registrationProofStatus: (state) => getDocumentStatus(state.documentRegistrationProof),
  shareholderDeclarationStatus: (state) => getDocumentStatus(state.documentShareholderDeclaration),
  getInvestorLegalInfo: (state) => state.investorLegalInformation,
  getInvestorKycStatus: (state) => state.investorLegalInformation.status,
  getInvestorKycStatusCapitalized:
    (state) => capitalize(snakeToWord(state.investorLegalInformation.status)),
  getInvestorKycType: (state) => state.investorLegalInformation.type,
  getInvestorKycTypeCapitalized:
    (state) => capitalize(snakeToWord(state.investorLegalInformation.type)),
  getIsKYCUploaded: (state) => state.isKYCUploaded,
  getIsKYCUploading: (state) => state.isKYCUploading,
};

const actions = {
  async fetchInvestorLegalInfo({ commit, getters, state }) {
    commit('setIsInvestorLegalInfoLoading', true);
    commit('setIsInvestorLegalInfoFetchedSuccessfully', false);

    try {
      const investorId = getters.portfolioInvestorId;
      const response = await axiosCore.get(`investors/${investorId}/legal-information`);
      commit('setInvestorLegalInformation', response.data);
      commit('setIsInvestorLegalInfoFetchedSuccessfully', true);
    } catch (error) {
      commit('setIsInvestorLegalInfoFetchedSuccessfully', false);
    } finally {
      commit('setIsInvestorLegalInfoLoading', false);
    }
  },
  async patchInvestorLegalInfo({ commit, getters, dispatch }, data) {
    commit('setIsInvestorLegalInfoLoading', true);
    commit('setIsInvestorLegalInfoFetchedSuccessfully', false);
    try {
      const investorId = getters.portfolioInvestorId;
      const response = await axiosCore.patch(`investors/${investorId}/legal-information`, data);
      commit('setInvestorLegalInformation', response.data);
      commit('setIsInvestorLegalInfoFetchedSuccessfully', true);
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong when updating legal information.' });
      commit('setIsInvestorLegalInfoFetchedSuccessfully', false);
      throw new Error(error.message);
    } finally {
      commit('setIsInvestorLegalInfoLoading', false);
    }
  },
  async submitKyc({ commit, dispatch }, data = {}) {
    commit('setIsKycSubmitting', true);
    const KYCId = state.investorLegalInformation?.kyc_id;
    try {
      await axiosCore.patch(`/kycs/${KYCId}/submit/`, data);
    } catch (e) {
      // Do nothing
    } finally {
      dispatch('successAlert', { message: 'Your KYC has been successfully submitted!' });
      commit('setIsKycSubmitting', false);
    }
  },
};

const mutations = {
  setIsInvestorLegalInfoLoading: (state, data) => state.isInvestorLegalInfoLoading = data,
  setInvestorLegalInformation: (state, data) => state.investorLegalInformation = data,
  setIsInvestorLegalInfoFetchedSuccessfully: (state, data) => {
    state.isInvestorLegalInfoFetchedSuccessfully = data;
  },
  setIsHandlingDocumentIdentityOfProof: (state, data) => {
    state.isHandlingDocumentIdentityOfProof = data;
  },
  setIsHandlingDocumentArticlesOfAssociation: (state, data) => {
    state.isHandlingDocumentArticlesOfAssociation = data;
  },
  setIsHandlingDocumentRegistrationProof: (state, data) => {
    state.isHandlingDocumentRegistrationProof = data;
  },
  setIsHandlingDocumentShareholderDeclaration: (state, data) => {
    state.isHandlingDocumentShareholderDeclaration = data;
  },
  setDocumentIdentityOfProof: (state, data) => state.documentIdentityOfProof = data,
  setDocumentArticlesOfAssociation: (state, data) => state.documentArticlesOfAssociation = data,
  setDocumentRegistrationProof: (state, data) => state.documentRegistrationProof = data,
  setDocumentShareholderDeclaration: (state, data) => state.documentShareholderDeclaration = data,
  setIsKycSubmitting: (state, data) => state.isKycSubmitting = data,
  setProofOfIdentity: (state, data) => state.isProofOfIdentitySubmitted = data,
};
export default {
  state,
  getters,
  actions,
  mutations,
};
