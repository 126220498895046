import { axiosCore } from '@/plugins/axios';

const state = {
  sectorsCore: [],
  sectorsPlatform: [],
};

const getters = {
  sectorsList: (state) => state.sectorsCore,
  sectorsStartup: (state, getters) => {
    const filteredList = getters.sectorsList.filter((sector) => sector.type == 'startup');
    return filteredList;
  },
  sectorsGeneral: (state, getters) => {
    const filteredList = getters.sectorsList.filter((sector) => sector.type == 'general');
    return filteredList;
  },
};

const actions = {
  getSectors({ dispatch }) {
    const actionToCall = 'getSectorsCore';
    dispatch(actionToCall);
  },
  getSectorsCore({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.sectorsCore.length == 0) {
        axiosCore.get('/sectors-list/').then((response) => {
          commit('storeCoreSectors', response.data);
          resolve();
        }, (error) => {
          reject();
        });
      } else {
        resolve();
      }
    });
  },
};

const mutations = {
  storeCoreSectors: (state, data) => state.sectorsCore = data,
  storePlatformSectors: (state, data) => state.sectorsPlatform = data,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
