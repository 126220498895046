import Vue from 'vue';
import { axiosCore } from '@/plugins/axios';

const state = {
  kycFundTaxCountry: [],
  kycFundTaxCountryForm: {},
  isKycFundTaxCountryLoading: false,
};

const getters = {
  getKycFundTaxCountry: (state) => state.kycFundTaxCountry,
  getKycFundTaxCountryForm: (state) => state.kycFundTaxCountryForm,
  isKycFundTaxCountryLoading: (state) => state.isKycFundTaxCountryLoading,
};

const mutations = {
  setKycFundTaxCountry: (state, data) => { state.kycFundTaxCountry = data; },
  setKycFundTaxCountryForm: (state, data) => { state.kycFundTaxCountryForm = data; },
  setKycFundTaxCountryFormProperty: (state, { key, value }) => {
    Vue.set(state.kycFundTaxCountryForm, key, value);
  },
  setIsKycFundTaxCountryLoading: (state, data) => { state.isKycFundTaxCountryLoading = data; },
};

const actions = {
  async fetchKycFundTaxCountry({ commit, dispatch, getters }) {
    commit('setIsKycFundTaxCountryLoading', true);
    const investorId = getters.portfolioInvestorId;
    const kycFundId = getters.getKycFund.id;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/tax-country`;
    try {
      const response = await axiosCore.get(url);
      commit('setKycFundTaxCountry', response.data);
    } catch {
      commit('setKycFundTaxCountry', []);
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get your tax country.',
      });
    } finally {
      commit('setIsKycFundTaxCountryLoading', false);
    }
  },

  async createKycFundTaxCountry({ commit, dispatch, getters }) {
    commit('setIsKycFundTaxCountryLoading', true);
    const investorId = getters.portfolioInvestorId;
    const kycFundId = getters.getKycFund.id;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/tax-country`;
    const data = getters.getKycFundTaxCountryForm;
    data.kyc_fund = kycFundId;
    try {
      await axiosCore.post(url, data);
    } catch {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to save your tax country.',
      });
    } finally {
      commit('setIsKycFundTaxCountryLoading', false);
    }
  },

  async patchKycFundTaxCountry({ commit, dispatch, getters }) {
    commit('setIsKycFundTaxCountryLoading', true);
    const data = getters.getKycFundTaxCountryForm;
    const investorId = getters.portfolioInvestorId;
    const kycFundId = getters.getKycFund.id;
    const taxCountryId = data.id;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/tax-country/${taxCountryId}`;
    try {
      await axiosCore.patch(url, data);
    } catch {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to save your tax country.',
      });
    } finally {
      commit('setIsKycFundTaxCountryLoading', false);
    }
  },

  async deleteKycFundTaxCountry({ commit, dispatch, getters }, taxCountryId) {
    commit('setIsKycFundTaxCountryLoading', true);
    const investorId = getters.portfolioInvestorId;
    const kycFundId = getters.getKycFund.id;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/tax-country/${taxCountryId}`;
    try {
      await axiosCore.delete(url);
    } catch {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to delete your tax country.',
      });
    } finally {
      commit('setIsKycFundTaxCountryLoading', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
