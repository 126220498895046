import { axiosCore } from '@/plugins/axios';

const state = {
  fundraisingPushSelected: {},
  loadingDetailFundraisingPushModal: false,
};

const getters = {
  fundraisingPushSelected: (state) => state.fundraisingPushSelected,
  loadingDetailFundraisingPushModal: (state) => state.loadingDetailFundraisingPushModal,
};

const actions = {
  getSingleFundraisingPush({ commit, getters }, id) {
    commit('setLoadingDetailFundraisingPushModal', true);
    return new Promise((resolve, reject) => {
      axiosCore
        .get(`corporate/${getters.accesstokenPayload.active_profile.entity_id}/deals/${id}`)
        .then(
          (response) => {
            commit('setLoadingDetailFundraisingPushModal', false);
            commit('setSingleFundraisingPush', response.data);
            resolve();
          },
          (error) => {
            commit('setLoadingDetailFundraisingPushModal', false);
            reject(error);
          },
        );
    });
  },
  discardFundraisingPush({ commit, getters }, id) {
    const discardObj = {
      is_discarded: true,
    };
    return new Promise((resolve, reject) => {
      axiosCore
        .patch(`corporate/${getters.accesstokenPayload.active_profile.entity_id}/deals/${id}/discard`, discardObj)
        .then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
};

const mutations = {
  setLoadingDetailFundraisingPushModal: (state, data) => (state.loadingDetailFundraisingPushModal = data),
  setSingleFundraisingPush: (state, data) => (state.fundraisingPushSelected = data),
  emptySingleFundraisingPush: (state) => state.fundraisingPushSelected = {},
};

export default {
  state,
  getters,
  actions,
  mutations,
};
