import { axiosCore } from '@/plugins/axios';

const state = {
  partnerBio: '',
  partnerJob: '',
  partnerLocation: '',
  partnerSectorsOfInterest: [],
  partnerAvgTicketSize: '',
  partnerAvgTicketPerYear: '',
  partnerPreferedStage: [],
  partnerFunctionalExpertise: [],
  partnerSectorialExpertise: '',

  // profile info
  jobPosition: '',
  cardLocation: '',
  country: '',
  profileBio: '',
  profileImage: '',
  profileInfoCityId: '',
  profileInfoCityName: '',
  company: '',
  video: '',
  linkedin: '',
  twitter: '',
  facebook: '',
  instagram: '',
  accountAdminOptions: [],
};

const getters = {
  partnerBio: (state) => state.partnerBio,
  partnerJob: (state) => state.partnerJob,
  partnerLocation: (state) => state.partnerLocation,
  partnerSectorsOfInterest: (state) => {
    const filteredChoices = [];
    state.partnerSectorsOfInterest.map((sector) => {
      filteredChoices.push(sector.name);
    });
    return filteredChoices;
  },
  partnerAvgTicketSize: (state) => state.partnerAvgTicketSize,
  partnerAvgTicketPerYear: (state) => state.partnerAvgTicketPerYear,
  partnerPreferedStage: (state) => {
    const filteredChoices = [];
    state.partnerPreferedStage.map((stage) => {
      filteredChoices.push(stage.name);
    });
    return filteredChoices;
  },
  partnerFunctionalExpertise: (state) => {
    const filteredChoices = [];
    state.partnerFunctionalExpertise.map((option) => {
      filteredChoices.push(option.name);
    });
    return filteredChoices;
  },
  partnerSectorialExpertise: (state) => {
    const filteredChoices = [];
    filteredChoices.push(state.partnerSectorialExpertise);
    return filteredChoices;
  },

  // profile info
  linkedin: (state) => state.linkedin,
  twitter: (state) => state.twitter,
  facebook: (state) => state.facebook,
  instagram: (state) => state.instagram,
  company: (state) => state.company,
  accountAdminOptions: (state) => state.accountAdminOptions,
  video: (state) => state.video,
  profileBio: (state) => state.profileBio,
  profileImage: (state) => state.profileImage,
  profileInfoCityName: (state) => state.profileInfoCityName,
  jobPosition: (state) => state.jobPosition,
  cardLocation: (state) => state.cardLocation,
  country: (state) => state.country,
};

const actions = {
  // fetchPartnerDescription
  fetchPartnerPortfolio({ commit, dispatch, rootGetters }) {
    const {
      getUserId: userId,
      activeRelationshipId: relationId,
    } = rootGetters;

    const baseUrl = `/partners/user/${userId}/partner-relationship/${relationId}/partner-portfolio`;
    return new Promise((resolve, reject) => {
      axiosCore.get(baseUrl).then(
        (response) => {
          commit('setProfileBio', response.data.partner.data.bio);
          commit('setLocation', response.data.contact_info.data.city);
          commit('setCountry', response.data.contact_info.data.city);
          commit('setPartnerLocation', response.data.contact_info.data.city);
          commit('setLinkedin', response.data.contact_info.data.links.linkedin);
          commit('setInstagram', response.data.contact_info.data.links.instagram);
          commit('setFacebook', response.data.contact_info.data.links.facebook);
          commit('setTwitter', response.data.contact_info.data.links.twitter);
          commit('setCompany', response.data.contact_info.data.employer);
          commit('setJobPosition', response.data.partner.data.job_position);
          commit('setPartnerJob', response.data.partner.data.job_position);
          commit('setProfileImage', response.data.contact_info.data.profile_image);
          commit('setProfileInfoCityName', response.data.contact_info.data.city);
          commit('setName', response.data.contact_info.data.name);
          resolve(response.data);
        },
        (error) => {
          dispatch('dangerAlert', { message: 'There was an error in the request' }, { root: true });
          reject(error);
        },
      );
    });
  },
  updatePartnerProfileInfo({
    commit, dispatch, getters, rootGetters,
  }, body) {
    const data = {
      partner: {},
      contact_info: {
        links: {},
      },
      functional_expertises: [],
    };
    // profile info
    data.partner.bio = getters.profileBio;
    data.partner.address = getters.cardLocation;
    data.partner.job_position = getters.jobPosition;
    data.contact_info.city = state.profileInfoCityId;
    data.contact_info.position = getters.jobPosition;
    data.contact_info.employer = getters.company;
    data.contact_info.links.twitter = getters.twitter;
    data.contact_info.links.facebook = getters.facebook;
    data.contact_info.links.instagram = getters.instagram;
    data.contact_info.links.linkedin = getters.linkedin;
    const baseUrl = `/partners/user/${body.userId}/partner-relationship/${body.relationId}/partner-portfolio`;

    return new Promise((resolve, reject) => {
      axiosCore.patch(baseUrl, data).then(
        (response) => {
          dispatch('successAlert', { message: 'Profile info successfully changed!' }, { root: true });

          commit('setPartnerBio', getters.profileBio);
          commit('setPartnerJob', getters.jobPosition);
          commit('setPartnerLocation', getters.cardLocation);
          resolve(response);
        },
        (error) => {
          dispatch('dangerAlert', { message: 'There was an error in the request' }, { root: true });
          reject(error);
        },
      );
    });
  },
};

const mutations = {
  setPartnerBio: (state, data) => (state.partnerBio = data),
  setPartnerJob: (state, data) => (state.partnerJob = data),
  setPartnerLocation: (state, data) => (state.partnerLocation = data),
  setPartnerSectorsOfInterest: (state, data) => (state.partnerSectorsOfInterest = data),
  setPartnerPreferedStage: (state, data) => (state.partnerPreferedStage = data),
  setPartnerFunctionalExpertise: (state, data) => (state.partnerFunctionalExpertise = data),
  setPartnerSectorialExpertise: (state, data) => (state.partnerSectorialExpertise = data),

  // profile info
  setAccountAdminOptions: (state, data) => (state.accountAdminOptions = data),
  setName: (state, data) => (state.name = data),
  setPartnerId: (state, id) => (state.partnerId = id),
  setNames: (state, data) => (state.names = data),
  setJobPosition: (state, data) => (state.jobPosition = data),
  setLocation: (state, data) => (state.cardLocation = data),
  setCountry: (state, data) => (state.country = data),
  setProfileInfoCityId: (state, data) => (state.profileInfoCityId = data),
  setProfileInfoCityName: (state, data) => (state.profileInfoCityName = data),
  setCompany: (state, data) => (state.company = data),
  setVideo: (state, data) => (state.video = data),
  setLinkedin: (state, data) => (state.linkedin = data),
  setTwitter: (state, data) => (state.twitter = data),
  setFacebook: (state, data) => (state.facebook = data),
  setInstagram: (state, data) => (state.instagram = data),
  setProfileBio: (state, data) => (state.profileBio = data),
  setProfileImage: (state, data) => (state.profileImage = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
