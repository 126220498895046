import { axiosCore } from '@/plugins/axios';

const state = {};
const getters = {};
const mutations = {};
const actions = {
  getStartupRelationshipsCore(context) {
    const coreId = context.getters.getUserId;
    const url = `/startups/user/${coreId}/startup-relationships`;
    return new Promise((resolve, reject) => {
      axiosCore.get(url).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
