import { axiosCore } from '@/plugins/axios';

const state = {
  activeEntity: {},
  activeEntityLoading: false,
};

const mutations = {
  setActiveEntity: ((state, data) => (state.activeEntity = data)),
  setActiveEntityLoading: (state, data) => { state.activeEntityLoading = data; },
};

const getters = {
  getActiveEntityId: (state) => state.activeEntity.entity_id,
  getActiveEntityName: (state) => state.activeEntity.entity_name,
  getActiveEntityLoading: (state) => state.activeEntityLoading,
};

const actions = {
  activateEntity({ dispatch, commit }, data) {
    const url = `/auth/users/${data.owner_id}/active-profile`;
    return new Promise((resolve, reject) => {
      axiosCore.patch(url, data).then(
        (response) => {
          dispatch('successAlert', { message: 'Entity switched successfully, page is going to reload 📝🔄' });
          commit('setActiveEntity', response.data);
          resolve(response.data);
        },
        (error) => {
          dispatch('dangerAlert', { message: 'There was a problem with your request 🚨' });
          reject(error);
        },
      );
    });
  },
  getEntityProfilePicture({}, data) {
    const url = `${data.type}s/user/${data.user_id}/${data.type}-relationship/${data.relation_id}/avatar`;
    return new Promise((resolve, reject) => {
      axiosCore.get(url).then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};

export default {
  actions, state, mutations, getters,
};
