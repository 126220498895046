const state = {
  isOnboardingBtnLoading: false,
};

const getters = {
  showOnboardingBtnLoader: (state) => state.isOnboardingBtnLoading,
};

const mutations = {
  setOnboardingBtnLoader: (state) => {
    state.isOnboardingBtnLoading = !state.isOnboardingBtnLoading;
  },
};

export default {
  state,
  getters,
  mutations,
};
