const Home = () => import('@/components/pages/Home/Home.vue');
const Login = () => import('@/components/pages/Access/Login.vue');
const ForgotPassword = () => import('@/components/pages/Access/ForgotPassword.vue');
const ResetPassword = () => import('@/components/pages/Access/ResetPassword.vue');
const Join = () => import('@/components/pages/Access/CreateAccount.vue');
const CreateAccountPassword = () => import('@/components/pages/Access/CreateAccountPassword.vue');
const VerifyEmail = () => import('@/components/pages/Access/VerifyEmail.vue');
const ReviewingApplication = () => import('@/components/pages/Access/ReviewingApplication.vue');
const Portfolio = () => import('@/components/pages/Portfolio/Portfolio.vue');
const TCs = () => import('@/components/pages/TCs/TCs.vue');
const Onboarding = () => import('@/components/pages/Access/Onboarding/Onboarding.vue');
const OnboardingEntrepreneur = () => import('@/components/pages/OnBoarding/OnboardingEntrepreneur.vue');
const MyProfile = () => import('@/components/pages/ProfileCore/ProfileCore.vue');
const PortfolioManagement = () => import('@/components/pages/Portfolio/sections/ChartsSection/PortfolioManagement.vue');
const NotFoundPage = () => import('@/components/pages/Fallback/NotFoundPage.vue');
const AccessDeniedPage = () => import('@/components/pages/Fallback/AccessDeniedPage.vue');
const ManageClients = () => import('@/components/pages/Entities/Partner/ManageClients.vue');
const KYCFund = () => import('@/components/pages/KYC/KYCFund.vue');
const KYCFundCompleted = () => import('@/components/pages/KYC/KYCFundCompleted.vue');
const FundSection = () => import('@/components/pages/Opportunities/sections/FundSection.vue');
const ClubdealSection = () => import('@/components/pages/Opportunities/sections/ClubdealSection.vue');
const FounderFundraising = () => import('@/components/pages/FounderFundraising/FounderFundraising.vue');
const ExclusiveFundraisings = () => import('@/components/pages/Opportunities/sections/ExclusiveFundraisings.vue');
const MyFundraisings = () => import('@/components/pages/Home/Roles/StartupSections/MyFundraisings.vue');
const SecondaryMarket = () => import('@/components/pages/SecondaryMarket/SecondaryMarket.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Portfolio,
  },
  // ACCESS
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/join',
    name: 'Join',
    component: Join,
  },
  {
    path: '/create-password',
    name: 'CreateAccountPassword',
    component: CreateAccountPassword,
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail,
  },
  {
    path: '/joining',
    name: 'ReviewingApplication',
    component: ReviewingApplication,
  },
  { // investor and partner onboarding
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
  },
  { // startup onboarding (old onboarding)
    path: '/onboarding-entrepreneur',
    name: 'OnboardingEntrepreneur',
    component: OnboardingEntrepreneur,
  },
  // KYC FUND
  {
    path: '/kyc-fund',
    name: 'KYCFund',
    component: KYCFund,
  },
  {
    path: '/kyc-completed',
    name: 'KYCFundCompleted',
    component: KYCFundCompleted,
  },
  // PARTNER
  {
    path: '/clients',
    name: 'ManageClients',
    component: ManageClients,
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio,
  },
  {
    path: '/exclusive-fundraisings',
    name: 'ExclusiveFundraisings',
    component: ExclusiveFundraisings,
  },
  {
    path: '/terms-and-conditions',
    name: 'TCs',
    component: TCs,
  },
  {
    path: '/profile',
    name: 'MyProfile',
    component: MyProfile,
  },
  {
    path: '/portfolio-management',
    name: 'PortfolioManagement',
    component: PortfolioManagement,
  },
  {
    path: '/funds',
    name: 'FundSection',
    component: FundSection,
  },
  {
    path: '/club-deal',
    name: 'ClubdealSection',
    component: ClubdealSection,
  },
  {
    path: '/secondary',
    name: 'SecondaryMarket',
    component: SecondaryMarket,
  },
  {
    path: '/founder-fundraisings',
    name: 'MyFundraisings',
    component: MyFundraisings,
  },
  {
    path: '/founder-fundraisings/:id',
    name: 'FounderFundraising',
    component: FounderFundraising,
  },
  {
    path: '/404',
    name: '404',
    component: NotFoundPage,
  },
  {
    path: '/401',
    name: '401',
    component: AccessDeniedPage,
  },
];

export default routes;
