/** @template T; @typedef {import("src/types/utils.js").TableCache<T>} TableCache<T> */
/** @template T; @typedef {import("src/types/utils.js").PaginatedResults<T>} PaginatedResults<T> */

function expectedLength(page = 1, total = 0, pageSize = 10) {
  const lastEntry = page * pageSize;
  const startEntry = lastEntry - pageSize + 1;

  if (startEntry > total) return 0;
  if (lastEntry > total) return total - startEntry + 1;
  return pageSize;
}

/**
 * @function
 * @template T
 * @param {TableCache<T>} tableCache
*/
export function checkPageCache(tableCache) {
  const { currentPage, pageSize, entries } = tableCache;
  const cachedEntries = entries.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const cacheLength = expectedLength(currentPage, tableCache.total, pageSize);
  if (cachedEntries.length === cacheLength && !cachedEntries.includes(undefined)) return cachedEntries;
  return false;
}

/**
 * @function
 * @template T
 * @param {PaginatedResults<T>} paginatedResults
 * @param {TableCache<T>} tableCache
*/
export function updateTableCache(paginatedResults, tableCache) {
  /** @type {TableCache<T>} */
  const updatedCache = JSON.parse(JSON.stringify(tableCache));
  const { currentPage, pageSize, entries } = tableCache;
  const { count, results } = paginatedResults;
  updatedCache.total = count;
  results.forEach((result, index) => {
    const entryIdx = (currentPage - 1) * pageSize + index;
    updatedCache.entries[entryIdx] = result;
  });
  return updatedCache;
}

/**
 * @function
 * @template T
 * @param {TableCache<T>} tableCache
 * @param {T} entry
*/
export function insertInTableCache(tableCache, entry, index) {
  const updatedCache = JSON.parse(JSON.stringify(tableCache));
  updatedCache.entries.splice(index, 0, entry);
  updatedCache.total += 1;
  return updatedCache;
}

/**
 * @function
 * @template T
 * @param {TableCache<T>} tableCache
 * @param {T} entry
*/
export function prependTableCache(tableCache, entry) {
  insertInTableCache(tableCache, entry, 0);
}

/**
 * @function
 * @template T
 * @param {TableCache<T>} tableCache
 * @param {T} entry
*/
export function appendTableCache(tableCache, entry) {
  insertInTableCache(tableCache, entry, tableCache.entries.length);
}

/**
 * @function
 * @template T
 * @param {TableCache<T>} tableCache
 * @param {number} index
*/
export function removeFromTableCache(tableCache, index) {
  const updatedCache = JSON.parse(JSON.stringify(tableCache));
  updatedCache.entries.splice(index, 1);
  updatedCache.total -= 1;
  return updatedCache;
}
