<template>
    <div class="div-text-area">
        <div v-if="label !== null" class="label"><span>{{ label }}</span></div>
        <resizable-textarea>
            <b-form-textarea
                class="input"
                v-bind:class="{ 'input-valid': state === 'valid', 'input-pre-invalid': state === 'pre-invalid', 'input-post-invalid': state === 'post-invalid' }"
                v-model="value"
                :placeholder="placeholder"
                :rows="rows"
                :max-rows="maxRows"
                :no-resize="noResize"
                @input="updateValue">
            </b-form-textarea>
        </resizable-textarea>
        <span v-if="state === 'pre-invalid' || state === 'post-invalid'" class="feedback text-left" v-bind:class="{ 'feedback-pre-invalid': state === 'pre-invalid', 'feedback-post-invalid': state === 'post-invalid' }">{{ feedback }}</span>
    </div>
</template>

<script>
import ResizableTextarea from '@/components/lib/mixins/ResizableTextarea.js';

export default {
  name: 'TextArea',
  components: { 'resizable-textarea': ResizableTextarea },
  emits: ['input'],
  props: {
    state: {
      type: String,
      required: false,
      default: 'null',
    },
    feedback: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    rows: {
      type: Number,
      required: false,
      default: 3,
    },
    'max-rows': {
      type: Number,
      required: false,
      default: 6,
    },
    'no-resize': {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    state(newVal) {
      this.state = newVal;
    },
    feedback(newVal) {
      this.feedback = newVal;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', this.value);
    },
    clicked() {
    },
    clickedOutside() {
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .div-text-area {
        width: 100%;
        text-align: left !important;
        .input {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            min-height: 24px;
            border-radius: 5px;
            border: solid 1px $grey-color;
            font-size: $medium-font-size;
            background-color: $white-color;
            outline: none;
            transition: box-shadow .3s ease, border .3s ease, color .3s ease;
            &:hover {
                color: $black-color;
            }
            &:focus, &:active {
                color: $black-color;
                box-shadow: 0 0 3px 2px $outline-color;
            }
            &-valid {
                border: solid 1px $green-color !important;
                &:focus, &:active {
                    box-shadow: 0 0 1px 2px $green-color;
                }
            }
            &-pre-invalid {
                border: solid 1px $yellow-color !important;
                &:focus, &:active {
                    box-shadow: 0 0 1px 2px $yellow-color;
                }
            }
            &-post-invalid {
                border: solid 1px $red-color !important;
                &:focus, &:active {
                    box-shadow: 0 0 1px 2px $red-color;
                }
            }
            &::placeholder {
                color: #9b9a9a;
                font-weight: 400;
            }
        }
        .feedback {
            &-pre-invalid {
                color: $yellow-color;
                text-align: left !important;
            }
            &-post-invalid {
                color: $red-color;
                text-align: left !important;
            }
        }
        .label {
            text-align: left;
            font-size: $medium-font-size;
            margin-bottom: 5px;
        }
        .label-checkbox {
            text-align: left;
            cursor: pointer;
            .checkbox {
                margin-right: 10px;
                text-align: left;
            }
        }
    }

</style>
