import Vue from 'vue';
import { axiosCore } from '@/plugins/axios';

const state = {
  kycFund: {},
  kycFundDefault: {},
  kycFundForm: {},
  isKycFundLoading: false,
  isKycFormValid: false,
};

const getters = {
  getKycFund: (state) => state.kycFund,
  getKycFundForm: (state) => state.kycFundForm,
  getIsKycFormValid: (state) => state.isKycFormValid,
  isKycFundLoading: (state) => state.isKycFundLoading,
};

const mutations = {
  setKycFund: (state, data) => {
    state.kycFund = data;
    state.kycFundDefault = { ...data };
  },
  resetKycFundForm: (state) => { state.kycFundForm = {}; },
  resetHardKycFundForm: (state) => {
    for (const field in state.kycFundForm) state.kycFund[field] = state.kycFundDefault[field];
    state.kycFundForm = {};
  },
  setKycFundFormProperty: (state, { key, value }) => { Vue.set(state.kycFundForm, key, value); },
  setHardKycFundFormProperty: (state, { key, value }) => {
    Vue.set(state.kycFundForm, key, value);
    Vue.set(state.kycFund, key, value);
  },
  setIsKycFundLoading: (state, data) => { state.isKycFundLoading = data; },
  setIsKycFormValid: (state, data) => { state.isKycFormValid = !!data; },
  setBankIBAN: (state, data) => { state.kycFund.bank_iban_document = data; },
};

const actions = {
  async fetchKycFund({ commit, dispatch, getters }) {
    commit('setIsKycFundLoading', true);
    const investorId = getters.portfolioInvestorId;
    const url = `investors/${investorId}/kyc-fund`;
    try {
      const response = await axiosCore.get(url);
      commit('setKycFund', response.data);
    } catch {
      commit('setKycFund', {});
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get kyc fund.',
      });
    } finally {
      commit('setIsKycFundLoading', false);
    }
  },

  async patchKycFund({ commit, dispatch, getters }) {
    commit('setIsKycFundLoading', true);
    const investorId = getters.portfolioInvestorId;
    const url = `investors/${investorId}/kyc-fund`;
    const data = getters.getKycFundForm;
    try {
      const response = await axiosCore.patch(url, data);
      commit('setKycFund', response.data);
    } catch {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to save your kyc information.',
      });
    } finally {
      commit('setIsKycFundLoading', false);
      commit('resetKycFundForm');
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
