<template>
    <transition mode="out-in" name="fade">
        <b-alert
            v-if="dismiss_count_down"
            :show="dismiss_count_down"
            :variant="type"
            @dismissed="dismiss_count_down = 0"
            @dismiss-count-down="countDownChanged">
            {{ this.message }}
        </b-alert>
    </transition>
</template>

<script>
export default {
  name: 'Alert',
  emits: ['click'],
  props: {
    type: {
      type: String,
      required: false,
      default: 'info',
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dismiss_count_down: 3,
    };
  },
  methods: {
    clicked() {
      this.dismiss_count_down = 0;
      this.$emit('click');
      if (this.to != undefined && !this.external) {
        this.$router.push(this.to);
      }
    },
    countDownChanged(dismiss_count_down) {
      this.dismiss_count_down = dismiss_count_down;
      if (this.dismiss_count_down === 0) {
        this.$store.dispatch('triggerAlert', { show: false });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .alert {
        font-size: $medium-font-size;
        font-weight: 400;
        position: fixed;
        top: 10px;
        left: 35%;
        z-index: 9999;
        width: 30%;
        text-align: center;
        &-info {
            background-color: white;
            border-color: $blue-lighter-color;
            color: $blue-darker-color;
        }
        &-success {
            background-color: white;
            border-color: $green-lighter-color;
            color: $green-darker-color;
        }
        &-danger {
            background-color: white;
            border-color: $red-lighter-color;
            color: $red-darker-color;
        }
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

</style>
