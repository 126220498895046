/* eslint-disable no-shadow */
import { axiosCore } from '@/plugins/axios';

const state = {
  countries: [],
  IsLoadingCountries: false,
};

const getters = {
  countriesDataName: (state) => {
    const viewCountriesData = state.countries.map((country) => country.name);
    return viewCountriesData;
  },
  countriesData: (state) => {
    const viewCountriesData = state.countries.map((country) => ({
      name: country.name, id: country.id,
    }));
    return viewCountriesData;
  },
};

const mutations = {
  setCountries: (state, data) => {
    state.countries = data;
  },
  setIsLoadingCountries: (state, data) => {
    state.IsLoadingCountries = data;
  },
};

const actions = {
  async fetchCountries({ commit, dispatch }, searchVal = null) {
    commit('setIsLoadingCountries', true);
    const params = searchVal ? `?name=${searchVal}` : '';
    try {
      const response = await axiosCore.get(`/countries/${params}`);
      commit('setCountries', response.data);
    } catch (error) {
      dispatch('dangerAlert', { message: 'Something went wrong' });
      throw new Error(error.message);
    } finally {
      commit('setIsLoadingCountries', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
