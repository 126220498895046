<template>
    <span id="beta-indicator">Version Beta</span>
</template>

<script>
export default {
  name: 'BetaIndicator',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#beta-indicator {
    @extend .text-xs--bold;
    color: $cobalt-200 !important;
    background: $cobalt-600;
    border-radius: 12px;
    width: 148px;
    height: 32px;
    padding: 4px 8px;
    cursor: default;
    margin: 11px 18.5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
