<!-- This component is not used currently due to svg import differencies in vite & vue 2.7 - vite & vue 3 -->
<template>
  <span
    :class="iconClasses"
    :style="{
      'width': size,
      'height': size,
      'mask': `url(${iconUrl})`,
      'mask-size': 'contain',
      'mask-position': 'center',
      'mask-repeat': 'no-repeat',
      'background-size': 'contain',
      '-webkit-mask': `url(${iconUrl})`,
      '-webkit-mask-size': 'contain',
      '-webkit-mask-position': 'center',
      '-webkit-mask-repeat': 'no-repeat',
      'display': 'inline-block',
    }"
    :key="icon"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
  >
  </span>
</template>

<script>

export default {
  name: 'ORTIcon',
  props: {
    icon: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
      default: 'primary-label',
    },
    size: {
      type: String,
      required: false,
      default: '16px',
    },
  },
  data() {
    return {
      iconId: 1,
    };
  },
  computed: {
    iconClasses() {
      return [
        'ort-icon',
        `ort-icon--${this.color}`,
      ];
    },
    iconUrl() {
      const path = `/assets/${this.icon}.svg`;
      const url = new URL(path, import.meta.url).href;
      return url;
    },
  },
  methods: {
    mouseOver() {
      this.$emit('mouseOver');
    },
    mouseLeave() {
      this.$emit('mouseLeave');
    },  
  },

};
</script>

<style lang="scss" scoped>
.ort-icon {
  transition: all 0.3s ease;
  padding: 10px;

  &--primary {
    background-color: $primary;
  }

  &--primary-label {
    background-color: $primary-label;
  }

  &--secondary {
    background-color: $secondary;
  }

  &--danger {
    background-color: $danger;
  }

  &--info {
    background-color: $info;
  }

  &--success {
    background-color: $success;
  }

  &--warning {
    background-color: $warning;
  }

  &--red500 {
    background-color: $red-500;
  }

  &--grey {
    background-color: $grey-200;
  }
}
</style>
