import { axiosCore } from '@/plugins/axios';

const state = {
  corporateRelationships: [],
  corporateRelationshipsIsLoading: false,
  corporateInvestorId: null,
};
const getters = {
  getCorporateRelationshipsResponse: (state) => state.corporateRelationships,
  getCorporateInvestorId: (state) => state.corporateInvestorId,
};
const mutations = {
  setCorporateRelationships: (state, data) => {
    state.corporateRelationships = data;
  },
  setCorporateRelationshipsIsLoading: (state, data) => {
    state.corporateRelationshipsIsLoading = data;
  },
  setCorporateInvestorId: (state, data) => {
    state.corporateInvestorId = data;
  },
};

const actions = {
  async getCorporateRelationshipsCoreAsync({ commit, getters }) {
    const coreId = getters.getUserId;
    const url = `/corporates/user/${coreId}/corporate-relationships`;
    commit('setCorporateRelationshipsIsLoading', true);
    try {
      const response = await axiosCore.get(url);
      commit('setCorporateRelationships', response.data);
    } catch {
      commit('setCorporateRelationships', []);
      throw new Error(error?.message || 'Something went wrong trying to get corporate relationships.');
    } finally {
      commit('setCorporateRelationshipsIsLoading', false);
    }
  },
  // TODO deprecate this function:
  getCorporateRelationshipsCore(context) {
    const coreId = context.getters.getUserId;
    const url = `/corporates/user/${coreId}/corporate-relationships`;
    return new Promise((resolve, reject) => {
      axiosCore.get(url).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  async fetchCorporateInvestorId({ commit, getters }) {
    const corporateId = getters.entityId;
    const url = `/corporates/${corporateId}/corporate-investor-id`;
    return new Promise((resolve) => {
      if (!getters.isActiveCorporate) {
        resolve();
      } else {
        axiosCore.get(url).then(
          (response) => {
            commit('setCorporateInvestorId', response.data.investor);
            resolve();
          },
          () => {
            commit('setCorporateInvestorId', null);
            resolve();
          },
        );
      }
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
