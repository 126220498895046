import { axiosCore } from '@/plugins/axios';

const state = {
  businessModelsCore: [],
  businessModelsPlatform: [],
};

const getters = {
  businessModelsList: (state) => state.businessModelsCore,
};

const actions = {
  getBusinessModels({ dispatch }) {
    const actionToCall = 'getBusinessModelsCore';
    dispatch(actionToCall);
  },
  getBusinessModelsCore({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.businessModelsCore.length == 0) {
        axiosCore.get('/businessmodels-list/').then((response) => {
          commit('storeCoreBusinessModels', response.data);
          resolve();
        }, (error) => {
          reject();
        });
      } else {
        resolve();
      }
    });
  },
};

const mutations = {
  storeCoreBusinessModels: (state, data) => state.businessModelsCore = data,
  storePlatformBusinessModels: (state, data) => state.businessModelsPlatform = data,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
