import { axiosCore } from '@/plugins/axios';

const actions = {
  getChoices({ commit }, choiceType) {
    return new Promise((resolve, reject) => {
      axiosCore.get(`/choices/?choices=${choiceType}`).then(
        (response) => {
          commit(`set${choiceType}`, response.data);
          resolve(response.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};

const state = {
  exitStatusChoices: [],
};

const getters = {
  getExitStatusChoices: (state) => state.exitStatusChoices,
  getExitStatusChoicesMap: (state) => {
    const map = {};
    state.exitStatusChoices.forEach((item) => {
      map[item.value] = item.text;
    });
    return map;
  },
};

const mutations = {
  setExitStatusChoices: (state, data) => { state.exitStatusChoices = data; },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
