<template>
   <span class="active-range">
       <span
            :class="'custom-tooltip' + ' custom-tooltip-' + color"
            :style="
            'width:' + width +
            ';left:' + left +
            ';bottom:' + bottom +
            ';'">
            <slot></slot>
        </span>
   </span>
</template>

<script>

export default {
  name: 'Tooltip',
  props: {
    width: {
      type: String,
      required: false,
      default: '100px',
    },
    left: {
      type: String,
      required: false,
      default: '50%',
    },
    bottom: {
      type: String,
      required: false,
      default: '100%',
    },
    color: {
      type: String,
      required: false,
      default: 'white',
    },
  },
};

</script>

<style lang="scss" scoped>

    .active-range {
        padding: 10px;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        width: 100%;
        top: -20px;
        left: 0%;
    }

    .custom-tooltip {
        text-align: left;
        box-sizing: content-box;
        transition: opacity .5s ease;
        // visibility: hidden;
        // opacity: 0;
        background-color: $white-color;
        font: $main-font-regular;
        color: $black-color;
        border-radius: 8px;
        padding: 7px 13px;
        position: absolute;
        margin-left: -60px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
        z-index: 1;
        &:after {
            position: absolute;
            content: "";
            top: 100%;
            left: 8px;
            width: 0;
            height: 0;
            box-sizing: border-box;
            border: 10px solid black;
            border-color: transparent transparent $white-color $white-color;
            transform-origin: 0 0;
            transform: rotate(-45deg);
            box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
        }
        &-white {
            background-color: $white-color;
            color: $black-color;
            &:after {
                border-color: transparent transparent $white-color $white-color;
            }
        }
        &-black {
            background-color: $black-color;
            color: $white-color;
            &:after {
                border-color: transparent transparent $black-color $black-color;
            }
        }
        &-blue {
            background-color: $blue-color;
            color: $white-color;
            &:after {
                border-color: transparent transparent $blue-color $blue-color;
            }
        }
        &-red {
            background-color: $red-color;
            color: $white-color;
            &:after {
                border-color: transparent transparent $red-color $red-color;
            }
        }
        &-purple {
            background-color: $cobalt-200;
            color: $white-color;
            &:after {
                border-color: transparent transparent $cobalt-200 $cobalt-200;
            }
        }
        &-yellow {
            background-color: $yellow-color;
            color: $white-color;
            &:after {
                border-color: transparent transparent $yellow-color $yellow-color;
            }
        }
        &-green {
            background-color: $green-color;
            color: $white-color;
            &:after {
                border-color: transparent transparent $green-color $green-color;
            }
        }
    }

</style>
