/** @typedef {import("@/ort-lib/stores/accounts/jwt").Actions} JWTActions */

import axios from 'axios';
import Cookies from 'js-cookie';
import JwtToken from './jwt';
import { store } from '@/store/index';
import { getMutation } from '@/utils/jsdoc';

const defaultHeaders = {
  'Content-Type': 'application/json',
  // "Access-Control-Allow-Origin": "*"
};

// NOTE: comment out WithCredentials until CORS headers are configured in the backend
const coreurl = import.meta.env.VITE_API_ROOT;

const axiosCore = axios.create({
  baseURL: coreurl,
  headers: defaultHeaders,
});

const axiosCoreNoToken = axios.create({
  baseURL: coreurl,
  headers: defaultHeaders,
});

/**
 * Flag to call refresh/token only once (without this, it gets called multiple times)
 *
 * We use localStorage becase we cannot trust vuex, as it takes too long to save (race-condition)
 * We use number becase using bool in localStorage is shit
 */
localStorage.is_login_loading = 0;

// The interceptor here ensures that
// we check for the token in local storage
// every time an axios request is made
const valid_access_token = () => new Promise((resolve, reject) => {
  const raw_accesstoken = Cookies.get('platform_ort_jwt_access_token');
  if (raw_accesstoken) {
    const accesstoken = new JwtToken(raw_accesstoken);
    if (accesstoken.is_expired()) {
      const refresh_token = new JwtToken(Cookies.get('platform_ort_jwt_refresh_token'));
      if (refresh_token.is_expired()) {
        // force login screen
        getMutation('deleteTokens')();
        location.reload();
      } else if (!Number(localStorage.is_login_loading)) {
        localStorage.is_login_loading = 1;
        store.dispatch('refreshTokens').then(
          (response) => {
            localStorage.is_login_loading = 0;
            const new_raw_accesstoken = response.data.access;
            return resolve(new_raw_accesstoken);
          },
          (error) => reject(error),
        );
      } else {
        // retry until the token becomes accesible
        setTimeout(() => resolve(valid_access_token()), 500);
      }
    } else {
      // access token is valid
      return resolve(raw_accesstoken);
    }
  } else {
    // no token stored in cookies
    console.error('No access tokens found in cookies');
    return reject;
  }
});

axiosCore.interceptors.request.use(
  async (config) => {
    const accesstoken = await valid_access_token();
    config.headers = {
      ...config.headers,
      'Accept-Language': store.getters.language,
      Authorization: `Bearer ${accesstoken}`,
    };
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error),
);

export {
  axiosCore,
  axiosCoreNoToken,
};
