import { axiosCore } from '@/plugins/axios';

import { downloadXlsx } from '@/utils/download';

const state = {
  DealflowKPIExportLoading: false,
  DealflowOverviewExportLoading: false,
  fundraisingPushTabs: [
    { type: 'Scope', count: null },
    { type: 'Screening', count: null },
    { type: 'Under analysis', count: null },
    { type: 'Under fundraising', count: null },
    { type: 'Portfolio', count: null },
  ],
  fundraisingKPICounts: [
    {
      type: 'Aria Scope',
      count: null,
      color: 'purple',
      icon: 'fas fa-rocket',
    },
    {
      type: 'Screen',
      count: null,
      color: 'yellow',
      icon: 'fas fa-tv-alt',
    },
    {
      type: 'Discarded',
      count: null,

      color: 'red',
      icon: 'fas fa-times',
    },
    {
      type: 'Financed',
      count: null,

      color: 'green',
      icon: 'fas fa-money-bill-wave',
    },
  ],
  fundraisingKPICountsFemaleFounder: [
    { type: 'Aria Scope', count: null },
    { type: 'Screen', count: null },
    { type: 'Discarded', count: null },
    { type: 'Financed', count: null },
  ],
  currentTab: 'scope',
  tabFilters: { aria_scope: true },
  dateFiltersOverview: {},
  sectorFiltersOverview: {},

  dateFiltersKPI: {},
  sectorFiltersKPI: {},

  currentPage: 1,
  loadingFundraisingPushes: false,
  fundraisingPushes: {
    results: [],
    next: null,
    count: 0,
  },
};

const getters = {
  getDealflowKPIExportLoading: (state) => state.DealflowKPIExportLoading,
  getDealflowOverviewExportLoading: (state) => state.DealflowOverviewExportLoading,
  fundraisingPushTabs: (state) => state.fundraisingPushTabs,
  fundraisingPushes: (state) => state.fundraisingPushes.results,
  loadingFundraisingPushes: (state) => state.loadingFundraisingPushes,
  currentTab: (state) => state.currentTab,
  nextPage: (state) => state.fundraisingPushes.next,
  currentFiltersOverview: (state) => ({
    ...state.dateFiltersOverview,
    ...state.sectorFiltersOverview,
  }),
  currentTabFilters: (state) => state.tabFilters,
  currentFiltersKPI: (state) => ({
    ...state.dateFiltersKPI,
    ...state.sectorFiltersKPI,
  }),
  itemsLeft: (state, getters) => state.fundraisingPushes.count - getters.fundraisingPushes.length,
  fundraisingPushTabsValueText: (state) => state.fundraisingPushTabs.map((el) => {
    const formatedtext = el.count ? `${el.type} (${el.count})` : el.type;
    return {
      text: formatedtext,
      value: el.type.replace(' ', '_').toLowerCase(),
    };
  }),
  fundraisingPushKPICount: (state) => state.fundraisingKPICounts.map((el, index) => ({
    ...el,
    female_founder:
                    state.fundraisingKPICountsFemaleFounder[index].count,
  })),
};

const actions = {
  downloadOverviewExport({ commit, getters }, extraParams) {
    commit('setDealflowKPIExportLoading', true);
    commit('setDealflowOverviewExportLoading', true);
    return new Promise((resolve, reject) => {
      axiosCore
        .get(`corporate/${getters.accesstokenPayload.active_profile.entity_id}/deals/export`, {
          params: {
            ...getters.currentFiltersOverview,
            ...getters.currentTabFilters,
            ...extraParams,
          },
        })
        .then(
          (response) => {
            commit('setDealflowOverviewExportLoading', false);
            commit('setDealflowKPIExportLoading', false);
            downloadXlsx(response.data, 'export.xlsx');
            resolve(response);
          },
          (error) => {
            commit('setDealflowKPIExportLoading', false);
            commit('setDealflowOverviewExportLoading', false);
            reject(error);
          },
        );
    });
  },
  retrieveFundraisingPushCountOnTabs(
    { commit, getters, dispatch },
    extraParams,
  ) {
    const currentFilters = getters.currentFiltersOverview;
    const storeType = 'fundraisingPushTabs';
    const countList = [
      {
        params: {
          ...currentFilters,
          ...extraParams,
          stage: 'scope',
        },
        type: 'Scope',
        state: storeType,
      },
      {
        params: {
          ...currentFilters,
          ...extraParams,
          stage: 'screened',
        },
        type: 'Screening',
        state: storeType,
      },

      {
        params: {
          ...currentFilters,
          ...extraParams,
          stage: 'under_analysis',
        },
        type: 'Under analysis',
        state: storeType,
      },
      {
        params: {
          ...currentFilters,
          ...extraParams,
          stage: 'under_fundraising',
        },
        type: 'Under fundraising',
        state: storeType,
      },
      {
        params: {
          ...currentFilters,
          ...extraParams,
          stage: 'portfolio',
        },
        type: 'Portfolio',
        state: storeType,
      },
    ];

    countList.forEach((item) => {
      dispatch('retrieveFundraisingPushCount', item);
    });
  },
  retrieveFundraisingPushCountKPIs(
    { commit, getters, dispatch },
    extraParams,
  ) {
    const currentFilters = getters.currentFiltersKPI;
    const storeType = 'fundraisingKPICounts';
    const countList = [
      {
        params: {
          ...currentFilters,
          ...extraParams,
        },
        type: 'Aria Scope',
        state: storeType,
      },
      {
        params: {
          ...currentFilters,
          ...extraParams,
          stage: 'screened',
        },
        type: 'Screen',
        state: storeType,
      },
      {
        params: {
          ...currentFilters,
          ...extraParams,
          is_discarded: true,
        },
        type: 'Discarded',
        state: storeType,
      },
      {
        params: {
          ...currentFilters,
          ...extraParams,
          stage: 'portfolio',
          is_discarded: false,
        },
        type: 'Financed',
        state: storeType,
      },
    ];

    countList.forEach((item) => {
      dispatch('retrieveFundraisingPushCount', item);
    });
    const kpiFemaleFounderStateName = 'fundraisingKPICountsFemaleFounder';

    countList.forEach((item) => {
      const female_founder_params = { ...item.params, female_founder: true };
      const female_founder_item = {
        ...item,
        params: female_founder_params,
        state: kpiFemaleFounderStateName,
      };
      dispatch('retrieveFundraisingPushCount', female_founder_item);
    });
  },
  retrieveFundraisingPushCount({ commit, getters }, added_info) {
    const { params } = added_info;
    const { type } = added_info;
    const { state } = added_info;

    return new Promise(() => {
      axiosCore
        .get(`corporate/${getters.accesstokenPayload.active_profile.entity_id}/deals/count`, {
          params,
        })
        .then((response) => {
          const eachTypeCount = {
            type,
            count: response.data.count,
            state,
          };
          commit('updateFundraisingTypeCount', eachTypeCount);
        });
    });
  },
  getFundraisingPushes({ commit, getters }, extraParams) {
    commit('emptyFundraisingPushes');
    commit('setLoadingFundraisingPushes', true);
    return new Promise((resolve, reject) => {
      axiosCore
        .get(`corporate/${getters.accesstokenPayload.active_profile.entity_id}/deals/`, {
          params: {
            page: 1,
            ...getters.currentFiltersOverview,
            ...getters.currentTabFilters,
            ...extraParams,
          },
        })
        .then(
          (response) => {
            commit('setLoadingFundraisingPushes', false);
            commit('setFundraisingPushes', response.data);
            resolve(response);
          },
          (error) => {
            commit('setLoadingFundraisingPushes', false);
            commit('emptyFundraisingPushes');
            reject(error);
          },
        );
    });
  },
  getFundrainsingNexPage({ commit, getters }, extraParams) {
    const currentFilters = getters.currentFiltersOverview;
    const tabFilters = getters.currentTabFilters;
    const pageNumber = { page: getters.nextPage };
    return new Promise((resolve, reject) => {
      axiosCore
        .get(`corporate/${getters.accesstokenPayload.active_profile.entity_id}/deals/`, {
          params: {
            ...currentFilters,
            ...tabFilters,
            ...extraParams,
            ...pageNumber,
          },
        })
        .then(
          (response) => {
            commit('addNextTabDealflow', response.data.results);
            commit('setNextPage', response.data.next);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
};

const mutations = {
  setDealflowOverviewExportLoading: (state, loadingState) => (state.DealflowOverviewExportLoading = loadingState),
  setDealflowKPIExportLoading: (state, loadingState) => (state.DealflowKPIExportLoading = loadingState),
  updateFundraisingTypeCount: (state, typeCount) => {
    const stateName = typeCount.state;
    delete typeCount.state;
    const index = state[stateName].findIndex(
      (item) => item.type === typeCount.type,
    );
    state[stateName][index] = {
      ...state[stateName][index],
      ...typeCount,
    };
    return (state[stateName] = [...state[stateName]]);
  },
  setFundraisingPushes: (state, data) => (state.fundraisingPushes = data),
  setCurrentTab: (state, data) => (state.currentTab = data),
  setNextPage: (state, data) => (state.fundraisingPushes.next = data),
  setTabFilters: (state, tabName) => {
    const filtersForTab = {
      scope: { stage: 'scope' },
      screening: { stage: 'screened' },
      under_analysis: { stage: 'under_analysis' },
      under_fundraising: { stage: 'under_fundraising' },
      portfolio: { stage: 'portfolio' },
    };
    return (state.tabFilters = filtersForTab[tabName]);
  },
  setDateFiltersOverview: (state, data) => (state.dateFiltersOverview = data),
  setSectorFiltersOverview: (state, data) => (state.sectorFiltersOverview = data),
  setLoadingFundraisingPushes: (state, data) => (state.loadingFundraisingPushes = data),
  addNextTabDealflow: (state, data) => {
    data.forEach((element) => {
      state.fundraisingPushes.results.push(element);
    });
  },
  emptyFundraisingPushes: (state) => (state.fundraisingPushes = {
    results: [],
    next: null,
    count: 0,
  }),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
