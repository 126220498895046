import moment from 'moment';
import { utils, writeFile } from 'xlsx';

const TAG_OPTIONS = {
  gsi: 'GSI',
  sae: 'SAE',
  both: 'GSI/SAE',
};
const EXCEL_EXTENSION = '.xlsx';

function handleTags(gsiTag, saeTag) {
  if (gsiTag.length > 0 && saeTag.length > 0) return TAG_OPTIONS.both;
  if (gsiTag.length > 0) return TAG_OPTIONS.gsi;
  if (saeTag.length > 0) return TAG_OPTIONS.sae;
  return null;
}

function combineTagsContent(tagOptions) {
  const tagList = [];
  tagOptions.forEach((tag) => {
    tagList.push(tag.name);
  });

  return tagList.join(', ');
}

function downloadXlsx(xlsxData) {
  const fileName = `Report-export_${moment().format('DD-MM-YYYY_hh:mm:ss').toString()}${EXCEL_EXTENSION}`;

  // Modify headers
  const xlsxWithModifiedData = xlsxData.map((xlsxObject) => ({
    'Startup name': xlsxObject.startup_name,
    'Added date': moment(xlsxObject.added_at).format('DD-MM-YYYY'),
    'GSI/SAE tag': handleTags(xlsxObject.gsi_options, xlsxObject.sae_options),
    Fund: 'ARIA',
    Tagline: xlsxObject.tagline,
    Stage: xlsxObject.stage,
    Discard: xlsxObject.is_discarded,
    'Contact name': xlsxObject.contact_name,
    'Female founder': xlsxObject.female_founder,
    'Fundraising total round': xlsxObject.fundraising_total_round,
    'OneRagtime ticket': xlsxObject.oneragtime_ticket,
    'Post money valuation': xlsxObject.post_money_valuation,
    'Pre money valuation': xlsxObject.pre_money_valuation,
    'Updated date': moment(xlsxObject.updated_at).format('DD-MM-YYYY'),
    'Why we love it?': xlsxObject.why_we_love_it,
    'Startup ID': xlsxObject.startup_id,
    'GSI content': combineTagsContent(xlsxObject.gsi_options),
    'SAE content': combineTagsContent(xlsxObject.sae_options),
  }));
  const workSheet = utils.json_to_sheet(xlsxWithModifiedData);

  // Convert state to workbook
  const workBook = utils.book_new();

  // generate file and send to client
  utils.book_append_sheet(workBook, workSheet);
  writeFile(workBook, fileName);
}

export { downloadXlsx };
