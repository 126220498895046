import { axiosCoreNoToken } from '@/plugins/axios';
import router from '@/router/index.js';
import { errorAlert } from '@/ort-lib/utils/utils';
import { getAction } from '@/utils/jsdoc';

const successAlert = getAction('successAlert');

const state = {
  showLoader: true,
};

const getters = {
  showLoader: (state) => state.showLoader,
};

const actions = {
  activateUserAction({ dispatch, commit }, data) {
    return new Promise((resolve, reject) => {
      axiosCoreNoToken.patch('/auth/users/activate', data).then(
        (response) => {
          commit('setAccessToken', response.data.access);
          commit('setRefreshToken', response.data.refresh);
          successAlert('Password set successfully 📝');
          resolve();
        },
        (error) => {
          state.showLoader = !state.showLoader;
          errorAlert('Could not activate your account', error);
          router.push({ name: 'Login' });
          reject();
        },
      );
    });
  },
};

export default {
  actions,
  state,
  getters,
};
