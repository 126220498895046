<template>
    <b-container class="form-group">
        <div v-if="label !== null" class="label"><span>{{ label }}</span></div>
        <slot></slot>
        <b-row v-if="state === 'invalid' || state === 'pre-invalid'"><b-col><span class="feedback">{{ feedback }}</span></b-col></b-row>
    </b-container>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    state: {
      type: String,
      required: false,
      default: 'null',
    },
    feedback: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  watch: {
    state(newVal) {
      this.state = newVal;
    },
    feedback(newVal) {
      this.feedback = newVal;
    },
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .form-group {
        text-align: left !important;
        .label {
            text-align: left;
            font-size: $medium-font-size;
            margin-bottom: 5px;
        }
        .feedback {
            color: $red-color;
        }
    }

</style>
