import { axiosCoreNoToken } from '@/plugins/axios';

import { getAction } from '@/utils/jsdoc';
import { errorAlert } from '@/ort-lib/utils/utils';

const successAlert = getAction('successAlert');

const state = {
  invitation_email: '',
};

const getters = {
  getInvitationEmail: (state) => state.invitation_email,
};

const mutations = {
  setInvitationEmail: (state, data) => (state.invitation_email = data),
};

const actions = {
  createUser({ commit, dispatch }, data) {
    commit('setInvitationEmail', data.email);
    return new Promise((resolve, reject) => {
      axiosCoreNoToken.post('/auth/users/join', data)
        .then((response) => {
          resolve(response);
        }, (error) => {
          errorAlert('There was a problem creating your account.', error);
        });
    });
  },

  async resendUserInviteEmail({ dispatch }, userEmail) {
    try {
      await axiosCoreNoToken.post(`/auth/users/join-resend?email=${userEmail}`);
      successAlert('User invitation resent successfully!');
    } catch (error) {
      errorAlert('Failed to resend invitation email.', error);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
