import { axiosCore } from '@/plugins/axios';

const state = {
  businessStagesCore: [],
  businessStagesPlatform: [],
};

const getters = {
  businessStagesList: (state) => state.businessStagesCore,
};

const actions = {
  getBusinessStages({ dispatch }) {
    const actionToCall = 'getBusinessStagesCore';
    dispatch(actionToCall);
  },
  getBusinessStagesCore({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.businessStagesCore.length == 0) {
        axiosCore.get('/growths-list/').then(
          (response) => {
            commit('storeCoreBusinessStages', response.data);
            resolve();
          },
          (error) => {
            reject();
          },
        );
      } else {
        resolve();
      }
    });
  },
};

const mutations = {
  storeCoreBusinessStages: (state, data) => (state.businessStagesCore = data),
  storePlatformBusinessStages: (state, data) => (state.businessStagesPlatform = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
