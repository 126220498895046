import { successAlert } from '@/ort-lib/utils/utils';
import { getAction } from '@/ort-lib/utils/jsdoc.js';

/** @typedef {import("@/ort-lib/stores/accounts/jwt").Actions} JWTActions */

const state = {
  loginFormData: { email: '', password: '' },
  loadingWhileLogin: false,
  loginFeedback: false,
  redirectUrl: '',
};

const getters = {
  getLoginFormData: (state) => state.loginFormData,
  getLoginFeedback: (state) => state.loginFeedback,
  getLoadingWhileLogin: (state) => state.loadingWhileLogin,
  getRedirectUrl: (state) => state.redirectUrl,
};
const mutations = {
  setLoginFormData: (state, data) => (state.loginFormData = data),
  setLoginFeedback: (state, data) => (state.loginFeedback = data),
  setLoadingWhileLogin: (state, data) => (state.loadingWhileLogin = data),
  setRedirectUrl: (state, data) => (state.redirectUrl = data),
};

const actions = {
  async completeLogin({ dispatch, getters, commit }) {
    commit('setLoadingWhileLogin', true);
    const getToken = getAction(/** @type {JWTActions["getToken"]} */ ('getToken'));
    try {
      await getToken(getters.getLoginFormData);
      successAlert('Welcome back!');
      dispatch('redirectAfterLogin');
      commit('setLoadingWhileLogin', false);
    } catch (error) {
      commit('setLoginFeedback', error.response.data);
      commit('setLoadingWhileLogin', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
